import React from 'react';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import { History, Lock, PlaylistAdd, Receipt, Schedule, Save, Clear } from '@mui/icons-material';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Button, Checkbox, Dialog, MenuItem, ListItemIcon } from '@mui/material';
import Popover from '@mui/material/Popover';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import List from './../List';
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import MultiSelect from '../../general/MultiSelect';
import AdvancedSearch from '../../search/AdvancedSearch';
import PageTopSection from '../../general/PageTopSection';
import DateRangePicker from '../../general/react-date-range/src/components/DateRangePicker';
import TaimerComponent from '../../TaimerComponent';
import NoPermissionOverlay from '../../overlays/NoPermissionOverlay';
import MassInvoicingListRow from '../rows/MassInvoicingListRow';
import { ColumnHeaderButton } from '../ListHeader';
import MassInvoicingSummaryRow from '../rows/MassInvoicingSummaryRow';
import ContextMenu from '../../general/ContextMenu';

import ToolTip from '@mui/material/Tooltip';

import SaveSearchQuery from '../../reports/dialogs/SaveSearchQuery';
import DeleteSearchQuery from '../../reports/dialogs/DeleteSearchQuery';

import styles from './MassInvoicingList.module.scss';
import { DatePicker } from '../../general/react-date-range/src';
import colors from '../../colors';

import FileSaver from 'file-saver';

import CloudDownload from '@mui/icons-material/CloudDownload';

const COLUMNS_KEY = "mass_invoicing_pdf_columns";


type Props = WithSnackbarProps

interface State {
    filters: any;
    listSettings: any;
    autoCompleteData: any;
    data: any[];
    dataLoaded?: boolean;
    dialogOpen: boolean;
    loading: boolean;
    resetAccounts: boolean;
    stickySearchLoaded?: boolean;
    selectedInvoiceGenerationSettings: any;
    dialogRows: any[];
    currency: string;
    companies: any[];
    checkedRows: object;
    savedSearches: any[];
    savedSearchDialog?: any;
    savedSearchDialogData?: any;
    selectedSearch?: any;
    invoiceSettings?: any;
    hideableColumns: any[];
    viewMenuOpen: boolean;
    viewMenuAnchor: null | HTMLElement;
}
class MassInvoicingList extends TaimerComponent<Props, State> {
    list: any = React.createRef();
    advancedSearch: any = React.createRef();
    searchTerms: any = undefined;
    initialFilters: any = {};
    stickySearchKey = 'mass_invoicing_list';
    savedSearchKey = 'mass_invoicing_list';
    listColumns: any = [
        {
            field: 'menu',
            visibleInToolbar: true,
            hideFromSearch: true,
            name: 'menu',
            header: '',
            width: 60,
            fieldType: 'rowmenu',
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
        },
        {
            field: 'has_grouping_settings',
            staticIndex: 1,
            name: 'has_grouping_settings',
            header: '',
            width: 45,
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
            onlyInMaterialDialog: true,
            hideFromSearch: true,
            noMinWidth: true
        },
        {
            field: 'checked',
            visibleInToolbar: true,
            name: 'checked',
            header: '',
            width: 40,
            fieldType: 'check',
            hideFromSearch: true,
            columnHeaderType: 'checkbox',
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
            hideFromMaterialDialog: true,
        },
        { field: 'project_id', hideFromSearch: true, name: 'project_id', transl: this.tr('Project nr.'), header: this.tr('Project nr.'), width: 120 },
        { field: 'account', name: 'account', transl: this.tr('Account'), header: this.tr('Account'), width: 200, visualizationType: 'tree' },
        { field: 'project', name: 'project', transl: this.tr('Project'), header: this.tr('Project'), width: 200, visualizationType: 'tree' },
        { field: 'address', name: 'address', transl: this.tr('Invoice Address'), header: this.tr('Invoice Address'), width: 300 },
        { field: 'bill_language', name: 'bill_language', transl: this.tr('Invoice Language'), header: this.tr('Invoice Language'), width: 150 },
        { field: 'reverse_charge', hideFromSearch: true, name: 'reverse_charge', transl: this.tr('Reverse Charge'), header: this.tr('Reverse Charge'), width: 180 },
        { field: 'e_operator', hideFromSearch: true, name: 'e_operator', transl: this.tr('E-invoice Operator'), header: this.tr('E-invoice Operator'), width: 180 },
        { field: 'e_address', hideFromSearch: true, name: 'e_address', transl: this.tr('E-invoice Address'), header: this.tr('E-invoice Address'), width: 180 },
        { field: 'category', hidden: true, name: 'category', transl: this.tr('Category'), header: this.tr('Category'), visible: false, width: 150, "visualizationType": "tree" },
        { field: 'project_type', hidden: true, name: 'project_type', transl: this.tr('Project Type'), header: this.tr('Project Type'), visible: false, width: 150 },
        { field: 'tag', hidden: true, name: 'tag', transl: this.tr('Tag'), header: this.tr('Tag'), visible: false, width: 150 },
        { field: 'invoiced_total', hideFromSearch: true, name: 'invoiced_total', transl: this.tr('Invoiced'), header: this.tr('Invoiced'), width: 150 },
        { field: 'invoice_material', sortable: false, hideFromSearch: true, name: 'invoice_material', transl: this.tr('Invoice Material'), header: this.tr('Invoice Material'), width: 180 },
        { field: 'scheduled_invoices', sortable: false, hideFromSearch: true, name: 'scheduled_invoices', transl: this.tr('Scheduled Invoices'), header: this.tr('Scheduled Invoices'), width: 180 },
        { field: 'hours', sortable: false, hideFromSearch: true, name: 'hours', transl: this.tr('Hours'), header: this.tr('Hours'), width: 150 },
        { field: 'expenses', sortable: false, hideFromSearch: true, name: 'expenses', transl: this.tr('Expenses'), header: this.tr('Expenses'), width: 150 },
        { field: 'bills', sortable: false, hideFromSearch: true, name: 'bills', transl: this.tr('Bills'), header: this.tr('Bills'), width: 150 },
        { field: 'quotes', sortable: false, hideFromSearch: true, name: 'quotes', transl: this.tr('Quotes'), header: this.tr('Quotes'), width: 180 },
        { field: 'project_manager', name: 'project_manager', transl: this.tr('Project Manager'), header: this.tr('Project Manager'), width: 180 },
        { field: 'sales_agent', name: 'sales_agent', transl: this.tr('Sales Agent'), visible: false, header: this.tr('Sales Agent'), width: 180 },
        { field: 'account_manager', hidden: true, name: 'account_manager', transl: this.tr('Account Manager'), visible: false, header: this.tr('Account Manager'), width: 180 },
        { field: 'account_group', hidden: true, name: 'account_group', transl: this.tr('Account Group'), visible: false, header: this.tr('Account Group'), width: 180 },
        { field: 'enterprise_group', hidden: true, name: 'enterprise_group', transl: this.tr('Enterprise Group'), visible: false, header: this.tr('Enterprise Group'), width: 180 },
        { field: 'reporting_group', hidden: true, name: 'reporting_group', transl: this.tr('Reporting Group'), visible: false, header: this.tr('Reporting Group'), width: 180 },
        { field: 'custom_grouping', hideFromSearch: true, name: 'custom_grouping', transl: this.tr('Custom Invoice Row Grouping'), visible: false, header: this.tr('Custom Invoice Row Grouping'), width: 180 },
    ];

    notSelectedFilterValue = { id: -1, name: this.tr('Not selected') };

    invoiceGenerationSettings = {
        projectGrouping: {
            name: this.tr('Project Grouping'),
            options: [
                {
                    value: 1,
                    name: this.tr('Separate Invoices'),
                },
                {
                    value: 2,
                    name: this.tr('Group by Address'),
                },
            ],
        },
        hourEntryProjectAndTaskGrouping: {
            name: this.tr('Hour Entries Project & Task Grouping'),
            options: [
                { value: '1', name: this.tr('By project'), tooltip: this.tr('Group entries by project. When invoicing multiple projects all entries grouped under respective project topic row.') },
                {
                    value: '2',
                    name: this.tr('No project grouping'),
                    tooltip: this.tr('When invoicing multiple projects, use this selection to hide topic rows with project names and show all entries in one list.'),
                },
                {
                    value: '3',
                    name: this.tr('By project and task'),
                    tooltip: this.tr('View each task entires separately. Each task name is added to the topic name after the project name.'),
                    disabled: !(this.context.addons && this.context.addons.resourcing),
                },
            ],
        },
        hourEntryGrouping: {
            name: this.tr('Hour Entries Grouping'),
            options: [
                ...(this.context.addons.procountor_accounting
                    ? []
                    : [
                          {
                              value: '3',
                              name: this.tr('Per employee'),
                              tooltip: this.tr(
                                  'Group all hours per employee to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'
                              ),
                          },
                      ]),
                {
                    value: '2',
                    name: this.tr('Per employee and task'),
                    tooltip: this.tr('Group all hours per employee and task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
                },
                {
                    value: '4',
                    name: this.tr('Per task'),
                    tooltip: this.tr('Group all hours per task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
                },
                ...(this.context.addons.procountor_accounting
                    ? []
                    : [
                          {
                              value: '5',
                              name: this.tr('All-in-one, show qty'),
                              tooltip: this.tr('Group all hours to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
                          },
                      ]),
                {
                    value: '7',
                    name: this.tr('All-in-one, qty 1'),
                    tooltip: this.tr('Group all hours to one row, regardless of hourly selling price. Quantity set as 1 and one total row for the sum.'),
                },
                { value: '1', name: this.tr('By entry, show employee'), tooltip: this.tr('All entries by users specified by row showing date, user, jobtype and description.') },
                { value: '6', name: this.tr('By entry, hide employee'), tooltip: this.tr('All entries by users, excluding user name, specified by row showing date, jobtype and description.') },
                { value: '10', name: this.tr("By entry, descriptions separately"), tooltip: this.tr("All entires separately showing the date and the user. Description will be separately on a description row."), addon: 'separate_description_grouping' },
                { value: '8', name: this.tr('By employee and entry'), tooltip: this.tr('Each employee separately shown by entry and date. Row showing employee, date, jobtype and description.') },
                { value: '9', name: this.tr("By dimension team and professional title"), tooltip: this.tr("Group all hours by user's dimension team and professional title."), addon: 'unit_and_protitle_grouping' },
            ],
        },
        expenseGrouping: {
            name: this.tr('Expenses Grouping'),
            options: [
                { value: 3, name: this.tr('Travel expenses all in one') },
                { value: 1, name: this.tr('Daily allowance, mileage and other expenses specified') },
                { value: 2, name: this.tr('Daily allowance and mileage in one, other expenses specified') },
            ],
        },
        quoteGrouping: {
            name: this.tr('Quote Grouping'),
            options: [
                { value: 2, name: this.tr('Do not import description rows from quote') },
                { value: 1, name: this.tr('Import description rows from quote') },
            ],
        },
    };

    savedSearchDialogs = {
        saveSearch: SaveSearchQuery,
        deleteSearch: DeleteSearchQuery,
    };

    constructor(props, context) {
        super(props, context, 'list/lists/MassInvoicingList');

        const {
            functions: { getCompany },
            taimerAccount: { currency },
        } = this.context;

        this.initialFilters = {
            dateRange: {
                startDate: undefined,
                endDate: undefined,
                key: 'selection',
            },
            company: getCompany('invoices', 'write_full', false, true) || getCompany('invoices', 'write_simple', false, true),
            accounts: [],
        };
        
        let defaultHideableColumns = [
            {
                key: 'summary_section',
                field: this.tr('Summary by user'), 
                selected: true,
            },
            {
                key: 'protitle_name', 
                field: this.tr('Professional title'), 
                selected: true, 
                parent: "summary_section"
            },
            {
                key: 'hours', 
                field: this.tr('Quantity'), 
                selected: true, 
                parent: "summary_section"
            },
            {
                key: 'average_price', 
                field: this.tr('Average price'), 
                selected: true, 
                parent: "summary_section"
            },
            {
                key: 'total', 
                field: this.tr('Total'), 
                selected: true, 
                parent: "summary_section"
            },
            {
                key: 'summary_per_project', 
                field: this.tr('Summary by project'), 
                selected: true,
            },
            {
                key: 'your_reference', 
                field: this.tr('Your reference'), 
                selected: true, 
                parent: "summary_per_project"
            },
            {
                key: 'worktype', 
                field: this.tr('Worktype'), 
                selected: true, 
                parent: "summary_per_project"
            },
            {
                key: 'hours', 
                field: this.tr('Quantity'), 
                selected: true, 
                parent: "summary_per_project"
            },
            {
                key: 'average_price', 
                field: this.tr('Unit price'), 
                selected: true, 
                parent: "summary_per_project"
            },
            {
                key: 'total', 
                field: this.tr('Total'), 
                selected: true, 
                parent: "summary_per_project"
            },
            {
                key: 'entries_section', 
                field: this.tr('Entries specified section'), 
                selected: true,
            },
            {
                key: 'description', 
                field: this.tr('Description'), 
                selected: false, 
                parent: "entries_section"
            }, 
            {
                key: 'worktype', 
                field: this.tr('Worktype'), 
                selected: true, 
                parent: "entries_section"
            },
            {
                key: 'resource_name', 
                field: this.tr('Task'), 
                selected: false, 
                parent: "entries_section"
            },
            {
                key: 'hours', 
                field: this.tr('Quantity'), 
                selected: true, 
                parent: "entries_section"
            },
            {
                key: 'average_price', 
                field: this.tr('Average price'), 
                selected: false, 
                parent: "entries_section"
            },
            {
                key: 'total', 
                field: this.tr('Total'), 
                selected: false, 
                parent: "entries_section"
            },
        ];

        try {
            const storedColumns = localStorage.getItem(COLUMNS_KEY)?.split(",") || [];
            defaultHideableColumns = defaultHideableColumns.map(hc => ({ ...hc, 
                selected: storedColumns.includes(`${hc.parent}-${hc.key}`)
            }));
        } catch (e) {
            console.error(e);
        }
        this.state = {
            data: [],
            companies: [],
            dialogRows: [],
            checkedRows: {},
            currency,
            dataLoaded: false,
            dialogOpen: false,
            loading: false,
            resetAccounts: false,
            stickySearchLoaded: false,
            listSettings: {
                perPage: 30,
                page: 1,
                totalCount: 0,
                pageCount: 0,
            },
            savedSearches: [],
            savedSearchDialogData: {},
            filters: this.initialFilters,
            selectedInvoiceGenerationSettings: {
                projectGrouping: 1,
                hourEntryProjectAndTaskGrouping: 1,
                hourEntryGrouping: this.context.addons.procountor_accounting ? 4 : 5,
                expenseGrouping: 1,
                quoteGrouping: 1,
                addHoursReport: false,
                addInvoiceHistoryReport: false,
                addAlreadyInvoicedRow: false,
                addMaterialPeriodRow: false,
                invoiceDate: moment().format('YYYY-MM-DD'),
                voucherDate: moment().format('YYYY-MM-DD'),
            },
            autoCompleteData: {},
            hideableColumns: defaultHideableColumns,
            viewMenuOpen: false,
            viewMenuAnchor: null
        };

    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getStickySearch();
        this.listenReset();
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        this.unlistenReset();
    };

    listenReset = () => {
        document.body.addEventListener('keyup', this.resetFilters);
    };

    unlistenReset = () => {
        document.body.removeEventListener('keyup', this.resetFilters);
    };

    toggleHideableColumns = (column) => {
        const hideableColumns = cloneDeep(this.state.hideableColumns);
        const columnIndex = hideableColumns.findIndex(c => c.key == column.key && c.parent == column.parent);
        if (columnIndex == -1) return;
        const selectedValue = !hideableColumns[columnIndex].selected;
        hideableColumns[columnIndex].selected = selectedValue;
        hideableColumns.forEach(c => {
            if (c.parent == column.key) {
                c.selected = selectedValue;
            }
        });
        if (column.parent) {
            const selectedChildValues = hideableColumns.filter(c => c.parent == column.parent && c.selected == true);
            const parentIndex = hideableColumns.findIndex(c => c.key == column.parent);
            if (parentIndex != -1) {
                hideableColumns[parentIndex].selected = selectedChildValues.length != 0;
            }
        }
        this.setState({ hideableColumns });
        try {
          localStorage.setItem(COLUMNS_KEY, hideableColumns.filter(sc => sc.selected).map(cs => `${cs.parent}-${cs.key}`).join(","));
        } catch (e) {}        
    }

    getStickySearch = () => {
        this.setState({ loading: true }, () => {
            DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` })
                .done((filters) => {
                    this.searchTerms = filters?.searchTerms || this.searchTerms;
                    let listSettings = cloneDeep(this.state.listSettings);
                    listSettings = {
                        ...listSettings,
                        sort: filters?.sort || listSettings.sort,
                    };
                    this.setState({ stickySearchLoaded: true, filters: filters || this.state.filters, listSettings }, () => {
                        this.getCompaniesAndData();
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.setState({ stickySearchLoaded: true });
                    this.getCompaniesAndData();
                });
        });
    };

    saveStickySearch = () => {
        const search = cloneDeep(this.state.filters);
        search.searchTerms = this.searchTerms;
        search.sort = this.state.listSettings?.sort;
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}` }, { search });
    };

    getCompaniesAndData = () => {
        const {
            filters: { company },
        } = this.state;
        return DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, 
            currency: 1, 
            allow_mass_invoicing: 1, 
            already_invoiced_total_row: 1, 
            show_material_period_on_invoice: 1 
        }).done((companies) => {
            companies = companies.filter((e) => e.allow_mass_invoicing == 1);
            const selectedCompany = (companies || []).find((c) => c.id == company) || companies[0];
            const currency = selectedCompany?.currency || this.context.taimerAccount.currency;
            this.initialFilters = {
                ...this.initialFilters,
                company: selectedCompany?.id || company,
            };
            this.setState(
                {
                    companies,
                    currency,
                    filters: {
                        ...this.state.filters,
                        company: selectedCompany?.id || company,
                    },
                    selectedInvoiceGenerationSettings: {
                        ...this.state.selectedInvoiceGenerationSettings, 
                        addAlreadyInvoicedRow: selectedCompany?.already_invoiced_total_row == 1 ? true : false,
                        addMaterialPeriodRow: selectedCompany?.show_material_period_on_invoice == 1 ? true : false,
                    }
                },
                () => {
                    this.getAutoCompleteData();
                    this.getSavedSearches();
                    this.getInvoiceSettings();
                    this.getData();
                }
            );
        });
    };

    getSavedSearches = () => {
        const { companies } = this.state;
        DataHandler.get({ url: `reports/get_saved_queries`, querytype: this.savedSearchKey, company: companies.map((c) => c.id) }).done((savedSearches) => {
            this.setState({ savedSearches });
        });
    };

    getAutoCompleteData = () => {
        const {
            company,
            dateRange: { startDate: startdate, endDate: enddate },
        } = this.state.filters;
        DataHandler.get({ url: `invoices/massInvoicingAutoCompleteData/${company}`, startdate, enddate })
            .done((autoCompleteData) => {
                autoCompleteData = {
                    ...autoCompleteData,
                    customers: (autoCompleteData.customers || []).map((c) => ({ ...c, label: c.name })),
                };
                this.setState({ autoCompleteData });
            })
            .fail((err) => {
                console.error(err);
            });
    };

    getData = () => {
        this.setState({ loading: true }, () => {
            const {
                listSettings,
                listSettings: { page, perPage: perpage, sort },
                filters: {
                    company,
                    accounts: selectedAccounts,
                    dateRange: { startDate: startdate, endDate: enddate },
                },
            } = this.state;
            const { freetextSearchTerm, advanced_search_criteria, mode } = this.searchTerms || {};
            let accounts = (selectedAccounts || []).map((a) => a.id);
            if (accounts.length == 0 || (accounts.length == 1 && selectedAccounts[0].value == 0)) {
                accounts = undefined;
            }
            DataHandler.post({ url: `invoices/mass_invoicing/${company}` }, { page, perpage, sort, startdate, enddate, accounts, freetextSearchTerm, advanced_search_criteria, mode, require_customers_address: true })
                .done((data) => {
                    this.setState(
                        { data: data.data, listSettings: Object.assign({}, listSettings, { totalCount: data.totalCount, pageCount: data.pageCount }), dataLoaded: true, loading: false },
                        () => {
                            this.list.current && this.list.current.endPageChangeAnimation();
                            this.saveStickySearch();
                        }
                    );
                })
                .fail((err) => {
                    console.error(err);
                    this.list.current && this.list.current.endPageChangeAnimation();
                    this.setState({ dataLoaded: true, loading: false });
                });
        });
    };

    getInvoiceSettings = () => {
        const { company } = this.state.filters;
        DataHandler.get({ url: `settings/invoicing/${company}`}).done((settings) => {
            this.setState({selectedInvoiceGenerationSettings:
                {
                    ...this.state.selectedInvoiceGenerationSettings,
                    hourEntryProjectAndTaskGrouping: this.invoiceGenerationSettings.hourEntryProjectAndTaskGrouping.options.find((e) => e.value == settings.invoicing_hour_task_grouping)?.value || 1,
                    hourEntryGrouping: this.invoiceGenerationSettings.hourEntryGrouping.options.find((e) => e.value == settings.invoicing_hour_grouping)?.value || this.context.addons.procountor_accounting ? 4 : 5,
                    expenseGrouping: this.invoiceGenerationSettings.expenseGrouping.options.find((e) => e.value == settings.invoicing_expense_grouping)?.value || 1,
                    quoteGrouping: this.invoiceGenerationSettings.quoteGrouping.options.find((e) => e.value == settings.invoicing_quote_grouping)?.value || 1,
                }
            });
        });
    }

    exportData = (target) => {
        const {
            listSettings,
            listSettings: { perPage: sort },
            filters: {
                company,
                accounts: selectedAccounts,
                dateRange: { startDate: startdate, endDate: enddate },
            }
        } = this.state;

        const allChecked = this.list.current && this.list.current.getAllChecked();
        const columns = this.list.current && this.list.current.state?.columns;
        const lang = this.context.userObject.language;
        const checkedRows = !allChecked ? this.state.checkedRows : null;
        const  fileName = this.tr("mass_invoicing_list_export");
        const { freetextSearchTerm, advanced_search_criteria, mode } = this.searchTerms || {};
        let accounts = (selectedAccounts || []).map((a) => a.id);
        if (accounts.length == 0 || (accounts.length == 1 && selectedAccounts[0].value == 0)) {
            accounts = undefined;
        }

        const columnTranslations = columns.reduce(function(map, column) {
            map[column.field] = column.transl;
            return map;
        }, {});
        
        DataHandler.postArrayBuffer({ url: `invoices/export_mass_invoicing/${company}` }, { checkedRows, startdate, enddate, accounts, freetextSearchTerm, advanced_search_criteria, mode, target: target, columns: columnTranslations, lang: lang, require_customers_address: true })
        .done((response) => {
            let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
                if (target == 'csv') {
                    type = 'text/csv';
                }
                const blob = new Blob([response], { type: type });
                FileSaver.saveAs(blob, `${fileName}.${target}`);
        })
        .fail((err) => {
            this.props.enqueueSnackbar(this.tr('Too many projects selected. Please select fewer projects'), {
                variant: 'error',
            });
            console.error(err);
        }); 
    }

    handleTabChange = (selectedPage) => {
        const {
            functions: { updateView },
        } = this.context;
        updateView({ selectedPage });
    };

    setDateRangeFilter = (startDate?: string, endDate?: string) => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    dateRange: {
                        startDate,
                        endDate,
                        key: 'selection',
                    },
                },
            },
            () => {
                this.getAutoCompleteData();
                this.getData();
            }
        );
    };

    onDateRangeChanged = (e) => {
        const { startDate, endDate } = e.selection;
        if (!startDate || !endDate) {
            this.setDateRangeFilter(undefined, undefined);
            return;
        }
        this.setDateRangeFilter(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    };

    onDateRangeInputChanged = (dateType, date) => {
        const newDate = moment(date).format('YYYY-MM-DD');
        const startDate = dateType == 'start' ? newDate : this.state.filters.dateRange.startDate;
        const endDate = dateType == 'end' ? newDate : this.state.filters.dateRange.endDate;
        this.setDateRangeFilter(startDate, endDate);
    };

    onFilterChanged = (e, noDataRequest = false) => {
        const { name, value } = e.target;
        if (isEqual(this.state.filters[name], value)) return;
        let otherUpdates;
        if (name == 'company') {
            const { companies } = this.state;
            const currency = companies.find((c) => c.id == value)?.currency || this.state.currency;
            otherUpdates = { currency };
            this.context.functions.setLastCompany(value);
        }
        const filters = {
            ...this.state.filters,
            [name]: value,
        };
        if (value == -1) {
            delete filters[name];
        }
        this.setState({ filters, ...otherUpdates, selectedSearch: !noDataRequest ? undefined : this.state.selectedSearch }, () => {
            !noDataRequest && this.resetListAndGetData(name == 'company');
        });
    };

    resetListAndGetData = (getAutoCompleteData = false) => {
        if (this.list.current) {
            this.list.current.resetCheckedRows();
        }
        if (getAutoCompleteData) {
            this.getAutoCompleteData();
            this.getSavedSearches();
        }
        this.onListSettingsChanged({ page: 1 }, true);
    };

    resetFilters = (e?) => {
        if (!e || e.keyCode == '27') {
            this.searchTerms = undefined;
            this.advancedSearch.current.clearSearch(undefined, true);
            this.advancedSearch.current.clearSearchTextInput();
            this.setState(
                {
                    resetAccounts: true,
                    filters: {
                        ...this.state.filters,
                        ...this.initialFilters,
                    },
                },
                () => this.resetListAndGetData()
            );
        }
    };

    generateInvoices = (bill_type) => {
        const {
            data,
            listSettings: { totalCount },
            selectedInvoiceGenerationSettings,
            dialogRows: projects,
            filters: {
                company,
                accounts: selectedAccounts,
                dateRange: { startDate: startdate, endDate: enddate },
            },
        } = this.state;

        const selectedProjects = this.list.current?.getAllChecked() ? [] : projects;
        const { freetextSearchTerm, advanced_search_criteria, mode } = this.searchTerms || {};
        
        const useSearch = this.list.current?.getAllChecked();
        let accounts = (selectedAccounts || []).map((a) => a.id);
        if (accounts.length == 0 || (accounts.length == 1 && selectedAccounts[0].value == 0)) {
            accounts = undefined;
        }

        this.closeMaterialDialog();

        const mainHidden = this.state.hideableColumns.filter(column => (column.selected == false && column.parent == 'entries_section')).map(c => {
            return c.key;
        });
        const extraHidden = this.state.hideableColumns.filter(column => (column.selected == false && column.parent == 'summary_section')).map(c => {
            return c.key;
        });
        const projectHidden = this.state.hideableColumns.filter(column => (column.selected == false && column.parent == 'summary_per_project')).map(c => {
            return c.key;
        });

        DataHandler.put(
            { url: `invoices/mass_invoicing/create` },
            { selectedInvoiceGenerationSettings, bill_type, company, projects: selectedProjects,
                require_customers_address: true, startdate, enddate, accounts, freetextSearchTerm,
                advanced_search_criteria, mode, useSearch,
                report_settings: {
                    hideMainSection: this.state.hideableColumns.findIndex(c => c.selected == false && c.key == "entries_section") != -1, 
                    hideExtraSection: this.state.hideableColumns.findIndex(c => c.selected == false && c.key == "summary_section") != -1,
                    hideProjectSection: this.state.hideableColumns.findIndex(c => c.selected == false && c.key == "summary_per_project") != -1,
                    mainHidden,
                    extraHidden,
                    projectHidden
                }
            }
        );

        const sentIds = selectedProjects.map((e) => e.id);
        const filtered = cloneDeep(data).filter((e) => !sentIds.includes(e.id));

        this.setState({ data: filtered });

        this.props.enqueueSnackbar(this.tr('Invoice creation in progress. You can leave this view, you will receive a notification when the creation is ready.'), {
            variant: 'info',
        });

        const analyticsData = {
            invoices_created: useSearch ? this.state.listSettings.totalCount : projects.length,
            invoice_type: 'Mass Invoicing',
            taimer_version: this.context.versionId,
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics('GA4', 'event', 'invoice_created', analyticsData);
    };

    onLockMaterial = () => {
        if (!this.list.current) return;
        this.showMaterialDialog(this.list.current.getCheckedData());
    };

    onLockRow = (row) => {
        this.showMaterialDialog([row]);
    };

    onCreateRegularInvoice = (row) => {
        const {
            dateRange: { startDate: start, endDate: end },
        } = this.state.filters;
        this.context.functions.updateView({
            module: 'invoices',
            action: 'view',
            editMode: 1,
            projects_id: row.id,
            customers_id: row.customers_id,
            companies_id: row.companies_id,
            preselect: 'projectInvoice',
            template: 'material',
            start,
            end,
        });
    };

    showMaterialDialog = (dialogRows) => {
        //lock material here
        this.setState({ dialogOpen: true, dialogRows });
    };

    closeMaterialDialog = () => {
        //unlock material here
        this.list.current.uncheckAll();
        this.setState({ dialogOpen: false, dialogRows: [] });
    };

    onListSettingsChanged = (value, pageAnimation = false) => {
        let listSettings = cloneDeep(this.state.listSettings);
        listSettings = {
            ...listSettings,
            ...value,
        };
        if (pageAnimation) {
            this.list.current && this.list.current.startPageChangeAnimation();
        }
        this.setState({ listSettings }, () => {
            this.getData();
        });
    };

    onSearchTrigger = (searchTerms) => {
        this.searchTerms = searchTerms;
        this.resetListAndGetData();
    };

    filtersAreInInitialState() {
        const initial = cloneDeep(this.initialFilters);
        const filters = {};
        for (const key in initial) {
            let value = this.state.filters[key];
            if (key == 'accounts' && value) {
                if (value.length == 1 && value[0].value == 0) {
                    value = [];
                }
            }
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(value);
        }
        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : '';
        return isEqual(initial, filters) && !freetext;
    }

    resetSelectedAccounts = () => {
        this.setState({ resetAccounts: false }, () => {
            this.onFilterChanged({ target: { name: 'accounts', value: [] } }, true);
        });
    };

    setSelectedAccounts = (value) => {
        this.onFilterChanged({ target: { name: 'accounts', value } });
    };

    renderTopSection = (hideFilters = false) => {
        const {
            filters,
            autoCompleteData: {
                customers,
                projects,
                branch_of_business,
                project_types,
                project_tags,
                project_managers,
                sales_agents,
                account_managers,
                account_groups,
                enterprise_groups,
                reporting_groups,
            },
            listSettings: { perPage, totalCount },
            savedSearches,
            selectedSearch,
            loading,
            companies,
            resetAccounts,
            checkedRows,
        } = this.state;
        const { userObject } = this.context;
        return (
            <div className="listControlsContainer clearfix">
                {!hideFilters && (
                    <div className={styles.filterContainer}>
                        {companies.length > 1 && (
                            <OutlinedField className="listFilterOutlinedField" select label={this.tr('Company')} value={filters.company} name="company" onChange={this.onFilterChanged}>
                                {companies.map((row) => (
                                    <MenuItem key={row.id} value={row.id}>
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </OutlinedField>
                        )}
                        <DateRangePicker
                            className={styles.dateRange}
                            ranges={[filters.dateRange]}
                            onChange={this.onDateRangeChanged}
                            onInputChange={this.onDateRangeInputChanged}
                            label={this.tr('Time span')}
                            dateFormat={userObject.dateFormat}
                            showClearButton
                        />
                        <MultiSelect
                            className={`listFilterOutlinedField ${styles.multiselect}`}
                            options={customers}
                            defaultValue={filters.accounts}
                            reset={resetAccounts}
                            resetFunction={this.resetSelectedAccounts}
                            resetValue={filters.accounts}
                            label={this.tr('Account')}
                            name="accounts"
                            onChange={this.setSelectedAccounts}
                            skipInitialChange
                        />
                        {(savedSearches || []).length > 0 && (
                            <OutlinedField select className="listFilterOutlinedField" label={this.tr('Saved searches')} name="savedSearches" value={selectedSearch} onChange={this.onSearchSelected}>
                                <MenuItem className="googleTranslateHide" value={0}>
                                    {' '}
                                </MenuItem>
                                {/* Blocks Chrome from translating empty element, hidden with css from menu */}
                                <MenuItem value={-1}>{this.tr('Empty search')}</MenuItem>
                                {savedSearches.map((option: any) => {
                                    return (
                                        <MenuItem key={option.id} value={option.id}>
                                            <div style={{ flex: 1 }}>{option.name}</div>
                                            <ListItemIcon className={styles.menuItemAction}>
                                                <Clear
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.openDeleteSearchDialog(e, option.id);
                                                    }}
                                                />
                                            </ListItemIcon>
                                        </MenuItem>
                                    );
                                })}
                            </OutlinedField>
                        )}
                        <AdvancedSearch
                            ref={this.advancedSearch}
                            mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                            initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
                            alwaysShowClearFilters={!this.filtersAreInInitialState()}
                            mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined}
                            freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ''}
                            onClearSearch={this.resetFilters}
                            fields={this.listColumns.filter((c) => !c.hideFromSearch)}
                            autoCompleteData={{
                                account: [customers, 'parent_id'],
                                project: [projects, 'parent_id'],
                                category: [branch_of_business?.filter(pc => pc.deleted < 1 && pc.locked < 1), "parent_id"],
                                project_type: project_types,
                                tag: project_tags,
                                project_manager: project_managers,
                                sales_agent: sales_agents,
                                account_manager: account_managers,
                                account_group: account_groups,
                                enterprise_group: enterprise_groups,
                                reporting_group: reporting_groups,
                            }}
                            perpage={perPage}
                            noRequests={true}
                            onSearchTrigger={this.onSearchTrigger}
                        >
                            <Button className={styles.saveSearchButton} onClick={this.openSaveSearchDialog}>
                                <Save />
                                {this.tr('Save this search')}
                            </Button>
                        </AdvancedSearch>
                    </div>
                )}
                <PageTopSection
                    summaries={[{ title: this.tr('Invoiceable projects'), value: totalCount || 0 }]}
                    mainButtons={[
                        {
                            title: this.tr('Generate Invoices (top button)'),
                            action: this.onLockMaterial,
                            disabled: Object.keys(checkedRows).length <= 0,
                            isVisible: true,
                            noIcon: true,
                            color: colors.dark_sky_blue,
                        },
                    ]}
                    settingsButton={{
                        isVisible: !(null == this.context.privileges.admin),
                        title: this.tr('Settings'),
                        href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }),
                        action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }, false),
                    }}
                />
            </div>
        );
    };

    onRowCheck = ({ checkedRows }) => {
        this.setState({ checkedRows });
    };

    renderList = (data, materialConfirmationMode = false) => {
        const {
            autoCompleteData,
            dataLoaded,
            listSettings: { pageCount, totalCount, perPage, page },
            currency,
        } = this.state;
        const allChecked = this.list.current && this.list.current.getAllChecked();

        return (
            <List
                ref={!materialConfirmationMode && this.list}
                data={data}
                columns={this.listColumns.filter((c) => materialConfirmationMode ? !c.hideFromMaterialDialog : !c.onlyInMaterialDialog)}
                sharedData={autoCompleteData}
                height="fitRemaining"
                onCheck={this.onRowCheck}
                listRowType={MassInvoicingListRow}
                rowProps={{
                    onLockRow: this.onLockRow,
                    onCreateRegularInvoice: this.onCreateRegularInvoice,
                    currency,
                }}
                summaryRowProps={{ currency }}
                summaryRowType={MassInvoicingSummaryRow}
                enableToolbar={false}
                hiddenToolbarColumns={['delete', 'edit', 'export']}
                additionalToolbarColumns={[{ name: 'generateInvoices', header: '', columnHeaderType: 'generateInvoicesButton', width: 100 }]}
                additionalToolbarButtons={{
                    generateInvoicesButton: <ColumnHeaderButton title={this.tr('Generate Invoices')} onClick={this.onLockMaterial} icon={Lock} />,
                }}
                trimHeight={materialConfirmationMode ? -80 - 120 + 32 : 0} // top & bottom bar height + some random offset
                // saveColumnConfig={!materialConfirmationMode}
                // userListSettingsKey="mass_invoicing_list_config_v2"
                showNoResultsMessage={dataLoaded}
                showPageSelector={allChecked || !materialConfirmationMode}
                ignoreRowPropsChange={false}
                noStateData={true}
                pageCount={pageCount}
                totalCount={totalCount}
                perpage={perPage}
                page={page}
                controlPage={true}
                useAllCheckedExcept={true}
                onPageChange={(page) => this.onListSettingsChanged({ page }, true)}
                onSortRows={(name, asc) => this.onListSettingsChanged({ sort: { name, asc } })}
                onPerPageChange={(perPage) => {
                    this.onListSettingsChanged({ perPage, page: 1 }, true);
                }}
                useHSRightPadding
            />
        );
    };

    openSaveSearchDialog = () => {
        this.setState({
            savedSearchDialogData: {
                name: '',
                userId: '',
                saveFunc: 'saveSearch',
            },
        });
        this.setState({ savedSearchDialog: 'saveSearch' });
    };

    openDeleteSearchDialog = (evt, id) => {
        this.setState({
            savedSearchDialogData: {
                id: id,
                saveFunc: 'deleteSearch',
                text: this.tr('Are you sure you want to delete this saved search?'),
            },
        });
        this.setState({ savedSearchDialog: 'deleteSearch' });
    };

    deleteSearch = (id) => {
        const query = this.state.savedSearches.find((q) => q.id == id);
        const squery = this.state.selectedSearch;
        DataHandler.get({ url: `reports/delete_query`, id }).done(() => {
            this.closeSavedSearchDialog();
            if (query.id == squery) {
                this.resetFilters();
            }
            setTimeout(() => {
                this.getSavedSearches();
            }, 1000);
        });
    };

    onSearchSelected = (e) => {
        const selectedSearch = e.target.value;
        if (this.state.selectedSearch == selectedSearch || selectedSearch == '') return;
        if (Number(selectedSearch) < 1) {
            this.setState({ selectedSearch: undefined }, () => {
                this.resetFilters();
            });
            return;
        } else {
            const savedSearch = this.state.savedSearches.find((item) => item.id == selectedSearch);
            if (!savedSearch) return;
            const searchData = JSON.parse(savedSearch.querystring);
            const { filters, listSettings, searchTerms } = searchData;
            this.searchTerms = searchTerms;
            const prevCompany = this.state.filters.company;
            let otherUpdates = {};
            if (filters.company != prevCompany) {
                const { companies } = this.state;
                const currency = companies.find((c) => c.id == filters.company)?.currency || this.state.currency;
                otherUpdates = { currency };
                this.context.functions.setLastCompany(filters.company);
            }
            this.setState(
                {
                    ...otherUpdates,
                    selectedSearch,
                    resetAccounts: true,
                    filters: {
                        ...this.state.filters,
                        ...filters,
                    },
                    listSettings: {
                        ...this.state.listSettings,
                        ...listSettings,
                    },
                },
                () => {
                    this.getData();
                    if (prevCompany != this.state.filters.company) {
                        this.getAutoCompleteData();
                    }
                }
            );
        }
    };

    closeSavedSearchDialog = () => {
        this.setState({ savedSearchDialog: undefined });
    };

    onSavedSearchDialogSave = (saveFunc, data) => {
        this[saveFunc](data);
    };

    saveSearch = (data) => {
        const {
            filters: { company },
        } = this.state;
        const search = {
            filters: this.state.filters,
            listSettings: {
                sort: this.state.listSettings?.sort,
                perPage: this.state.listSettings?.perPage,
            },
            searchTerms: this.searchTerms,
        };
        const params = {
            name: data.name,
            is_private: data.isPrivate,
            querystring: search,
            querytype: this.savedSearchKey,
            company,
        };
        DataHandler.post({ url: `reports/save_query` }, params).done(() => {
            this.closeSavedSearchDialog();
            setTimeout(() => {
                this.getSavedSearches();
            }, 1000);
        });
    };

    setSelectedInvoiceGenerationSetting = (e) => {
        this.setState({
            selectedInvoiceGenerationSettings: {
                ...this.state.selectedInvoiceGenerationSettings,
                [e.target.name]: e.target.checked !== undefined ? e.target.checked : e.target.value,
            },
        });
    };

    renderMaterialDialog = () => {
        const { dialogOpen, selectedInvoiceGenerationSettings, dialogRows, data } = this.state;
        const { userObject } = this.context;
        const rows = this.list.current && this.list.current.getAllChecked() ? data : dialogRows;

        let grayedColumns = this.state.hideableColumns.find(hc => (hc.key === 'description' && hc.parent == "entries_section" && !hc.selected)) ? ' descriptionGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'worktype' && hc.parent == "entries_section" && !hc.selected)) ? ' worktypeGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'resource_name' && hc.parent == "entries_section" && !hc.selected)) ? ' taskGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'hours' && hc.parent == "entries_section" && !hc.selected)) ? ' hoursGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'total' && hc.parent == "entries_section" && !hc.selected)) ? ' totalGreyed ' : '';

        return (
            <Dialog className={styles.materialDialog} PaperProps={{ classes: { root: styles.paper } }} open={dialogOpen}>
                <div className={styles.materialDialogHeader}>
                    <h1>{this.tr('Generate Invoices (header)')}</h1>
                    <span className={styles.materialDialogExport} title={this.tr("Export")}><CloudDownload onClick={() => this.exportData('xlsx')} /></span>
                </div>
                {this.renderList(rows, true)}
                <div className={styles.materialDialogFooter}>
                    <h2>{this.tr('Invoice Settings')}</h2>
                    <div>
                        <div className={styles.materialDialogFilters}>
                            <DatePicker
                                label={this.tr('Invoice date')}
                                className="full date"
                                date={moment(selectedInvoiceGenerationSettings.invoiceDate).toDate()}
                                dateFormat={userObject.dateFormat}
                                onChange={(date) => this.setSelectedInvoiceGenerationSetting({ target: { name: 'invoiceDate', value: moment(date).format('YYYY-MM-DD') } })}
                                onInputChange={(text, date) => this.setSelectedInvoiceGenerationSetting({ target: { name: 'invoiceDate', value: moment(date).format('YYYY-MM-DD') } })}
                                usePopper
                            />
                            {this.context.addons?.sap && <DatePicker
                                label={this.tr('Accounting date')}
                                className="full date"
                                date={moment(selectedInvoiceGenerationSettings.voucherDate).toDate()}
                                dateFormat={userObject.dateFormat}
                                onChange={(date) => this.setSelectedInvoiceGenerationSetting({ target: { name: 'voucherDate', value: moment(date).format('YYYY-MM-DD') } })}
                                onInputChange={(text, date) => this.setSelectedInvoiceGenerationSetting({ target: { name: 'voucherDate', value: moment(date).format('YYYY-MM-DD') } })}
                                usePopper
                            />}
                            {Object.keys(this.invoiceGenerationSettings).map((settingKey) => {
                                return (
                                    <OutlinedField
                                        className="listFilterOutlinedField"
                                        select
                                        name={settingKey}
                                        label={this.invoiceGenerationSettings[settingKey].name}
                                        value={selectedInvoiceGenerationSettings[settingKey]}
                                        onChange={this.setSelectedInvoiceGenerationSetting}
                                    >
                                        {(this.invoiceGenerationSettings[settingKey].options || []).map((setting: any) => {
                                            if (setting.addon && setting.addon == 'unit_and_protitle_grouping' && !(this.context.addons?.unit_and_protitle_grouping?.used_by_companies.indexOf(this.state.filters.company) > -1)) {
                                                return null;
                                            }
                                            if (setting.addon && setting.addon == 'separate_description_grouping' && !(this.context.addons?.separate_description_grouping?.used_by_companies.indexOf(this.state.filters.company) > -1)) {
                                                return null;
                                            }
                                            if (setting.tooltip && setting.tooltip.length > 0) {
                                                return (
                                                    <MenuItem value={setting.value} disabled={setting.disabled}>
                                                        <ToolTip title={setting.tooltip} placement="right">
                                                            <span>{setting.name}</span>
                                                        </ToolTip>
                                                    </MenuItem>
                                                );
                                            } else {
                                                return <MenuItem value={setting.value}>{setting.name}</MenuItem>;
                                            }
                                        })}
                                    </OutlinedField>
                                );
                            })}
                            <div className={styles.materialDialogAttachmentSettings  + " " + grayedColumns}>
                                <div>
                                    <Checkbox color="primary" name="addHoursReport" onChange={this.setSelectedInvoiceGenerationSetting} checked={selectedInvoiceGenerationSettings.addHoursReport} />
                                    {this.tr('Add Hours Report')}
                                    <span className={styles.hourReportOptions} onClick={e => {
                                        this.setState({ 
                                            viewMenuAnchor: this.state.viewMenuOpen ? null : e.currentTarget, 
                                            viewMenuOpen: !this.state.viewMenuOpen
                                        })
                                    }}>
                                        {this.tr('Hour report options')}
                                    </span>
                                    <Popover
                                        open={this.state.viewMenuOpen}
                                        onClose={() => {
                                            this.setState({
                                                viewMenuAnchor: null,
                                                viewMenuOpen: false
                                            });
                                        }}
                                        anchorEl={this.state.viewMenuAnchor} 
                                        anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        className="hide-columns-popover"
                                        style={{}}>
                                        <MUIList>
                                            {this.state.hideableColumns.map(column => {
                                                return (
                                                    <MUIListItem className="material-pdf-hide-columns-option">
                                                        <Checkbox
                                                            color="primary"
                                                            style={{ paddingLeft: column.parent ? 26 : undefined }}
                                                            onChange={(e) => {
                                                                this.toggleHideableColumns(column);
                                                            }}
                                                            checked={column.selected}
                                                        />
                                                        <ListItemText primary={column.field} className="hide-columns-checkbox-text" />
                                                    </MUIListItem>
                                                )})}
                                        </MUIList>
                                    </Popover>
                                </div>
                                <div>
                                    <Checkbox
                                        color="primary"
                                        name="addInvoiceHistoryReport"
                                        onChange={this.setSelectedInvoiceGenerationSetting}
                                        checked={selectedInvoiceGenerationSettings.addInvoiceHistoryReport}
                                    />
                                    {this.tr('Add Invoice History Report')}
                                </div>
                                <div>
                                    <Checkbox color="primary" name="addAlreadyInvoicedRow" onChange={this.setSelectedInvoiceGenerationSetting} checked={selectedInvoiceGenerationSettings.addAlreadyInvoicedRow} />
                                    {this.tr('Add Already Invoiced Total Row')}
                                </div>
                                <div>
                                    <Checkbox color="primary" name="addMaterialPeriodRow" onChange={this.setSelectedInvoiceGenerationSetting} checked={selectedInvoiceGenerationSettings.addMaterialPeriodRow} />
                                    {this.tr('Add Material Period Row')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.materialDialogButtons}>
                            <Button onClick={this.closeMaterialDialog} variant="text" size="large">
                                {this.tr('Cancel')}
                            </Button>
                            <ContextMenu
                                //@ts-ignore
                                buttonProps={{
                                    className: styles.optionsMenuButton,
                                    stickyIcon: true,
                                    size: 'large',
                                }}
                                variant="outlined"
                                className={styles.optionsMenu}
                                label={this.tr('Generate Invoices (material dialog button)')}
                                size="medium"
                                placement={'top-end'}
                            >
                                <MenuItem onClick={() => this.generateInvoices('1')}>
                                    <Receipt />
                                    {this.tr('Generate Invoices (material dialog button option)')}
                                </MenuItem>
                                <MenuItem onClick={() => this.generateInvoices('2')}>
                                    <Schedule />
                                    {this.tr('Generate Pre-Invoices')}
                                </MenuItem>
                            </ContextMenu>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    };

    render() {
        const { data, companies, stickySearchLoaded, savedSearchDialog, savedSearchDialogData } = this.state;
        const {
            functions: { hasPrivilege },
        } = this.context;

        const noPermission =
            (companies.length > 0 && companies.findIndex((c) => c.allow_mass_invoicing == 1) == -1) || !hasPrivilege('invoices', ['write_simple', 'write_full']);

        const Dialog = savedSearchDialog ? this.savedSearchDialogs[savedSearchDialog] : undefined;

        return (
            <div className="contentBlock" id={styles.massInvoicingList}>
                {this.renderTopSection(noPermission || !stickySearchLoaded)}
                {noPermission ? (
                    <NoPermissionOverlay />
                ) : (
                    <>
                        {stickySearchLoaded && this.renderList(data)}
                        {this.renderMaterialDialog()}
                        {Dialog && <Dialog open onDialogClose={this.closeSavedSearchDialog} onDialogSave={this.onSavedSearchDialogSave} data={savedSearchDialogData} />}
                    </>
                )}
            </div>
        );
    }
}

export default withSnackbar(MassInvoicingList);
