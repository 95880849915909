import { Component } from 'react';

interface Props {
    data: any;
    style: {[cssProperty: string]: string | number};
    params: {
        colDef: {
            grandTotal: string, 
            pivotValueColumn?: any, 
            field?: string, 
            valueFormatter?: (params) => string,
            defaultAggFunc?: string
        },
        value
    };
}

export default class CustomPivotPinnedRowRenderer extends Component<Props> {
  render() {
    const { data, params } = this.props;
    const { colDef: { grandTotal, pivotValueColumn, field, valueFormatter, defaultAggFunc } } = params;
    let displayValue = "";

    if (grandTotal && grandTotal != "none") {
      params.value = data[field || ""];

      if (pivotValueColumn.aggFunc == "count") {
        params.value = `${params.value} values`;
      }
      else if (typeof valueFormatter === "function") {
        params.value = valueFormatter(params);
      }
      displayValue = params.value;
    }

    const agg = pivotValueColumn.aggFunc == "sumAndGroup"
      ? defaultAggFunc
      : pivotValueColumn.aggFunc;

    const grandTotalValue = grandTotal == "sum_per"
      ? "sum"
      : grandTotal;

    if (agg != grandTotalValue) {
      displayValue = "";
    }

    return <span style={this.props.style}>{displayValue}</span>;
  }
}