import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import getProjectColumns from './ProjectColumns';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";

// const columnOrder = [
//     'project',
//     'material_date_month',
//     'invoice_invoiced',
// ];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    getPriorityIcon: (data: any) => any;
    useAutoHeight?: boolean;
}

export default function getResourcingColumns(params: Params) {

    const columns: ColumnDefinition[] = [    
        {
            field: 'description',
            headerName: 'Description',
            ...getColumnType('text'),
            wrapText: true,
            autoHeight: true
        },
        {
            field: 'status',
            headerName: 'Status	',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: '1', "name": "In progress", "color": "#FBB823"},
                    {id: '2', "name": "overdue", "color": "#F85623"},
                    {id: '3', "name": "Done", "color": "#50E4C2"},
                    {id: '4', "name": "Milestone", "color": "#D33DAF"},
                ],
                tr: params.tr
            },
            autoHeight: params.useAutoHeight
        },
        {
            field: "created_by",
            headerName: "Created by",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.created_by_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: "assigned_to",
            headerName: "Assigned To",
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.assigned_to_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'startdate',
            headerName: 'Start date',
            ...getColumnType('date', null, 'start_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'enddate',
            headerName: 'End date',
            ...getColumnType('date', null, 'end_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'starttime',
            headerName: 'Start time',
            ...getColumnType('time', null, 'start_time'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'endtime',
            headerName: 'End time',
            ...getColumnType('time', null, 'end_time'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'priority',
            headerName: 'Priority	',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '1', "name": "Lowest"},
                    {id: '2', "name": "Low"},
                    {id: '3', "name": "Normal"},
                    {id: '4', "name": "High"},
                    {id: '5', "name": "Highest"},
                ],
                getIconComponent: params.getPriorityIcon,      
                tr: params.tr
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 'task', "name": "Task"},
                    {id: 'milestone', "name": "Milestone"},
                ],
                tr: params.tr
            },
        },
        {
            field: 'task_type',
            headerName: 'Task type',
            ...getColumnType('text'),
        },
        {
            field: 'allocated_hours',
            headerName: 'Allocated hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
        },
        {
            field: 'resourced_hours',
            headerName: 'Resourced hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
        },
        {
            field: 'tracked_hours',
            headerName: 'Tracked Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
        },
        {
            field: 'remaining_hours',
            headerName: 'Remaining hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
        },
        {
            field: "company",
            headerName: "Company",
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'additional_description',
            headerName: 'Internal note',
            ...getColumnType('text'),
            wrapText: true,
            autoHeight: true
        },
        {
            field: 'parent_description',
            headerName: 'Parent task',
            ...getColumnType('text'),
            wrapText: true,
            autoHeight: true
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
    ];

    // columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "resourcing", 
        params.settingsContext
    );
}
