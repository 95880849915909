import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../SettingsContext';
import { KeyboardArrowDown, KeyboardArrowRight, OpenInNew, Star } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Link from '../general/Link';
import TabInfoButton from '../general/TabInfoButton';
import './DropdownTabs.css';
import Close from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';

const DropdownItem = (props) => {
    const { id, type, name, items, label, icon, newTab, index, subDropdownIndex, setSubDropdownIndex, selectedSubTabChild, hideDropdown, onClick, active, checkItemPrivilege, url, generateUrlForItem, upgrade, target, disabled, tooltip } =
        props;
    const context = useContext(SettingsContext);

    const [subDropdownVisible, setSubDropdownVisible] = useState(false);
    const [showOverflowTooltip, setShowOverflowTooltip] = useState(false);

    const labelRef     = React.createRef();
    const mainContentRef = React.createRef()

    let hideDropdownTimer;

    // hide dropdown quickly if hovering over another tab
    if (subDropdownVisible && subDropdownIndex != index) {
        setSubDropdownVisible(false);
    }

    const showSubDropdown = () => {
        cancelHidingSubDropdown();
        setSubDropdownIndex && setSubDropdownIndex(index);
        setSubDropdownVisible(true);

        if(!showOverflowTooltip && !tooltip && (labelRef?.current?.scrollWidth + 16) > mainContentRef?.current?.scrollWidth) {
            setShowOverflowTooltip(true);
		}
    };

    const hideSubDropdown = () => {
        hideDropdownTimer = setTimeout(() => {
            setSubDropdownVisible(false);
        }, 200);
    };

    const cancelHidingSubDropdown = () => {
        hideDropdownTimer && clearTimeout(hideDropdownTimer);
        hideDropdownTimer = null;
    };

    const filteredItems = (items || []).filter((item) => !isItemHidden(item, checkItemPrivilege));
    if (items && items.length > 0 && filteredItems.length == 0) return null;
    if (type === 'divider') return null;

    const content = (
        <>
            {icon && React.cloneElement(icon, { className: `${icon.props.className} icon` })}
            <h3 ref={labelRef}>{label || name}</h3>
            {items && items.length > 0 && <KeyboardArrowRight className="indicator" />}
            {newTab && <OpenInNew className="indicator" />}
            {upgrade && !(items && items.length > 0) && <Star className='indicator star' />}
        </>
    );

    let mainItem = ((url || target) && !upgrade && !disabled ? (
        <Link
            noColor
            url={url || target}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            <div ref={mainContentRef} className={`dropdown-item-content ${active && 'active'}`}>{content}</div>
        </Link>
    ) : (
        <div ref={mainContentRef} onClick={disabled ? undefined : (upgrade ? () => context.functions.showUpgradeSlider() : () => onClick())} className={`dropdown-item-content ${active && 'active'} ${disabled && 'disabled'}`}>
            {content}
        </div>
    ))

    if (tooltip) {
        mainItem = (
            <Tooltip title={tooltip}>
                {mainItem}
            </Tooltip>
        )
    }

    const item = (
        <div data-testid={`${props.id}-dropdown-item`} className="tabs-dropdown-item" onMouseEnter={showSubDropdown} onMouseLeave={hideSubDropdown}>
            {mainItem}
            {subDropdownVisible && filteredItems && filteredItems.length > 0 && (
                <div onMouseEnter={cancelHidingSubDropdown} className="dropdown-container sub">
                    {filteredItems.map((i) => {
                        if (isItemHidden(i, checkItemPrivilege)) return null;
                        if (i.type === 'divider') return null;

                        return (
                            <DropdownItem
                                key={i.id}
                                url={(i.destination || i.getDestination) ? (i.destination || i.getDestination(i)) : generateUrlForItem && generateUrlForItem({ id }, i)}
                                {...i}
                                onClick={
                                    i.onClick ||
                                    (() => {
                                        if (i.newTab) {
                                            (i.destination || i.getDestination) && context.functions.updateView(i.destination || i.getDestination(i), true);
                                            hideDropdown && hideDropdown();
                                            return;
                                        }
                                        i.action && i.action(id, i.id);
                                        onClick(i.id);
                                    })
                                }
                                active={selectedSubTabChild == i.id}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );

    return showOverflowTooltip && filteredItems?.length < 1 ?
        <Tooltip title={label || name} placement={'left'}>
            {item}
        </Tooltip>
        : item;
};

const Tab = (props) => {
    const {
        id,
        label,
        name,
        active,
        closeable,
        showWithEmptyItems,
        items,
        setDropdownIndex,
        dropdownIndex,
        tabIndex,
        onClick,
        onClose,
        selectedSubTabChild,
        setSelectedSubTabChild,
        selectedSubItem,
        setSelectedTab,
        setSelectedSubTab,
        checkItemPrivilege,
        url,
        generateUrlForItem,
        disabled,
        upgrade,
        target,
        disableDropdown,
        hideDropdownWithOneChild,
        notification,
        tooltip
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [subDropdownIndex, setSubDropdownIndex] = useState(null);
    const [showOverflowTooltip, setShowOverflowTooltip] = useState(false);

    let hideDropdownTimer;

    const context = useContext(SettingsContext);

    const labelRef     = React.createRef();
    const mainContentRef = React.createRef();

    // hide dropdown quickly if hovering over another tab
    if (dropdownVisible && dropdownIndex != tabIndex) {
        setDropdownVisible(false);
    }

    const showDropdown = () => {
        cancelHidingDropdown();
        setDropdownIndex(tabIndex);
        setDropdownVisible(true);

        if(!showOverflowTooltip && !tooltip && labelRef?.current?.scrollWidth + 40 > mainContentRef?.current?.scrollWidth) {
            setShowOverflowTooltip(true);
		}
    };

    const hideDropdown = () => {
        hideDropdownTimer = setTimeout(() => {
            setDropdownVisible(false);
        }, 200);
    };

    const cancelHidingDropdown = () => {
        hideDropdownTimer && clearTimeout(hideDropdownTimer);
        hideDropdownTimer = null;
    };

    const clickClose = (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("clickClose")

        onClose?.();
    }

    const filteredItems = (items || []).filter((item) => !isItemHidden(item, checkItemPrivilege));
    if (items && !showWithEmptyItems && filteredItems.length == 0) return null;

    const showArrow = !disableDropdown && (items && items.length > (hideDropdownWithOneChild ? 1 : 0));
    const content = (
        <>
            <Badge badgeContent={notification && notification} className={notification ? "show-badge" : ''}>
                <h2 ref={labelRef}>{label || name}</h2>
            </Badge>
            {showArrow && <KeyboardArrowDown />}
            {upgrade && !showArrow && <Star className='tab-upgrade-indicator' />}
            {closeable && <div className="close-indicator" onClick={clickClose}>
                <Close />
            </div>}
            {active && <div className="active-indicator" />}
        </>
    );

    const item = (
        <div onMouseEnter={!disableDropdown ? showDropdown : undefined} onMouseLeave={hideDropdown} className={`tab ${active && 'active'} ${disabled && 'disabled'}`}>
            {(url || target) && !upgrade ? (
                <Link data-testid={`${id}-tab`} noColor url={url || target} onClick={onClick}>
                    <div className="main-content" ref={mainContentRef}>{content}</div>
                </Link>
            ) : (
                <div ref={mainContentRef} data-testid={`${id}-tab`} onClick={upgrade ? () => context.functions.showUpgradeSlider() : onClick} className="main-content">
                    {content}
                </div>
            )}
            {dropdownVisible && filteredItems && filteredItems.length > (hideDropdownWithOneChild ? 1 : 0) && (
                <div onMouseEnter={cancelHidingDropdown} className="dropdown-container">
                    {filteredItems.map((i, index) => {
                        if (isItemHidden(i, checkItemPrivilege)) return null;
                        return (
                            <DropdownItem
                                checkItemPrivilege={checkItemPrivilege}
                                key={i.id}
                                url={generateUrlForItem && generateUrlForItem({ id }, i)}
                                {...i}
                                index={index}
                                generateUrlForItem={generateUrlForItem && ((subItem, subItemChild) => generateUrlForItem({ id }, subItem, subItemChild))}
                                subDropdownIndex={subDropdownIndex}
                                setSubDropdownIndex={setSubDropdownIndex}
                                hideDropdown={() => setDropdownVisible(false)}
                                onClick={(subTabChildId) => {
                                    if (i.onClick) {
                                        i.onClick();
                                        return;
                                    }
                                    if (i.newTab) {
                                        (i.destination || i.getDestination) && context.functions.updateView(i.destination || i.getDestination(i), true);
                                        setDropdownVisible(false);
                                        return;
                                    }
                                    if (i.items && i.items[0].newTab && !subTabChildId) return;
                                    setSelectedTab(id);
                                    setSelectedSubTab && setSelectedSubTab(id, i.id, subTabChildId);
                                    setSelectedSubTabChild && subTabChildId && setSelectedSubTabChild(id, i.id, subTabChildId);
                                    i.action && i.action(id, i.id);
                                    setDropdownVisible(false);
                                }}
                                active={selectedSubItem == i.id}
                                selectedSubTabChild={selectedSubTabChild}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );

    return showOverflowTooltip ?
        <Tooltip title={label || name} placement={filteredItems?.length > 0 ? 'top' : 'bottom'}>
            {item}
        </Tooltip>
        : item;
}

const isItemHidden = (item, checkItemPrivilege) => {
    return (checkItemPrivilege && !checkItemPrivilege(item.id)) || (typeof item.hidden == 'function' ? item.hidden(item.id) : item.hidden);
};

const DropdownTabs = (props) => {
    const {
        tabs = [],
        selected: selectedTab,
        selectedSubItem,
        selectedSubTabChild,
        onSubTabChildClick,
        onTabClick,
        onTabClose,
        onSubTabClick,
        containerPadding = 0,
        checkItemPrivilege,
        generateUrlForItem,
        tabInfoButtonProps,
        rightComponent,
        height = 40,
        subTabStyling,
        parentTabStyling,
        noInnerPadding,
        notifications,
        disableDropdown,
        actionItems,
        extraContentWidth
    } = props;

    // Probably could be done in multiple nicer ways, just a starting point
    const calculateMaxTabAmount = () => {
        let tabAmount = 16;
        const width = window.innerWidth - (extraContentWidth || 0);
        if (width < 2200) {
            tabAmount = 14;
        }
        if (width < 2100) {
            tabAmount = 13;
        }
        if (width < 2000) {
            tabAmount = 12;
        }
        if (width < 1900) {
            tabAmount = 11;
        }
        if (width < 1800) {
            tabAmount = 10;
        }
        if (width < 1700) {
            tabAmount = 9;
        }
        if (width < 1600) {
            tabAmount = 8;
        }
        if (width < 1500) {
            tabAmount = 7;
        }   
        if (width < 1400) {
            tabAmount = 6;
        }        
        if (width < 1300) {
            tabAmount = 5;
        }
        if (width < 1200) {
            tabAmount = 4;
        }
        if (width < 1100) {
            tabAmount = 3;
        }
        if (width < 1000) {
            tabAmount = 2;
        }
        return tabAmount;
    }

    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [visibleTabAmount, setVisibleTabAmount] = useState(calculateMaxTabAmount());
    const context = useContext(SettingsContext);
    // const [selectedTab, setSelectedTab] = useState(selected);
    const _onTabClick = (tab, firstSubItemId, newTab, subTabChildId) => {
        if (selectedTab == tab.id && (!subTabChildId || subTabChildId == selectedSubItem)) return;

        if (firstSubItemId && onSubTabClick) {
            onSubTabClick(tab.id, subTabChildId ? subTabChildId : firstSubItemId, newTab);
        } else {
            onTabClick(tab, newTab);
        }
    };


    const handleResize = () => {
        const tabAmount = calculateMaxTabAmount()
        setVisibleTabAmount(tabAmount);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const mainTabs = tabs.slice(0, visibleTabAmount);
    const moreTabs = tabs.slice(visibleTabAmount, tabs.length);

    return (
        <div
            id="dropdown-tabs"
            className={`${noInnerPadding ? 'no-inner-padding' : ''} ${parentTabStyling ? 'dropdown-parenttabs' : ''} ${subTabStyling ? 'dropdown-subtabs' : ''}`}
            style={{ marginRight: -containerPadding, marginLeft: -containerPadding, height }}
        >
            <div className='dropdown-tabs-container'>
                {mainTabs.map((tab, i) => {
                    if (isItemHidden(tab, checkItemPrivilege)) return null;

                    if (tab.type === 'divider') {
                        return <div key={i} className='dropdown-tabs-divider' />
                    }

                    const tabElement = (<Tab
                        key={tab.id}
                        url={generateUrlForItem && generateUrlForItem(tab)}
                        {...tab}
                        onClick={(e) => {
                            let firstClickableSubItem;
                            for (let i = 0; i < (tab.items || []).length; i++) {
                                const item = tab.items[i];
                                if (item.items) {
                                    for (let j = 0; j < (item.items || []).length; j++) {
                                        const sub = item.items[j];
                                        if (!sub.newTab && !sub.onClick) {
                                            firstClickableSubItem = item.id;
                                            break;
                                        }
                                    }
                                    if (firstClickableSubItem) break;
                                } else {
                                    if (!item.newTab && !item.onClick) {
                                        firstClickableSubItem = item.id;
                                        break;
                                    }
                                }
                            }
                            if ((tab.items || []).length > 0 && !firstClickableSubItem) return;
                            tab.action ? tab.action(e) : _onTabClick(tab, firstClickableSubItem, e.ctrlKey || e.metaKey);
                        }}
                        active={selectedTab == tab.id || (tab.items || []).find((st) => st.id == selectedTab)}
                        selectedSubItem={selectedSubItem}
                        selectedSubTabChild={selectedSubTabChild}
                        tabIndex={i}
                        dropdownIndex={dropdownIndex}
                        generateUrlForItem={generateUrlForItem}
                        checkItemPrivilege={checkItemPrivilege}
                        setSelectedTab={(tabId) => { }}
                        setSelectedSubTab={onSubTabClick}
                        setSelectedSubTabChild={onSubTabChildClick}
                        setDropdownIndex={setDropdownIndex}
                        notification={notifications ? notifications[tab.id] : false}
                        disableDropdown={disableDropdown}
                        onClose={() => {
                            console.log({ onTabClose })
                            onTabClose?.(tab);
                        }}
                        tooltip={tab.tooltip}
                    />)

                    if (tab.tooltip) {
                        return <Tooltip title={tab.tooltip}>
                            <div>
                                {tabElement}
                            </div>
                        </Tooltip>
                    }

                    return tabElement;
                })}
                {moreTabs.length > 0 && <Tab
                    key={'more'}
                    label={context.functions.getTranslation ? context.functions.getTranslation('More') : "More"}
                    name="more"
                    id='more'
                    items={moreTabs}
                    active={(moreTabs || []).find((st) => st.id == selectedTab)}
                    selectedSubItem={selectedTab}
                    selectedSubTabChild={selectedSubItem}
                    tabIndex={mainTabs.length}
                    dropdownIndex={dropdownIndex}
                    generateUrlForItem={(item, subItem = undefined, subItemChild = undefined) => {
                        const target = subItem?.target || {};

                        return {
                            ...context.functions.getViewProps(),
                            selectedTab: subItem.id,
                            selectedSubTab: subItemChild?.id,
                            selectedSubTabChild: undefined,
                            ...target
                        }
                    }}
                    checkItemPrivilege={checkItemPrivilege}
                    setSelectedTab={(tabId) => { }}
                    setSelectedSubTab={(moreTab, tabKey, subTabChildId) => {
                        const tab = moreTabs?.find(m => m.id == tabKey);
                        const subtab = tab?.items?.length > 0 ? tab.items[0] : {};

                        _onTabClick(tab, subtab?.id, false, subTabChildId)
                    } }
                    // setSelectedSubTabChild={(a, b, c) => _onTabClick(b,c,"child")}
                    setDropdownIndex={setDropdownIndex}
                    disableDropdown={disableDropdown}
                />}

                {actionItems?.length > 0 && tabs?.length > 0 && <div className='dropdown-tabs-divider' />}
                {actionItems?.map(actionItem => {
                    return (
                        <div className="tab" {...(actionItem?.outerProps ?? {})}>
                            <div className="main-content">
                                {actionItem?.content}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="dropdown-tabs-right">
                {tabInfoButtonProps &&
                    (Array.isArray(tabInfoButtonProps) ? tabInfoButtonProps.map((infoButtonProps, i) => <TabInfoButton key={i} {...infoButtonProps} />) : <TabInfoButton {...tabInfoButtonProps} />)}
                {rightComponent && rightComponent}
            </div>
        </div>
    );
};

export default DropdownTabs;
