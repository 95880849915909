import React from 'react';
import { SettingsContext } from './../SettingsContext';
import './BillTargeting.css';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import ContextMenu from '../general/ContextMenu';
import { Button, MenuItem, IconButton } from '@mui/material';
import List from "../list/List";
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import TextInputCell from "../list/cells/TextInputCell";
import AutoCompleteCell from "../list/cells/AutoCompleteCell";
import CurrencyListCell from "../list/CurrencyListCell";
import PercentageListCell from "../list/PercentageListCell";
import DataHandler from "../general/DataHandler";
import _ from 'lodash';
import Utils from "../general/Utils";
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import WatchIcon from '@mui/icons-material/RemoveRedEye';
import withStyles from '@mui/styles/withStyles';
import TreeCell from '../list/cells/TreeCell';
import TaimerComponent from '../TaimerComponent';
import ProjectTreeDropdown from "../projects/ProjectTreeDropdown";
import TreeDropdownCell from '../list/cells/TreeDropdownCell';
import { formatInputNumber } from '../helpers';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';

const styles = theme => ({
    barButton: {
        padding: 1,
    },
    treeCellInput: {
        paddingRight: 30,
        paddingLeft: 5 + 8 + 2,
        paddingTop: 7,
        paddingBottom: 6,
        borderRadius: 2,
        textAlign: 'left',
    },
})
class BillTargetingRow extends PropsOnlyListRow {
    static contextType = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            onChange: () => { },
            editMode: false
        },
        forceFirstQuoteRow: true
    };
    
        
    constructor(props, context) {
        super(props, {}, {}, "bills/BillTargeting");
     
        this.refProject = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {
        super.componentDidMount();
        
        if(this.props.data.projects_id > 0) {
            this.getJobtypes(this.props.data.projects_id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const data = {...this.props.data};
        const billTotal = this.props.sharedData?.bill?.total;

        if(this.props.rowProps.company != prevProps.rowProps.company) {
            data.projects_id = 0;
            this.setData({data});
        }

        if (this.props.data.projects_id > 0 && this.props.data.projects_id != prevProps.data.projects_id) {
            this.getJobtypes(this.props.data.projects_id);
        }

        if (billTotal != prevProps.sharedData?.bill?.total) {
            let sum = data.sum;
    
            if (data.lastEdited == "split") {
                sum = data.share * billTotal || 0;
            }

            setTimeout(() => {
                this.setData({ sum });
            }, 1000);
        }
    }

    // TODO: abstract
    defineClassName() {
        return "billTargetingRow";
    }
 
    validateProject = () => {
           if((this.props.data.projects_id == null || this.props.data.customers_id == null) || this.props.data.costestimate_row_id == null ){
            // this.setState({invalidProject:true});  
            this.setData({_invalidProject:true});
            return false;
            }
           else{
            // this.setState({invalidProject:false});          
               this.setData({_invalidProject:false});

           }
     
        return true;
    }

    forceFirstQuoteRow = (project) => {

        if (!project)
            return;

        const firstRow = this.props.sharedData.quote_rows.find(el => el.projects_id == project.id);

        this.setData({
            projects_id: project.id,
            customers_id: project.customer,
            costestimate_row_id: firstRow ? firstRow.id : 0            
        });
    }

    getJobtypes = async (projectId) => {
        DataHandler.get({ url: 'bills/jobtypes/' + projectId }).done(data => {
            this.setState({
                jobtypes: data,
            });
        });
    }

    defineCells() {
        const commonProps = {
            textAlign: "right",
            // onEdited: this.editRow,
            // runOnEditedOnInput: true
        };

        const editModeProps = {
            listCellProps: {
                editable: this.props.rowProps.editMode || this.props.data.id < 0,
                inEditMode: this.props.rowProps.editMode || this.props.data.id < 0
            }
        };

        const { functions: { updateView } } = this.context;
        const { data } = this.props;
        const { sharedData: { bill, classes, error } } = this.props;

        const customersProjects = this.props.sharedData.projects.filter(x => data.customers_id && x.customers_id == data.customers_id);
        const projectQuotes = this.props.sharedData.quotes.filter(x => data.projects_id && x.projects_id == data.projects_id);
        const projectQuoteRows = this.props.sharedData.quote_rows.filter(x => data.projects_id && x.projects_id == data.projects_id);
        const projectQuote = this.props.sharedData.quote_rows.find(x => x.id == data['costestimate_row_id']);
        const project = this.props.sharedData.projects.find(x => data.projects_id && x.id == data['projects_id']);
        const customer = this.props.sharedData.customers.find(x => data.customers_id && x.id == data['customers_id']);

        const jobtype = data.jobtypes_id > 0 && this.state.jobtypes ? this.state.jobtypes.find(t => t.id == data.jobtypes_id) : {};
    
        let shareOfBill = data.shareOfBill;
        if (data.lastEdited != "split" && Math.abs(bill.total) > 0) {
            shareOfBill = data.sum / bill.total * 100 || 0;
        }

        const cells = {
            context:
          
                !this.props.rowProps.editMode ? <ListCell width={this.props.columnWidthMap.context} editable={false} /> : <ContextMenu className="cell row-menu" onClick={() => { this.setState({error : false}); }} label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                    <MenuItem className="delete" onClick={() => { this.delete() }}><RemoveIcon className="Delete"/> {this.tr("Delete")}</MenuItem>
                </ContextMenu>,
                  
            project: 
                <TreeDropdownCell
                    listCellProps={{...editModeProps.listCellProps, title: customer && project ? `${customer.name} / ${project.name}` : undefined}}
                    value={data['projects_id']}
                    displayValue={customer && project ? `${customer.name} / ${project.name}` : undefined}
                    error= {this.props.data._invalidProject}
                    dropdownProps={{
                        customer,
                        project,
                        error:this.state.error,
                        queryParameters: { company: this.props.rowProps.company, includeLocked: true, context: "bill_targeting" }
                    }}
                    onEdited={(project) => {
                        if (data['id'] == project.id)
                            return;

                        if (this.props.forceFirstQuoteRow)
                            this.forceFirstQuoteRow(project)
                        else
                            this.setData({
                                projects_id: project.id,
                                customers_id: project.customer
                            });

                    }}                    
                    dropdownType={ProjectTreeDropdown}
                /> 
                ,
            target:
                <TreeCell
                    key={this.props.data.costestimate_row_id}
                    {...editModeProps}
                    options={projectQuotes}
                    value={data['costestimate_row_id']}
                    error = {this.props.data._invalidProject}
                    treeSelectProps={{
                        error:error,
                        InputProps: {
                            classes: {
                                input: classes.treeCellInput
                            }
                        },
                        // margin: "none",
                    }}
                    onEdited={value => {
                        this.setData({
                            costestimate_row_id: value,
                        });
                    }} />,
            target_action:
                <ListCell
                    className="quoteLink"
                    onlyDisplay
                    editable={false}>
                    {projectQuote && <IconButton
                        onClick={() => {
                            // tab = quotes
                            updateView({ module: 'projects', action: 'view', id: data['projects_id'], tab: 'quotes', quote: projectQuote.quote_id }, true);
                        }}
                        classes={{ root: classes.barButton }}
                        size="large"><WatchIcon fontSize="small" /></IconButton>}
                </ListCell>,
            description:
                <TextInputCell
                    {...editModeProps}
                    value={data.description}
                    onEdited={value => {
                        this.setData("description", value);
                    }} />,
            split:
                <TextInputCell
                    {...editModeProps}
                    value={formatInputNumber(shareOfBill || 0)}
                    listCellType={PercentageListCell}
                    onEdited={value => {
                        if (formatInputNumber(shareOfBill) == formatInputNumber(value))
                            return;

                        const rounded = Utils.truncateDecimals(shareOfBill);

                        if (Number(value.replace(",", ".")) === rounded)
                            return; 

                        const portion = Number(value.replace(",", ".")) / 100;

                        this.setData({
                            share: portion,
                            sum: portion * bill.total,
                            shareOfBill: value,
                            lastEdited: "split"
                        });

                    }} />,
            amount:
                <TextInputCell
                    {...editModeProps}
                    listCellType={CurrencyListCell}
                    listCellProps={{currency: this.props.rowProps.currency}}
                    value={formatInputNumber(data.sum)}
                    validation={["numeric"]}
                    onEdited={value => {
                        if (formatInputNumber(data.sum) == formatInputNumber(value))
                            return;

                        this.setData({
                            sum: value || 0,
                            lastEdited: "amount"
                        });
                    }} />,
            jobtypes_id:
                <AutoCompleteCell
                    //style={{ width: this.props.columnWidthMap['jobtypes_id'] + 'px' }}
                    //width={this.props.columnWidthMap['jobtypes_id']}
                    name="jobtypes_id"
                    value={jobtype}
                    //editable={this.props.rowProps.editable ? false : true}
                    autoCompleteData={this.state.jobtypes}
                    searchable={false}
                    //menuPortalTarget={this.props.rowProps.listContainer}
                    /*onEdited={value => {
                        this.cellEdited("jobtypes_id", value.id)
                    }}*/
                    onEdited={value => {
                        this.setData("jobtypes_id", value.id);
                    }}
                    {...editModeProps}
                    /*listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}*/ />,
        };

        return cells;
    }
}

class BillTargeting extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "bills/BillTargeting");

        this.refList = React.createRef();

        this.state = {
            autoCompleteData: false,
            open: false,
            error: false
        }

        const { taimerAccount } = context;
        this.currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getAutoCompleteData();
    }

    getAutoCompleteData = async () => {
        const { company } = this.props;
        const projectsAndQuotes  = await DataHandler.get({ url: `bills/autoCompleteData/${company}`, projects: 1 });
        const data  = await DataHandler.get({ url: `bills/autoCompleteData/${company}` });
        
        this.setState({ autoCompleteData: {...data, ...projectsAndQuotes}});
    }

    validate = () => {
        const { total, enqueueSnackbar } = this.props;
        const rows = this.refList.current.getVisibleRows();
        const rowsData = rows.map(ref => ref.getData());
        const emp_rows =[];
        const len = rows.length-1;
        const totalSum = _.sumBy(rowsData, function(row) { return Number(row.sum); });

        for (let i=0; i<= len; i++){
            
            if(!rows[i].validateProject()){
                rows[i].error = true;
                emp_rows[i] = rows[i];

            }
        }
        if(emp_rows.length > 0){
            this.setState({ error: true });

            enqueueSnackbar(`Targeted customer, project and quote should be selected.`, {
                variant: "error",
            });
            return false;
        } else{
            this.setState({error:false});          
        }
         
        //In TAIM-3901- saves even if the target sum is more then the sum total bill.
      /*   if (totalSum > total) {
            enqueueSnackbar(`Targeted sum (${this.currencyFormatter(totalSum)}) is more than sum of bill (${this.currencyFormatter(total)}).`, {
                variant: "error",
            });
            return false;
        } */
        return true;
    }

    getRows = () => {
        return this.refList.current.getData();
        // return this.refList.current.getVisibleRows().map(row => row.getData());
    }

    handleToggle = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    render() {
        const { rows, billRows, editMode, total, classes, enqueueSnackbar, billValidation } = this.props;
        const { autoCompleteData, open, error} = this.state;
        const { tr } = this;
        const columnConfig = {
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
        };

        if (!autoCompleteData)
            return null;

        const bill = {
            total,
        };

        return (
            <div className="BillTargetingArea">
                <div className="BillTargetingHeader">
                    <Button
                        className="button"
                        size="small"
                        variant="text"
                        data-testid="bill-project-targeting-toggle"
                        onClick={this.handleToggle}>
                        {this.tr('Target Bill to Project Quote') + " (" + rows.length + ")"}
                        {!open ? <ExpandMore /> : <ExpandLess />}
                    </Button>
                </div>
                <div style={!open ? { display: 'none' } : undefined}>
                    <List
                        fluid={true}
                        noStateData={true}
                        id="billTargetingList"
                        className="billTargetingList"
                        ref={this.refList}
                        reverseNewData 
                        renderNewDataAtEnd
                        hideHeaderButtons={!editMode}
                        height="auto"
                        data={rows}
                        rowHeight={48} // row height + ONE margin
                        listRowType={BillTargetingRow}
                        sharedData={{ ...autoCompleteData, bill, classes, enqueueSnackbar, error}}
                        ignoreRowPropsChange={false}
                        rowProps={{
                            editMode,
                            onDelete: (data) => {
                                this.refList.current.removeRow(data.id);
                                if (data.id > 0)
                                    this.props.onDelete(data.id);
                            },
                            company: this.props.company,
                            currency: this.props.currency
                        }}
                        
                        newRow={{
                            share: 0,
                            sum: 0,
                        }}
                        columns={[
                            { name: "context", header: "", width: 30, ...columnConfig, columnHeaderType: "roundButton" },
                            { name: "project", header: this.tr("Customer / Project"), width: 200, ...columnConfig },
                            // { name: "customer", header: this.tr("Customer"), width: 100, ...columnConfig },
                            // { name: "project", header: this.tr("Project"), width: 100, ...columnConfig },
                            { name: "target", header: this.tr("Target to Quote Topic Row"), width: 200, ...columnConfig },
                            { name: "target_action", header: "", width: 10, ...columnConfig },
                            { name: "description", header: this.tr("Description"), width: 150, ...columnConfig },
                            { name: "split", header: this.tr("Split %"), width: 70, ...columnConfig },
                            { name: "amount", header: this.tr("Amount"), width: 90, ...columnConfig },
                            ...(this.context.addons.nav ? [{ name: "jobtypes_id", header: this.tr("Jobtype"), width: 200, ...columnConfig }] : []),
                        ]} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BillTargeting);
