import colors from '../colors';
import getColumnType, { ColumnDefinition, isGroupedParent, sumAndGroup } from './ColumnTypes';
import { CustomFields, createCustomFields } from './CustomFields';

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    useAutoHeight?: boolean;
}

export default function getProjectColumns(params: Params) {
    const { customFields } = params;
    
    const columns: ColumnDefinition[] = [
        {
            field: 'project',
            headerName: 'Project',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=projects&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'project_id',
            headerName: 'Project number',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=projects&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup',
            cellClass: 'export_columnText',
        },
        {
            field: 'project_parent',
            headerName: 'Project Parent',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=projects&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'seller',
            headerName: 'Seller',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'project_manager',
            headerName: 'Project manager',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'project_category',
            headerName: 'Category',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_parent_category',
            headerName: 'Parent category',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_pipeline',
            headerName: 'Funnel',
            ...getColumnType('multi', params.tr),
            valueGetter: params => {
                if (isGroupedParent(params?.node)) {
                    return sumAndGroup(params);
                }

                if(!params || !params.data) {
                    return '';
                }
                const status = params.data['project_status'];
                const pipeline = params.data[params.colDef.field];

                if (status == 1) {
                    return '[translate]Won Deals';
                } else if (status == 5) {
                    return '[translate]Internal';
                } else {
                    return pipeline;
                }
            },
            cellRendererParams: {
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'project_stage',
            headerName: 'Stage',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_reporting_group',
            headerName: 'Reporting Group',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_tags',
            headerName: 'Tags',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_type',
            headerName: 'Project type',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_locked',
            headerName: 'Project Status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: -1, name: "Open", color: colors.greenish_cyan},
                    {id: 1, name: "Locked", color: colors.faded_red},
                    {id: 2, name: "Hold", color: colors.orangey_yellow},
                    {id: 3, name: "Locked", color: colors.faded_red},
                ],
                tr: params.tr
            },
            autoHeight: params.useAutoHeight
        },
        ...createCustomFields(customFields.projects, 'project_custom'),
    ]
    return columns;
}
