import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import getProjectColumns from './ProjectColumns';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";
import colors from "../colors";

// const columnOrder = [
//     'project',
//     'material_date_month',
//     'invoice_invoiced',
// ];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
}

export default function getRevenueRecognitionColumns(params: Params) {

    const columns: ColumnDefinition[] = [    
        {
            field: 'recognized',
            headerName: 'Recognized',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: "sales_agent",
            headerName: "Sales agent (recognition split)",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.sales_agent_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'month_month',
            headerName: 'Month',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'month_quarter',
            headerName: 'Quarter',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'month_year',
            headerName: 'Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'company',
            headerName: 'Project company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
    ];

    // columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "revenue_recognition", 
        params.settingsContext
    );
}
