import TaimerComponent from '../TaimerComponent';
import IconCell from './IconCell';

import { UserColumnDefinition } from './ColumnTypes';

interface Props {
    value: string;
    colDef: UserColumnDefinition;
    justifyContent?: "left" | "right" | "icon_text";
    data: any;
    getIconComponent?: (data: any) => any;
}

export default class IconCellRenderer extends TaimerComponent<Props> {
    render() {
        const { value, data, colDef } = this.props;

        const iconComponent = this.props.getIconComponent ? this.props.getIconComponent(data) : null;

        return <IconCell
                value={value}
                iconComponent={iconComponent}
                justifyContent={colDef?.justifyContent || "icon_text"}
            />
    }
}