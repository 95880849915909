import TaimerComponent from '../TaimerComponent';

interface Props {
    value?: string;
}

export default class ValueRenderer extends TaimerComponent<Props> {
    render() {
        return this.props.value;
    }
}