import TaimerComponent from '../TaimerComponent';
import StyledTooltip from '../general/StyledTooltip';

import styles from './CellRenderer.module.scss';

export interface Tooltip {
    content: any;
    placement: string;
}

interface Props {
    value: string;
    tooltip?: Tooltip;
    icon?: any;
    iconComponent?: any;
    justifyContent?: "left" | "right" | "icon_text";
}

const renderIcon = (Icon, iconComponent, tooltip) => {
    if (tooltip) {
        return (<StyledTooltip {...tooltip}>
            <div>
                {iconComponent && <div className={styles.iconComponentContainer}>{iconComponent}</div>}
                {Icon && <Icon className={styles.icon} />}
            </div>
        </StyledTooltip>);
    }

    return <>
        {iconComponent && <div className={styles.iconComponentContainer}>{iconComponent}</div>}
        {Icon && <Icon className={styles.icon} />}
    </>
}

export default class IconCell extends TaimerComponent<Props> {
    render() {

        const { value, icon, iconComponent, justifyContent, tooltip } = this.props;
        const contentPlacement = justifyContent == "right" || justifyContent == "icon_text" ? "right" : "left";
 
        return (
            <div className={styles.iconCell}>
                <div className={`${styles.container} ${justifyContent == "right" ? styles.contentEnd : ''} ${icon ? styles.hasIcon : ''} ${justifyContent == "icon_text" ? styles.iconText : ''}`}> 
                    {contentPlacement == "left" && <span className="value">{value}</span>}
                    {(icon || iconComponent) && renderIcon(icon, iconComponent, tooltip)}
                    {contentPlacement == "right" && <span className="value">{value}</span>}
                </div>
            </div>
        )
    }
}