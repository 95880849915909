import React, { ReactNode, useContext } from 'react';
import { SettingsContext } from '../SettingsContext';
import PageTopSection, { Subheader } from '../general/PageTopSection';
import UpgradeVersionPlaceholder from '../general/UpgradeVersionPlaceholder';
import DropdownTabs from './DropdownTabs';
import styles from './WithTabs.module.scss';


const isItemHidden = (item, checkItemPrivilege) => {
    return (checkItemPrivilege && !checkItemPrivilege(item.id)) || (typeof item.hidden == 'function' ? item.hidden(item.id) : item.hidden);
};

export interface Tab {
    type?: 'text'|'divider';
    /** Unique id for the tab */
    id: string;
    /** String shown as the tab header */
    label: string;
    /** Show tab if items is empty */
    showWithEmptyItems?: boolean;
    /** Possible dropdown items for the tab */
    items?: Tab[];
    /** Disables the selecting the tab, is still visible */
    disabled?: boolean;
    /** If true, hides this tab completely */
    hidden?: (tabId: string) => boolean | boolean;
    /** Should this tab open a new tab – should be used together with the destination/getDestination prop */
    newTab?: boolean;
    /** A url object for the view that should be opened on a new tab */
    destination?: object;
    /** Return a url object for the view that should be opened on a new tab */
    getDestination?: () => object;
    /** Overrides the tab click event */
    onClick?: () => void;
    upgrade?: boolean;
    hideDropdownWithOneChild?: boolean;
    /** Never show dropdown */
    disableDropdown?: boolean;
    /** Allow closing */
    closeable?: boolean;
}

interface WithTabsProps {
    /** Selected tab's id */
    selectedTab: string;
    /** Possible selected sub tab's id */
    selectedSubTab?: string;
    /** Possible selected sub tab child's id */
    selectedSubTabChild?: string;
    /** Array of tabs to show */
    tabs: Tab[];
    /** Return the view that should be rendered for the selected tab */
    children: ReactNode | ((selectedTab: string) => ReactNode);
    loading?: boolean;
    /** A possible header to show on top of the tab row */
    header?: string;
    /** Possible sub headers to show after the header */
    subheaders?: Subheader[];
    /** Possible element to show on the right side of the tab row */
    rightComponent?: ReactNode;
    subRightComponent?: ReactNode;
    /** Possible element to show below the tab row, on top of the content */
    aboveComponent?: ReactNode;
    /** If true, only shows the rendered content, no tab row */
    hideTabs?: boolean;
    /** Props for the header component (PageTopSection). See PageTopSection.tsx for more info. */
    topSectionProps?: object;
    /** Key that is set in URL for the selected tab. Defaults to 'selectedTab' */
    selectedTabKey?: string;
    /** Key that is set in URL for the selected sub tab. Defaults to 'selectedSubTab' */
    selectedSubTabKey?: string;
    /** Keeps selected tab, if it doesn't exist  */
    keepSelectedTab?: boolean;
    /** Horizontal padding for the tab row */
    containerPadding?: number;
    /** Height of the tab row */
    height?: number;
    /** How much the tab row should be offset from the top of the view (in px) */
    offsetTop?: number;
    /** Additional key-value pairs to add in the url when selecting a tab */
    additionalUpdateViewValues?: object;
    /** If true, the URL isn't changed with tab selection */
    noUpdateView?: boolean;
    style?: object;
    /** Determines if a tab is hidden or not */
    checkItemPrivilege?: (tabId: string) => boolean;
    /** Props for the tab explainer button(s), see TabInfoButton.jsx for more info */
    getTabInfoButtonProps?: (tabId: string, isSubTab?: boolean) => object | undefined;
    /** Called when the tab is clicked. Doesn't cancel the normal behaviour */
    onTabClick?: (tab: Tab) => void;
    /**
     * 
     */
    onTabClose?: (tab: Tab) => void;

    disableFirstLevelDropdown?: boolean;

    noInnerPadding?: boolean;
    tabsAlwaysVisible?: boolean;
    notifications?: any;
    subActionItems?: {
        content: React.ReactNode;
        outerProps: any;
    }[];
    alwaysShowSubTabBar?: boolean;
    extraSubTabContentWidth?: number;
}

const WithTabs = (props: WithTabsProps) => {
    const context: any = useContext(SettingsContext);
    const {
        functions: { updateView, getViewProps },
    } = context;
    let { selectedTab, selectedSubTab, selectedSubTabChild, disableFirstLevelDropdown } = props;
    const {
        loading,
        header,
        subheaders,
        aboveComponent,
        hideTabs,
        topSectionProps,
        selectedTabKey = 'selectedTab',
        selectedSubTabKey = 'selectedSubTab',
        containerPadding,
        rightComponent,
        subRightComponent,
        height,
        offsetTop,
        additionalUpdateViewValues,
        noUpdateView,
        tabs: originalTabs = [],
        children,
        checkItemPrivilege,
        getTabInfoButtonProps,
        onTabClick,
        onTabClose,
        noInnerPadding,
        tabsAlwaysVisible,
        notifications,
        keepSelectedTab,
        subActionItems,
        alwaysShowSubTabBar,
        extraSubTabContentWidth
    } = props;
    // Maybe useEffect if performance issues?
    const tabs = originalTabs.filter((t) => !isItemHidden(t, checkItemPrivilege));
    if (!keepSelectedTab && (tabs.length && (selectedTab == undefined || !tabs.find((t) => t.id == selectedTab || (t.items || []).find((st) => st.id == selectedTab))))) {
        selectedTab = tabs[0].id;
    }
    const selectedTabItem = tabs.find((t) => t.id == selectedTab || (t.items || []).find((st) => st.id == selectedTab));
    if (!keepSelectedTab && selectedTabItem && selectedTabItem.items?.length) {
        const filteredItems = selectedTabItem.items.filter((i) => !i.newTab && !isItemHidden(i, checkItemPrivilege));
        if (selectedSubTab == undefined || !filteredItems.find((i) => i.id == selectedSubTab)) {
            selectedSubTab = filteredItems[0]?.id;
        }
    }
    const selectedSubTabItem = selectedTabItem?.items?.find((t) => t.id == selectedSubTab);

    if (!keepSelectedTab && selectedSubTabItem && selectedSubTabItem.items?.length) {
        const filteredItems = selectedSubTabItem.items.filter((i) => !i.newTab && !isItemHidden(i, checkItemPrivilege));
        if (selectedSubTabChild == undefined || !filteredItems.find((i) => i.id == selectedSubTabChild)) {
            selectedSubTabChild = selectedSubTabItem.items[0]?.id;
        }
    }

    const selectedSubTabChildItem = selectedSubTabItem?.items?.find((t) => !t.newTab && t.id == selectedSubTabChild);

    const childSubheaders: any = [];
    if (selectedSubTabItem) childSubheaders.push({ header: selectedSubTabItem.label });
    if (selectedSubTabChildItem) childSubheaders.push({ header: selectedSubTabChildItem.label });

    const realTabSelection = selectedSubTabChild || selectedSubTab || selectedTab;
    const realTabSelectionItem = selectedSubTabChildItem || selectedSubTabItem || selectedTabItem;

    const needsUpgrade = realTabSelectionItem?.upgrade == true;

    const childComponent = needsUpgrade ? <UpgradeVersionPlaceholder /> : typeof children == 'function' ? children(realTabSelection) : children;
    const showTabs = !hideTabs && tabs && (tabs.length > 1 || tabsAlwaysVisible);
    const showSubTabs = alwaysShowSubTabBar 
        || (!hideTabs && (selectedTabItem?.items || []).length > (selectedTabItem?.hideDropdownWithOneChild ? 1 : 0));

    return (
        <div className={`${styles.withTabs} ${header ? styles.withHeader : ''}`} style={{ marginTop: showTabs ? offsetTop || 0 : 0, ...props.style }}>
            {header && (
                <PageTopSection
                    loading={loading}
                    className={`${styles.titleSection} ${hideTabs || !tabs || tabs.length == 0 ? styles.noTabs : ''}`}
                    header={header}
                    subheaders={subheaders}
                    {...topSectionProps}
                />
            )}
            {showTabs && (
                <DropdownTabs
                    height={height}
                    noInnerPadding={noInnerPadding}
                    parentTabStyling={showSubTabs}
                    tabInfoButtonProps={getTabInfoButtonProps && getTabInfoButtonProps(selectedTab)}
                    rightComponent={rightComponent || (!showSubTabs && subRightComponent)}
                    containerPadding={containerPadding}
                    onTabClick={(tab, newTab) => {
                        onTabClick && onTabClick(tab);
                        !noUpdateView &&
                            updateView(
                                tab.updateView || {
                                    [selectedTabKey]: tab.id,
                                    [selectedSubTabKey]: selectedSubTabKey == selectedTabKey ? tab.id : undefined,
                                    selectedSubTabChild: undefined,
                                    nonUrlParams: { updateViewOriginPoint: "tabbar_dropdown" },
                                    ...additionalUpdateViewValues,
                                },
                                newTab,
                                true,
                                false,
                                true
                            );
                    }}
                    onTabClose={onTabClose}
                    onSubTabClick={(tab, subTab, target = null) => {
                        const t = typeof target === "object" ? target : {};
                        !noUpdateView &&
                            updateView(
                                {
                                    [selectedTabKey]: selectedSubTabKey == selectedTabKey ? subTab : tab,
                                    [selectedSubTabKey]: subTab,
                                    selectedSubTabChild: undefined,
                                    nonUrlParams: { updateViewOriginPoint: "tabbar_dropdown" },
                                    ...t
                                },
                                false,
                                true,
                                false,
                                true
                            );
                    }}
                    onSubTabChildClick={(tab, subTab, subTabChild) => {
                        !noUpdateView &&
                            updateView(
                                {
                                    [selectedTabKey]: tab == 'more' ? subTab : tab,
                                    [selectedSubTabKey]: tab == 'more' ? subTabChild : subTab,
                                    selectedSubTabChild: tab == 'more' ? undefined : subTabChild,
                                },
                                false,
                                true,
                                false,
                                true
                            );
                    }}
                    tabs={tabs}
                    generateUrlForItem={(item, subItem: any = undefined, subItemChild: any = undefined) => ({
                        ...getViewProps(),
                        [selectedTabKey]: item.id,
                        selectedSubTab: subItem?.id,
                        selectedSubTabChild: subItemChild?.id,
                    })}
                    checkItemPrivilege={checkItemPrivilege}
                    selected={selectedTab}
                    selectedSubItem={selectedSubTab}
                    selectedSubTabChild={selectedSubTabChild}
                    notifications={notifications}
                    disableDropdown={disableFirstLevelDropdown}
                />
            )}
            {showSubTabs && (
                <DropdownTabs
                    subTabStyling
                    height={40}
                    tabInfoButtonProps={getTabInfoButtonProps && selectedSubTab && getTabInfoButtonProps(selectedSubTab, true)}
                    containerPadding={containerPadding}
                    onTabClick={(tab) => {
                        onTabClick && onTabClick(tab);
                        !noUpdateView &&
                            updateView(
                                {
                                    [selectedSubTabKey]: tab.id,
                                    selectedSubTabChild: undefined,
                                    nonUrlParams: { updateViewOriginPoint: "tabbar_tab" },
                                },
                                false,
                                true,
                                false,
                                true
                            );
                    }}
                    onTabClose={onTabClose}
                    onSubTabClick={(tab, subTab) => {
                        !noUpdateView &&
                            updateView(
                                {
                                    [selectedSubTabKey]: tab,
                                    selectedSubTabChild: subTab,
                                    nonUrlParams: { updateViewOriginPoint: "tabbar_tab" },
                                },
                                false,
                                true,
                                false,
                                true
                            );
                    }}
                    tabs={selectedTabItem?.items}
                    actionItems={subActionItems}
                    generateUrlForItem={(item, subItem: any = undefined) => ({
                        ...getViewProps(),
                        [selectedTabKey]: selectedTab,
                        selectedSubTab: item.id,
                        selectedSubTabChild: subItem?.id,
                    })}
                    checkItemPrivilege={checkItemPrivilege}
                    selected={selectedSubTab}
                    selectedSubItem={selectedSubTabChild}
                    rightComponent={subRightComponent}
                    extraContentWidth={extraSubTabContentWidth}
                />
            )}
            {aboveComponent}
            {childComponent && (!Array.isArray(childComponent) && childSubheaders.length > 0 ? React.cloneElement(childComponent, { mainTabLabel: selectedTabItem?.label, subTabLabels: childSubheaders }) : childComponent)}
        </div>
    );
};

export default WithTabs;
