import TaimerComponent from '../TaimerComponent';
import { Button, DrawerProps, Tooltip } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import fieldEditSliderStyles from '../general/FieldEditSlider.module.scss';
import DataList from '../general/DataList';
import Slider from '../general/Slider';
import LoaderButton from '../general/LoaderButton';
import OutlinedField from '../general/OutlinedField';
import TextFieldWithLimit from '../general/TextFieldWithLimit';
import DataHandler from '../general/DataHandler';
import { roundToDecimals } from '../general/MathUtils';
import styles from './InvoiceMultipleAccountsSlider.module.scss';
import { cloneDeep, isEqual } from 'lodash';

export interface Address {
    id: number;
    address_id: number;
    customers_id: number;
    percentage: number;
    customer_name?: string;
    address_label?: string;
}

export interface AccountOption {
    id: number;
    label: string;
    name: string;
    value: string;
}

export interface AddressOption {
    id: number;
    label: string;
    address: string;
    postalcode: string;
    city: string;
    customers_id: number;
    name: string;
}

interface Props extends DrawerProps {
    onClose: (event?: any, reason?: string) => void;
    onSave?: (data?: Address[]) => void;
    addresses: Address[];
    accountOptions: AccountOption[];
    addressOptions: AddressOption[];
    projectsId: number;
    enqueueSnackbar: Function;
    fieldLimits: any;
}

interface State {
    addresses: Address[];
    addressOptions: AddressOption[];
    saving: boolean;
    invalidFields: object;
}

const AccountSection = (props) => {
    const { address, addresses, addresNumber, accountOptions, tr, addressOptions, editField, removeAddress, isNumeric, invalidFields, fieldLimits } = props;
    const { taimerAccount } = props.context;

    const customerAddresses = addressOptions.filter(a => a.customers_id == address.customers_id );

    const percentFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    }).format;

    return (
        <div className={styles.section}>
            <div className={styles.header}>
                <span className={styles.title}>{tr("Address") + " " + addresNumber}</span>
                {addresses?.length > 1 && <div onClick={() => removeAddress(address.id)} className={styles.remove}><Delete /></div>}
            </div>
            <DataList
                name="customers_id"
                label={tr("Account")}
                value={accountOptions.find(a => a.id == address.customers_id)}
                options={accountOptions}
                onChange={(value) => editField(address.id, "customers_id", value.id)}
                shownCount={20}
                menuWidth={100}
                error={invalidFields[address.id + "_customers_id"]}
            />
            <DataList
                name="id"
                label={tr("Address")}
                value={customerAddresses.find(a => a.id == address.address_id)}
                options={customerAddresses.filter(c => c.deleted != 1)}
                onChange={(value) => editField(address.id, "address_id", value.id)}
                shownCount={20}
                menuWidth={100}
                error={invalidFields[address.id + "_id"]}
            />
            <OutlinedField
                name="percentage"
                key={address.id}
                label={tr("Share of invoicing")}
                value={isNumeric(address.percentage) 
                    ? percentFormatter(address.percentage)?.replace(",", ".")
                    : address.percentage
                }
                onChange={(e) => editField(address.id, "percentage", e.target.value)}
                validation={['empty']}
                shrinkLabel
                className={styles.percentageField}
                formatDisplayValue={(value) => isNumeric(value) ? percentFormatter(value) + " %" : value}
                error={invalidFields[address.id + "_percentage"]}
            />
            <TextFieldWithLimit
                key={"customer_reference"}
                limitEditorType={OutlinedField}
                limit={fieldLimits.customerreference}
                onChange={(e) => editField(address.id, "customer_reference", e.target.value)}
                label={tr("Your reference")}
                name={"customer_reference"}
                value={address.customer_reference}
                shrinkLabel={true}
                error={invalidFields[address.id + "customer_reference"]}
                useAbsoluteLimitPositioning
            />
        </div>
    );
};

export default class InvoiceMultipleAccountsSlider extends TaimerComponent<Props, State> {
    maxAddressesAmount: number;

    constructor(props, context) {
        super(props, context, 'projects/InvoiceMultipleAccountsSlider');

        this.state = {
            addresses: cloneDeep(props.addresses || []),
            addressOptions: this.getAddressOptions(),
            saving: false,
            invalidFields: {}
        };

        this.maxAddressesAmount = 30;
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isEqual(prevProps.addressOptions, this.props.addressOptions)) {
            this.setState({ addressOptions: this.getAddressOptions() });
        }
    }

    getAddressOptions = () => {
        const { addressOptions } = this.props;

        const resp: AddressOption[] = (cloneDeep(addressOptions || [])).map(a => {
            const parts: string[] = [];
            if (a.name) {
                parts.push(a.name);
            }
            if (a.address) {
                parts.push(a.address);
            }
            if (a.postalcode) {
                parts.push(a.postalcode);
            }
            if (a.city) {
                parts.push(a.city);
            }
            a.label = parts.join(", ");
            return a;
        })

        return resp;
    }

    addAddress = () => {
        const { enqueueSnackbar } = this.props;
        const { addresses } = this.state;

        if (addresses.length == this.maxAddressesAmount) {
            enqueueSnackbar && enqueueSnackbar(this.tr("Max ${amount} addresses can be added.", {amount: this.maxAddressesAmount}), {
                variant: 'error'
            });
            return;
        }

        const lastId = addresses.reduce((a,b)=> a.id > b.id ? a : b)?.id || 1;

        addresses.push({
            id: Number(lastId) + 1,
            address_id: 0,
            customers_id: 0,
            percentage: 0
        });        

        this.setState({ addresses }); 
    }

    removeAddress = (id) => {
        const addresses = cloneDeep(this.state.addresses);
        const invalidFields = cloneDeep(this.state.invalidFields);

        Object.keys(this.state.invalidFields).forEach(key => {
            const keyId = key.split("_")[0];
            if (keyId == id) {
                invalidFields[key] = false;
            }
        });

        const index = addresses.findIndex(a => a.id == id);
        addresses.splice(index, 1);

        this.setState({ invalidFields, addresses }); 
    }

    isNumeric = (value) => {
        return Number.isFinite(Number(value));
    }

    save = () => {
        const { onSave, onClose, enqueueSnackbar, projectsId, accountOptions } = this.props;
        const { addresses, addressOptions } = this.state;

        this.setState({ saving: true });

        let totalPercentage = 0;
        let error = false;
        const addedAddresses: number[] = [];

        addresses.every((a) =>  {
            if (!this.isNumeric(a.percentage)) {
                error = this.tr('Invalid percentages.');
                return false;
            }
            if (Number(a.percentage) <= 0 || Number(a.percentage) > 100) {
                error = this.tr('Percentage needs to be number between 0 and 100.');
                return false;
            }
            if (Number(a.address_id) <= 0) {
                error = this.tr('Address cannot be empty.');
                return false;
            }
            if (addedAddresses.find(ad => a.address_id == ad)) {
                error = this.tr('Same address is added multiple times.');
                return false;
            }

            totalPercentage += Number(a.percentage);
            addedAddresses.push(a.address_id);
            return true;
        });

        if (!error && totalPercentage == 0) {
            error = this.tr('Total share cannot be 0.');
        }

        if (!error && roundToDecimals(totalPercentage, 2) > 100) {
            error = this.tr('Total share cannot be over 100%.');
        }

        if (error) {
            enqueueSnackbar && enqueueSnackbar(error, {
                variant: 'error'
            });
            this.setState({ saving: false });
            return;
        }

        DataHandler.post({ url: `projects/${projectsId}/invoicing_addresses` }, { addresses }).done(() => {
            onClose && onClose();

            const resp: Address[] = addresses.map(a => {
                a.customer_name = accountOptions.find(o => o.id == a.customers_id)?.name;
                a.address_label = addressOptions.find(o => o.id == a.address_id)?.label;
                return a;   
            });

            onSave && onSave(resp);
            this.setState({ saving: false });
        })
        .fail(err => {
            this.setState({ saving: false });
            onClose && onClose()
            enqueueSnackbar && enqueueSnackbar(this.tr('Error in saving addresses!'), {
                variant: 'error'
            });
        })
    }

    editField = (id, name, value) => {
        const { addressOptions } = this.state;
        const invalidFields = cloneDeep(this.state.invalidFields);
        const addresses = cloneDeep(this.state.addresses);

        const index = addresses.findIndex(a => a.id == id);
        addresses[index][name] = value;
        invalidFields[id + "_" + name] = false;

        if (name == "percentage" && !this.isNumeric(value)) {
            invalidFields[id + "_" + name] = true;
        }
        else if (name == "percentage") {
            value = value?.replace(".", ",");
        }

        // Select first address when account is changed.
        if (name == "customers_id") {
            const customerAddresses = addressOptions.filter(a => a.customers_id == value);
            addresses[index]["address_id"] = customerAddresses[0]?.id || 0;
        }

        this.setState({ addresses, invalidFields }); 
    }

    renderAddButton = () => {
        return (
            <div>
                <AddCircleOutline />
                <span>{this.tr("Add another account")}</span>
            </div>
        )
    }

    render() {
        const { onClose, accountOptions, fieldLimits } = this.props;
        const { addresses, addressOptions, saving, invalidFields } = this.state;

        const addressAddAllowed = addresses?.length < this.maxAddressesAmount;

        return (
            <Slider open={true} onClose={onClose} title={this.tr('Invoice multiple accounts')}>
                <div className={styles.sliderContent}>
                    <div className={styles.content}>
                        <div className={styles.sections}>
                            {addresses.map((address, index) => {
                                return <AccountSection
                                    tr={this.tr}
                                    address={address}
                                    addresses={addresses}
                                    accountOptions={accountOptions}
                                    addressOptions={addressOptions}
                                    editField={this.editField}
                                    removeAddress={this.removeAddress}
                                    context={this.context}
                                    isNumeric={this.isNumeric}
                                    invalidFields={invalidFields}
                                    addresNumber={index + 1}
                                    fieldLimits={fieldLimits}
                                />
                            })}
                        </div>
                        <div className={`${styles.iconTextButton} ${!addressAddAllowed ? styles.disabled : ""}`} onClick={() => addressAddAllowed && this.addAddress()}>
                            {!addressAddAllowed
                                ?
                                <Tooltip title={this.tr("Max ${amount} addresses can be added.", {amount: this.maxAddressesAmount})} placement="top" classes={{ tooltip: 'darkblue-tooltip' }} arrow={true}>
                                    {this.renderAddButton()}
                                </Tooltip>
                                : this.renderAddButton()
                            }
                        </div>
                    </div>

                    <div className={fieldEditSliderStyles.actions}>
                        <Button onClick={onClose} variant="text" size="large">
                            {this.tr('Cancel')}
                        </Button>
                        <div className={fieldEditSliderStyles.right}>
                            <LoaderButton
                                text={this.tr('Save')}
                                disabled={false}
                                loading={saving}
                                onClick={() => this.save()}
                                size="large"
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
            </Slider>
        );
    }
}

export {
    type Props
}
