import $ from 'jquery';
import _, { cloneDeep, debounce, isEqual, throttle } from 'lodash';
import React, { Suspense } from 'react';

import TaimerComponent from './TaimerComponent';

import { SnackbarProvider, useSnackbar } from 'notistack';

/* local components */
import TaimerNavi from './navigation/TaimerNavi';

import ContactView from './contacts/ContactView';
import ExpenseView from './expenses/ExpenseView';
import AttachmentInput from "./general/AttachmentInput";
import ProjectList from './list/lists/ProjectList';
import MailLogin from './mail/MailLogin';
import Notifications from './notifications/Notifications';
import OnedriveLogin from './onedrive/OnedriveLogin';
import ProjectKanban from './projects/ProjectKanban';
import TeamChat from './team-chat/TeamChat';

import PurchaseOrderView from './bills/PurchaseOrderView';
import ReceivedInvoiceView from './bills/ReceivedInvoiceView';
import ActivitiesInsight from './dashboard/insights/activities/ActivitiesInsight';
import InsightsView from './dashboard/insights/InsightsView';
import ErrorBoundary from './error/ErrorBoundary';
import ExternalQuote from './external/ExternalQuote';
import BuyTaimer from './general/BuyTaimer';
import PersonalNote from './general/PersonalNote';
import QuotePrint from './general/QuotePrint';
import OrderView from './onboarding/OrderView';
import UserView from './users/UserView';


import notificationIcon from './notification-icon.png';

/* context */
import { SettingsContext } from './SettingsContext';

import moment from "moment";
import 'moment/min/locales.min';

// Date-fns loacles
import { enGB, enUS, es, it, lt, nn, pt, sv } from 'date-fns/locale';
//import da from 'date-fns/locale/da';
import fi from 'date-fns/locale/fi';

import { Auth } from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';

import { addMonths, addYears, endOfMonth, format, getMonth, parse, startOfYear } from 'date-fns';

/* mui theme */
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { TaimerTheme } from './TaimerTheme';

import Cacher from "./general/Cacher";
import Utils from "./general/Utils";
import DataHandler from './general/DataHandler';
import './index.css';

// Dialog
import WarningIcon from "@mui/icons-material/Warning";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* Stripe */
import { Elements, StripeProvider } from 'react-stripe-elements';

/* Mixpanel */
import mixpanel from 'mixpanel-browser';

import { Close, ThumbUp } from '@mui/icons-material';
import AccountView from './accounts/AccountView';
import ProfitLoss from './charts/ProfitLoss';
import CollaborateView from './collaborate/CollaborateView';
import TestView from './customview/TestView';
import GoalsInsight from './dashboard/insights/goals/GoalsInsight';
import HoursInsight from './dashboard/insights/hours/HoursInsight';
import InvoicingInsight from './dashboard/insights/invoicing/InvoicingInsight';
import SalesInsight from './dashboard/insights/sales/SalesInsight';
import CoreDialog from './dialogs/mass_operations/CoreDialog';
import ExpenseWizard from './expenses/ExpenseWizard';
import AddAccountSlider from './general/AddAccountSlider';
import AddActivitySlider from './general/AddActivitySlider';
import AddContactSlider from './general/AddContactSlider';
import AddHoursSlider from './general/AddHoursSlider';
import AddProjectSlider from './general/AddProjectSlider';
import AddResourceSlider from './general/AddResourceSlider';
import AddUserSlider from './general/AddUserSlider';
import BuyLicensesSlider from './general/BuyLicensesSlider';
import CustomizeAppearanceView from './general/CustomizeAppearanceView';
import taimerContent from './general/exampleContent';
import LockUsersView from './general/LockUsersView';
import OnboardingSlider from './general/OnboardingSlider';
import PageNotFound from './general/PageNotFound';
import { AnimatedSlider } from './general/Slider';
import UpgradeVersionSlider from './general/UpgradeVersionSlider';
import VersionContentManager from './general/VersionContentManager';
import EmptyInvoiceConfirmationDialog from "./invoices/dialogs/EmptyInvoiceConfirmationDialog";
import InvoiceView from './invoices/InvoiceView';
import Login from './login/Login';
import Contacts from './navigation/pages/Contacts';
import Costs from './navigation/pages/Costs';
import Dashboard from './navigation/pages/Dashboard';
import Invoices from './navigation/pages/Invoices';
import Products from './navigation/pages/Products';
import ResourcePlanning from './navigation/pages/ResourcePlanning';
import TimeManagement from './navigation/pages/TimeManagement';
import NewReportsView from './new_reports/NewReportsView';
import EntryOrderView from './onboarding/EntryOrderView';
import OnboardingView from './onboarding/OnboardingView';
import ProjectView from './projects/ProjectView';
import ReportsView from './reports/ReportsView';
import Settings from './settings/Settings';
import TaimerCalendarLogin from './taimercalendar/TaimerCalendarLogin';

/* Images */
import { ReactComponent as WeValueYourPrivacyGraphic } from './images/we_value_your_privacy.svg';
import { ReactComponent as WeWantToBeTransparentGraphic } from './images/we_want_to_be_transparent.svg';

// dynamic env variables mixed with build time env variables
import { env } from './general/env';

const STRIPE_PUBLIC_KEY = env.REACT_APP_STRIPE_PUBLIC;

let globalContext = {};

const CloseSnackbarButton = (props) => {
    const { closeSnackbar } = useSnackbar();
    const { key } = props;
    return <Button className="close-snackbar-button" onClick={() => closeSnackbar(key)}><Close /></Button>;
}

export function exportContext() {
    return globalContext;
}
class Taimer extends TaimerComponent {
    static contextType = SettingsContext;
    settings = {
        showMenuSwitcher: false,
        build: "",
        userObject: {
            language: 'en-GB',
            calendarLocale: 'en-US',
            dateFormat: "YYYY-MM-DD",
            timeFormat: "LT",
            projects: 0,
            accounts: 0,
            sidebarStyle: 0,
            accountlistDefaultUnitFilter: "-100",
            disable_user_balance: false,
            completedOnboardingItems: [],
            onboardingStatus: 1,
            theme: 'heeros'
        },
        taimerAccount: {
            numberFormat: 'fi-FI',
            currency: 'EUR',
            symbolPosition: 'end', //'start'|'end'
            symbol: '€',
            distanceUnit: 'km',
            financialYearStart: 1,
            ourCompanyLang: 'en', //selected company language
            companyPrintLang: 'en',
            companyDateFormat: "DD.MM.YYYY",
            origin: 'onboarding',
            defaultVat: 24,
            projectInvoiceSettings: [],
            defaulttermsofpayment: 0,
            defaultannotation: 0,
            defaultpenaltyinterest: 0,
            attachmentMaxSize: 1,
        },
        calendar: {
            clock: 0, // 0 = 12hr, 1 = 24hr clock
            startOfWeek: 0, // 0 sunday, 1 monday
            locale: enUS, // locale for date-fns
            localeName: 'en-US'
        },
        timeTracker: {
            workhour_accuracy: 15,
            bulkentry_interval: 15,
            slots: 4,
            startTime: [4, 0, 0],
            endTime: [22, 0, 0],
            resourcingEnabled: true,
        },
        privileges: {},
        fakePrivileges: false,
        taimerVersion: "free",
        versionId: "1",
        addons: {},
        allAddons: {},
        functions: {
        },
        content: { sidebar: [] }
    };

    state = {
        buyTaimerOpen: false,
    }
    
    lastCompany = false;
    isDirty = false;
    dirtyHandler = false;
    unsavedDialogData = {};
    previousView = undefined;

    constructor(props, context) {
        super(props, context,  "Taimer");

        
        this.cacher = new Cacher();
        this.onUnloadListeners = [];

        this.updateView = this.updateView.bind(this);
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.parseLocation = this.parseLocation.bind(this);
        ["whoami", "urlify", "setTitle", "setTokenExpiration", "sendAnalytics"].forEach(e => this[e] = this[e].bind(this));

        this.settings.functions.setOverrideHeaderTitles = this.setOverrideHeaderTitles;
        this.settings.functions.updateView = this.updateView;
        this.settings.functions.goToPreviousView = this.goToPreviousView;
        this.settings.functions.setOverlayComponent = this.setOverlayComponent;
        this.settings.functions.setAdditionalHeaders = this.setAdditionalHeaders;
        this.settings.functions.showUpgradeSlider = this.showUpgradeSlider;
        this.settings.functions.showOnboardingSlider = this.showOnboardingSlider;
        this.settings.functions.openActivitySlider = this.openActivitySlider;
        this.settings.functions.addAccount = this.addAccount;
        this.settings.functions.openCustomizeSlider = this.openCustomizeSlider;
        this.settings.functions.onOnboardingItemCompleted = this.onOnboardingItemCompleted;
        this.settings.functions.isOnboardingItemCompleted = this.isOnboardingItemCompleted;
        this.settings.functions.isUserOwner = this.isUserOwner;
        this.settings.functions.setOnboardingStatus = this.setOnboardingStatus;
        this.settings.functions.setTheme = this.setTheme;
        this.settings.functions.setContextItem = this.setContextItem;
        this.settings.functions.addProject = this.addProject;
        this.settings.functions.addContact = this.addContact;
        this.settings.functions.addUser = this.addUser;
        this.settings.functions.addInvoice = this.addInvoice;
        this.settings.functions.addHours = this.addHours;
        this.settings.functions.addResource = this.addResource;
        this.settings.functions.addExpense = this.addExpense;
        this.settings.functions.addTravelExpense = this.addTravelExpense;
        this.settings.functions.showDialogContent = this.showDialogContent;
        this.settings.functions.showDialog = this.showDialog;
        this.settings.functions.showConfirmationDialog = this.showConfirmationDialog;
        this.settings.functions.closeDialog = this.closeDialog;
        this.settings.functions.showSliderContent = this.showSliderContent;
        this.settings.functions.showSlider = this.showSlider;
        this.settings.functions.closeSlider = this.closeSlider;
        this.settings.functions.checkPrivilege = this.checkPrivilege;
        this.settings.functions.checkPrivilegeAny = this.checkPrivilegeAny;
        this.settings.functions.hasPrivilege = this.hasPrivilege;
        this.settings.functions.getCompany = this.getCompany;
        this.settings.functions.getPreferedCompany = this.getPreferedCompany;
        this.settings.functions.getViewProps = this.getViewProps;
        this.settings.functions.setDirty = this.setDirty;
        this.settings.functions.openWorkhourDialog = this.openWorkhourDialog;
        this.settings.functions.whoami = this.whoami;
        this.settings.functions.getFinancialYear = this.getFinancialYear;
        this.settings.functions.toggleBuyDialog = this.toggleBuyDialog;
        this.settings.functions.presentCurrency = this.presentCurrency;
        this.settings.functions.showNotification = this.showNotification;
        this.settings.functions.openTeamChat = this.openTeamChat;
        this.settings.functions.startWorkhourTimer = this.startWorkhourTimer;
        this.settings.functions.stopWorkhourTimer = this.stopWorkhourTimer;
        this.settings.functions.getWorkhourTimers = this.getWorkhourTimers;
        this.settings.functions.getWorkhourTimer = this.getWorkhourTimer;
        this.settings.functions.urlify = this.urlify;
        this.settings.functions.setTitle = this.setTitle;
        this.settings.functions.getStorage = this.getStorage;
        this.settings.functions.setTokenExpiration = this.setTokenExpiration;
        this.settings.functions.setSidebarStyle = this.setSidebarStyle;
        this.settings.functions.setLastCompany = this.setLastCompany;
        this.settings.functions.getTimeTrackerSettings = this.getTimeTrackerSettings;
        this.settings.functions.refreshTimeTrackerSettings = this.refreshTimeTrackerSettings;
        this.settings.functions.getDownloadPath = this.getDownloadPath;
        this.settings.functions.sendAnalytics = this.sendAnalytics;
        this.settings.functions.sendMixpanelEvent = this.sendMixpanelEvent;
        this.settings.functions.setMixpanelAlias = this.setMixpanelAlias;
        this.settings.functions.initMixpanel = this.initMixpanel;
        this.settings.functions.resetMixpanel = this.resetMixpanel;
        this.settings.functions.sendMixpanelPeople = this.sendMixpanelPeople;
        this.settings.functions.getFnsLocales = this.getFnsLocales;
        this.settings.functions.companyUsesAddOn = this.companyUsesAddOn;
        this.settings.functions.unregisterOnUnloadListeners = this.unregisterOnUnloadListeners;
        this.settings.functions.registerOnUnloadListener = this.registerOnUnloadListener;
        this.settings.functions.emptyCacheOnUnload = this.emptyCacheOnUnload;
        this.settings.functions.fetchNavigationNotificationData = this.fetchNavigationNotificationData
        this.settings.functions.getEnv = this.getEnv

        this.settings.functions.openPreviewList = this.openPreviewList;
        this.settings.functions.closePreviewList = this.closePreviewList;
        this.settings.functions.cognitoSignOut = this.cognitoSignOut;
        this.settings.functions.promptUpload = this.promptUpload;
        this.settings.functions.promptMultipleUpload = this.promptMultipleUpload;
        this.settings.functions.promptFileSelection = this.promptFileSelection;
        this.settings.functions.promptMultipleFileSelection = this.promptMultipleFileSelection;
        this.settings.functions.isProjectReadCompanyUsingQuoteCurrencies = this.isProjectReadCompanyUsingQuoteCurrencies;
        this.settings.functions.getTranslation = this.getTranslation;

        this.settings.cacher = this.cacher;

        const parsedLocation = this.parseLocation();

        const view = {
            module: "login",
            action: "login",
            tabletMode: 'Off',
            ...parsedLocation,
            selectedPage: parsedLocation && parsedLocation.module && parsedLocation.action ? `${parsedLocation['module']}-${parsedLocation['action']}` : "login-login",
        };

        // Restore preview list state from localstorage
        let activePreviewList = false;

        if (localStorage["activePreviewList"]) {
            const ac = JSON.parse(localStorage["activePreviewList"]);

            if (typeof ac === 'object' && ac.pages && ac.pages.length) {
                activePreviewList = ac;
            }
        }

        if (activePreviewList) {
            activePreviewList.currentIndex = this.getPreviewListPosition(activePreviewList, view);
        }
        
        this.state = {
            view,
            useAppcues: true,
            initial: true,
            settingsLoaded: false,
            unsavedWarningOpen: false,
            gdprDialogOpen: false,
            tosPrivacypolicyDialogOpen: false,
            acceptTos: false,
            acceptPrivacypolicy: false,
            gdprReadmore: false,
            workhourTimers: [],
            additionalHeaders: [],
            activePreviewList,
            navigationNotifications: {},
            nonUrlParams: {}
        };

        this.setTheme(this.settings.userObject.theme);

        this.refTaimerNavi = React.createRef();
        this.refTeamChat = React.createRef();
        this.refCurrentPage = React.createRef();
        this.refNotifications = React.createRef();
        this.refPersonalNote = React.createRef();
        this.refErrorBoundary = React.createRef();
        this.currentViewRef = React.createRef();
        this.attachmentUploadInput = React.createRef();
        this.attachmentFileSelectorInput = React.createRef();

        this.planNames = {
            1: "Free (legacy)",
            2: "CRM",
            3: "PRM",
            4: "Enterprise",
            10: "Free",
            11: "Growth",
            12: "Business",
        };
 
        const isOdin = this.settings.isOdin = window.location.href.indexOf(':8080') > -1 || window.location.href.indexOf('branches.dev.psa') > -1;
        const tokenData = this.parseTokenData();
        const currentFolder = window.location.href.split("/")[3];

        if (this.state.view.module === 'logout') {
            window.location.replace("/");
            return this.forceLogOut();
        }
     
        if (tokenData && this.state.view.selectedPage !== 'onboarding-view') {
            if (this.state.view.selectedPage === 'login-login' && !currentFolder && Number(tokenData.exp) > moment().unix()) {
                //user doesn't have a correct url and session is still valid

                this.state.view.module = 'dashboard';
                this.state.view.action = 'main';
                this.state.view.selectedTab = 'my-day';
                this.state.view.selectedPage = 'dashboard-main';

                window.history.pushState({}, this.getPageTitleVersion(), `${isOdin ? '' : `/${tokenData.folder}/`}index.html?module=dashboard&action=main&selectedTab=my-day`);
            }
            else if (!isOdin && currentFolder !== tokenData.folder && !this._isPrintService()) {
                //user has changed the folder manually in the address bar
                localStorage.logout = "folder has changed, logout \n";
                this.forceLogOut();
            }
        }
        this.setupAwsAmplify();
    }
    /* 
    * getEnv gets environment variable
    */
    getEnv = (name) => {
        return env[name]
    }
    setupAwsAmplify = () => {
        Amplify.configure({
            Auth: {
                userPoolWebClientId: this.getEnv('REACT_APP_CLIENT_ID'),
                userPoolId: this.getEnv('REACT_APP_USERPOOL_ID')
            }
        })
    }

    /**
     * Looks for pages matching view in previewlist, returning index for longest match
     * @param {*} previewList 
     * @param {*} view 
     * @returns 
     */
    getPreviewListPosition = (previewList, view) => {
        if (!previewList)
            return -1;

        let bestMatch = null;
        let bestMatchCount = 0;

        for (const page of previewList.pages) {
            let matches = true;
            let matchCount = 0;

            for (const key in page.url) {
                if (Object.hasOwnProperty.call(page.url, key)) {
                    const el = page.url[key];
                    const cv = view[key] ?? null;

                    matches = matches && (String(el) === String(cv));
                    
                    if (matches) {
                        matchCount++;
                    }
                }
            }

            if (matches && matchCount > bestMatchCount) {
                bestMatchCount = matchCount;
                bestMatch = page;
            } 
        }

        return bestMatch ? previewList.pages.findIndex(x => x === bestMatch) : -1; 
    }

    /**
     * Open preview list and navigate to first page
     * 
     * previewList
     *   returnUrl: optional return url
     *   pages: array of pages (see PreviewList.tsx for definition)
     */
    openPreviewList = (previewList) => {
        if (!previewList?.pages)
            return;

        this.updateView(previewList.pages[0].url, {previewList});
    }

    setTheme = (theme) => {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme--${theme}`);
        this.settings.userObject.theme = theme;
        this.forceUpdate();
    }

    getTranslation = (text) => {
        return this.tr(text);
    }

    setOnboardingStatus = (onboardingStatus) => {
        this.settings.userObject.onboardingStatus = onboardingStatus;
        window.dispatchEvent(new Event(`onboardingStatusChanged_${onboardingStatus}`));
        DataHandler.put({ url: `onboarding_status` }, { onboarding_status: onboardingStatus });
        this.forceUpdate();
    }

    onOnboardingItemCompleted = (id, fromSlider = false) => {
        const completedOnboardingItems = [...this.settings.userObject.completedOnboardingItems];
        if (completedOnboardingItems.indexOf(id) == -1) {
            completedOnboardingItems.push(id);
        }
        this.settings.userObject.completedOnboardingItems = completedOnboardingItems;
        /*this.sendMixpanelEvent('Onboarding wizard completed', {
            'From': fromSlider ? 'Slider' : 'Dashboard',
            'Wizard type': id
        });*/
        window.dispatchEvent(new Event(`onboardingCompleted_${id}`));
        if (completedOnboardingItems.length == VersionContentManager.getOnboardingItems().length) {
            window.dispatchEvent(new Event(`onboardingCompleted`));
            this.sendMixpanelEvent('all_wizards_completed');
            this.sendMixpanelPeople('set', {
                "all_wizards_completed_end_date": new Date().toISOString(),
            });
            this.setOnboardingStatus(2);
        }
        DataHandler.put({ url: `completed_onboarding_items` }, { completed_onboarding_items: completedOnboardingItems.join(',') });
    }

    isOnboardingItemCompleted = (id) => {
        if (id == 'setup') {
            return this.settings.taimerAccount.onboardingSeen == 1 || this.settings.userObject.completedOnboardingItems.indexOf(id) != -1;
        }
        return this.settings.userObject.completedOnboardingItems.indexOf(id) != -1;
    }

    isUserOwner = () => {
        return this.settings.userObject.usersId == 1;
    }

    isProjectReadCompanyUsingQuoteCurrencies = (company = null) => {
        const { addons, userObject: { project_read_companies } } = this.settings;
        let use_quote_currencies = 0;
        if (company === null) {
            use_quote_currencies = (project_read_companies || []).filter(c => c.use_quote_currencies == 1).length > 0;
        }
        else {
            use_quote_currencies = (project_read_companies || []).find(c => c.id == company)?.use_quote_currencies == 1;
        }

        return addons?.invoice_currency && use_quote_currencies;
    }

    openCustomizeSlider = () => {
        this.showSliderContent(this.tr("Customize appearance"), <CustomizeAppearanceView />)
    }

    /**
     * Closes preview list without navigating
     */
    closePreviewList = () => {
        delete localStorage["activePreviewList"];
        this.setState({activePreviewList: false});
    }
    
    getDownloadPath = () => { return window.location.href.indexOf("branches.dev.psa.heeros.com") > -1 ? "react/api/attachment?" : "/react/api/attachment?" }

    parseTokenData = () => {
        const token = this.getStorage().taimerToken;
        
        if (token && token.length > 50) {
            //user has already logged in
            try {
                const jwt = this.parseJwt();

                return jwt;
            }catch (e) {
                console.log(e, "PARSE TOKEN DATA ERROR: ", e);
                return {}
            }
        }
        
        return {};
    }

    forceLogOut = () => {
        if (this.state.view.selectedPage === 'external-quote')
            return;
        sessionStorage.clear();
        localStorage.setItem('taimerToken', "");
        localStorage.setItem('taimerPrivileges', "");
        localStorage.setItem('taimerVersion', "");
        localStorage.setItem('taimerVersionId', "");
        localStorage.setItem('taimerAddons', "");
        localStorage.setItem('onboardingEnded', "");
        localStorage.setItem('lastView', "");
        localStorage.setItem('taimerTokenIssued', "");
        localStorage.setItem('taimerTokenExpires', "");
        localStorage.setItem('taimerTokenRefresh', "");
        localStorage.setItem('lastCompany', "0");
        clearTimeout(this.activityTimer);
        
        // this.resetMixpanel();
        DataHandler.post({url: "auth/logout_saml"})
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
        
        if (this.state.view.selectedPage === 'login-login')
            return;
        
        this.cognitoSignOut();

        const currentFolder = window.location.href.split("/")[3];
        window.location = `${!currentFolder || this.settings.isOdin ? '' : `/${currentFolder}/`}index.html?module=login&action=login`;    
    }

    cognitoSignOut = () => {
        Auth.signOut();
    }

    promptUpload = (uploadParameters) => {
        return this.attachmentUploadInput?.current?.promptSingle(uploadParameters);
    }

    promptMultipleUpload = (uploadParameters) => {
        return this.attachmentUploadInput?.current?.promptMultiple(uploadParameters);
    }

    promptFileSelection = (onFileSelectionConfirm) => {
        return this.attachmentFileSelectorInput?.current?.promptSingle({}, onFileSelectionConfirm);
    }

    promptMultipleFileSelection = (onFileSelectionConfirm) => {
        return this.attachmentFileSelectorInput?.current?.promptMultiple({}, onFileSelectionConfirm);
    }
    
    setTokenExpiration() {
        const tokenData = this.parseTokenData();
        const storage = this.getStorage();
        const time = moment().unix();
        const expireTime = tokenData.exp - tokenData.iat;
        storage.setItem('taimerTokenIssued', time);
        storage.setItem('taimerTokenExpires', time + expireTime);
        storage.setItem('taimerTokenRefresh', time + (expireTime / 2));
        storage.setItem("lastActivityTime", moment().unix());
    }

    refreshToken = async () => {
        const response = await DataHandler.get({url: 'auth/refresh_token'});
 
        this.getStorage().setItem('taimerToken', response.token);
        this.setTokenExpiration();
    }

    //1000ms debounce, so that backend doesn't get bombarded with a billion requests
    handleActivityEvent = debounce((evt) => {
        const storage = this.getStorage();
        storage.setItem("lastActivityTime", moment().unix());
    }, 1000);

    loopActivityTime = () => {
        const time = moment().unix();
        const sessionTimeout = this.settings && this.settings.taimerAccount && this.settings.taimerAccount.sessionTimeOut;
        const { taimerTokenIssued, taimerTokenExpires, taimerTokenRefresh, lastActivityTime } = this.getStorage();
        
        if (this.activityTimer)
            clearTimeout(this.activityTimer);
        
        if (!taimerTokenIssued) {
            localStorage.logout = "no token data, logout \n";
            return this.forceLogOut();
        }   
        if (time > taimerTokenExpires) {
            localStorage.logout = `token expired, current ${time}, exp ${taimerTokenExpires}, logout \n`;
            return this.forceLogOut();
        }
        if (time - lastActivityTime > sessionTimeout) {
            localStorage.logout = `no activity, last ${lastActivityTime}, sessionTimeout ${sessionTimeout} logout \n`;
            return this.forceLogOut();
        }
        if (moment().unix() > taimerTokenRefresh) {
            this.refreshToken();
        }
        
        this.activityTimer = setTimeout(() => {
            this.loopActivityTime();
        }, 5000);
        
    }

    getWorkhourTimers = () => this.state.workhourTimers || [];

    loadWorkhourTimers = async () => {
        const data = await DataHandler.get({url: 'timetracker/timers'});

        const workhourTimers = [];

        data.forEach( timer => {
            const start = moment
              .utc(timer.start, "YYYY-MM-DD HH:mm:ss")
              .local()
              .toDate();
            
            workhourTimers.push({
                ...timer,
                start,
            });

        });

        this.setState({workhourTimers});
    }

    isStartingTimer = false;

    timetrackerSettings = null;

    refreshTimeTrackerSettings = async () => {
        const timetrackerSettings = await DataHandler.get({ url: `timetracker/settings`});

        _.each(timetrackerSettings, (company) => {
            company.allow_add_after = new Date(company.allow_add_after * 1000);
            company.allow_modify_after = new Date(company.allow_modify_after * 1000);
        })

        this.timetrackerSettings = timetrackerSettings;
    }

    getTimeTrackerSettings = (projectCompany = null) => {
        const userCompany = Number(this.settings.userObject.companies_id);

        let actualCompany = userCompany > 0 ? userCompany : (projectCompany || 0);

        if (!this.timetrackerSettings[actualCompany]) {
            console.warn(`Missing timetracker settings for ${actualCompany}, using defaults`);
            actualCompany = 0;
        }

        return this.timetrackerSettings[actualCompany] ?? {
            // Sane defaults to prevent crashes before data is loaded
            id: 0,
            defaultVat: 0,
            dailymaxworkinghours: 7.5,
            balancemode: 0,
            workhour_accuracy: 15,
            bulkentry_interval: 15,
            bulkentry_daystart: '07:00',
            notify_hour_balance_max: 0,
            notify_hour_balance_min: 0,
            prevent_adding_old_workhours: false,
            lock_workhours_slide: 0,
            prevent_multicompany_hourentries: false,
            prevent_main_project_hours: false,
            hour_entry_description: false,
            hour_entry_task: false,
            allow_modify_after: 0,
            allow_add_after: 0,
            enable_approval_submitting: false
        };
    }

    startWorkhourTimer = throttle(async (data) => {
        if (this.isStartingTimer)
            return;

        try {
            const res = await DataHandler.post({url: 'timetracker/timers/start', ...data});

            if (!res || !res.id) {
                return;
            }
    
            const workhourTimers = [...(this.state.workhourTimers||[])];

            workhourTimers.push({
                id: res.id,
                start: new Date(),
                ...data,
            });
    
            this.setState({workhourTimers});
            return res;
        } catch (error) {
            // Probably timer is already running
            this.loadWorkhourTimers();
        }

        this.isStartingTimer = false;
    }, 500);

    getWorkhourTimer = () => {
        const { workhourTimers } = this.state;

        if (workhourTimers.length === 0)
            return false;

        const current = workhourTimers[0];

        return current;
    }

    stopWorkhourTimer = async (timer) => {
        const { workhourTimers } = this.state;

        this.setState({workhourTimers: workhourTimers.filter(x => x.id !== timer?.id)});

        if (!timer?.id) {
            return;
        }
        
        try {
            const res = await DataHandler.post({url: 'timetracker/timers/stop', id: timer.id});

            window.dispatchEvent(new Event('timersChanged'));

            return  true;
        } catch (error) {
            this.loadWorkhourTimers();

            return false;
        }
    }

    openWorkhourDialog = (data) => {
        this.addHours(data);
    }

    setDirty = (value, handler = false, data = {}) => {
        console.log("dirty")
        if (this.isDirty === value && this.dirtyHandler === handler)
            return;

        this.isDirty = value;
        this.dirtyHandler = handler;
        this.unsavedDialogData = data;
    }

    checkPrivilege = (group, perm, company) => {
        if (!this.settings.userObject.usersId)
            return false;

        if (!company) {
            let noInitialCompany;
            company = this.settings.userObject.companies_id;
        }

        company = parseInt(company, 10);

        const g = this.settings.privileges[group];
        const p = g && g[perm];

        const cClause = (company == 0 && typeof noInitialCompany === 'undefined' ? true : (p && p.indexOf(company) > -1));

        return (g && p && cClause) || false;
    }
    hasPrivilege = (group, perm = undefined) => {
        const g = this.settings.privileges[group];
        
        if (g && !perm)
            return true;
        if (!g)
            return false;
        
        let p;
        if (Array.isArray(perm))
            for (const i in perm) {
                p = g[perm[i]];
                if (p && p.length > 0)
                    return true;
            }
        else {
            p = g[perm];
            return p && p.length > 0;
        }
        
        return false;
    }

    getPreferedCompany = (companies) => {
        if (!companies) 
            return false;

        companies = companies.map(x => Number(x)).filter(x => x);

        if (companies.length === 0)
            return false;

        let usedCompany = companies[0];

        if (companies.indexOf(this.lastCompany) > -1)
            usedCompany = this.lastCompany;
        else if (companies.indexOf(this.settings.userObject.companies_id -0) > - 1)
            usedCompany = Number(this.settings.userObject.companies_id);

        return usedCompany;
    }

    getViewProps = () => {
        return this.state.view;
    }

    getCompany = (group, perm, preferCompany, useLastCompany = false) => {
        preferCompany = preferCompany || this.settings.userObject.companies_id;
        
        const g = this.settings.privileges[group];
        const p = g && g[perm];
        
        if (!p || p.length === 0)
            return false;

        let usedCompany = p[0];

        if (useLastCompany !== false && p.indexOf(this.lastCompany) > -1)
            usedCompany = this.lastCompany;
        else if (preferCompany !== false && p.indexOf(preferCompany-0) > -1)
            usedCompany = preferCompany;
        
        return usedCompany.toString();
    }

    checkPrivilegeAny = (group, perms = undefined, company = undefined) => {
        if (!this.settings.userObject.usersId)
            return false;

        if (!perms)
            perms = this.settings.privileges[group] ? Object.keys(this.settings.privileges[group]) : [];

        for (const perm of perms) {
            if (this.checkPrivilege(group, perm, company))
                return true;
        }

        return false;
    }

    parseLocation() {
        const newData = {};
        const location = window.location.href.split('?');

        if (!location[1])
            return;

        location[1].split("&").forEach(e => {
            const split = e.split("=");

            if (split[1] === "false")
                split[1] = false;
            else if (split[1] === "true")
                split[1] = true;
            else if (split[1] === "undefined" || split[1] == "")
                split[1] = undefined;
                
            newData[split[0]] = typeof split[1] === "string" ? split[1].replace("#", "") : split[1];
        });

        return newData;
    }

    onBeforeUnload = (e) => {
        if (this.isDirty) {
            e.preventDefault();
            e.returnValue = '';

            //this.dirtyHandler && this.dirtyHandler(true);
        }
    }

    dirtyLeaveTarget = {};

    onPopState = (e) => {
        const storage = this.getStorage();
        const token = storage.getItem('taimerToken');
        if (token === "" || !token || token === null) {
            e.preventDefault();
            this.updateView({action: 'login', 'module': 'login'}, false, false, false, true /* no whoami */);
        }
        const update = this.parseLocation();

        const isViewChanging = (update.module && update.module !== this.state.module) || (update.action && update.action !== this.state.action);

        if (this.isDirty && isViewChanging) {
            this.dirtyLeaveTarget = update;
            this.dirtyHandler && this.dirtyHandler();

            const url = `index.html?${$.param(this.state.view)}`;
            window.history.pushState({}, this.getPageTitleVersion(), url);

            this.setState({unsavedWarningOpen: true});

            return;
        }
        this.updateView(update, false, true, false, true, "", false);
    }
    
    setTitle(postfix = "", showVersion = true) {
        const prefix = this.getStorage() === sessionStorage ? "(A) " : "";
        document.title = `${prefix} ${postfix.length > 0 ? postfix + " - " : ""}${this.getPageTitleVersion(showVersion)}`;
    }

    convertDateFormat(format) {
        return format.replace('%d', 'DD')
            .replace('%m', 'MM')
            .replace('%Y', 'YYYY')
    }
    
    getStorage () {
        return sessionStorage.taimerToken ? sessionStorage : localStorage;
    }
    parseJwt () {
        const token = this.getStorage().taimerToken;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        
        const data = JSON.parse(jsonPayload);
        data.folder = data.db.substr(2);

        return data;
    }

    sendAnalytics = _.throttle((calledFunction = null, data = null) => {  
            /* CHECK OUT GTM AND GA4 CONFIGURATIONS AND READ GOOGLE'S DOCUMENTATION BEFORE USING! */
            /* When in doubt don't hesitate to ask for help. */
            /* If sending success and fail data consecutively please use 600ms setTimeout for the fail data so the throttle isn't triggered as it's mainly there to prevent tracking user spam. */
            /* EXAMPLE USAGE: 
                callType = "event"
                calledFunction = "invoice_created", <-- This is defined in GTM Triggers which is linked to a corresponding GTM Tag.
                data = {
                    taimer_version: 4,
                    invoice_type: Mass Invoice,
                    invoices_crated: 15
                }
            */
            window.dataLayer && window.dataLayer.push({"event": calledFunction, ...data});

            //Amplitude should just work with the same data as GA4 in a more simplified manner
            //window.amplitude && window.amplitude.getInstance().logEvent(calledFunction, data);
    }, 500);

    resetMixpanel = () => {
        try {
            mixpanel?.reset && mixpanel?.reset();
        } catch (e) {
            console.error(e);
        }
    }

    sendMixpanelEvent = (event, properties) => {
        try {
            mixpanel?.track && mixpanel?.track(event, properties);
        } catch (e) {
            console.error(e);
        }
    }

    sendMixpanelPeople = (operation, properties) => {
        try {
            mixpanel.people[operation](properties);
        } catch (e) {
            console.error(e);
        }
    }
    
    setMixpanelAlias = (id) => {
        try {
            mixpanel?.alias && mixpanel?.alias(id);
        } catch (e) {
            console.error(e);
        }
    }

    incrementMixpanelSuperProperty = (property) => {
        try {
            const value = mixpanel.get_property(property);
            const update = {} 
            if(value && typeof(value) == 'number') {  
                update[property] = value +1;  } 
            else { 
                update[property] = 1 
            } 
            mixpanel.register(update);
        } catch (e) {
            console.error(e);
        }

    }

    setNonessentialCookies = async (value) => {
        this.setState({ gdprDialogOpen: false, acceptNonessentialCookies: value });
        if (this.state.view.module !== 'onboarding') {
            await DataHandler.put({ url: `settings/user/nonessential_cookies` }, { accept_nonessential_cookies: value });
            this.whoami();
        } else {
            try {
                if (value == 1) {
                    mixpanel.opt_in_tracking();
                } else {
                    mixpanel.opt_out_tracking();
                }
            } catch (e) {
                console.error(e);
            }
                
        }
    }

    acceptTosAndPrivacypolicy = async () => {
        this.setState({ tosPrivacypolicyDialogOpen: false });
        const acceptTos = (this.settings.taimerAccount.tos_updated_date > this.settings.userObject.accept_tos || this.settings.userObject.accept_tos == null);
        const acceptPrivacypolicy = (this.settings.taimerAccount.privacypolicy_updated_date > this.settings.userObject.accept_privacypolicy || this.settings.userObject.accept_privacypolicy == null);

        await DataHandler.put({ url: `settings/user/accept_tos_privacypolicy` }, { accept_tos: acceptTos, accept_privacypolicy: acceptPrivacypolicy });
    }
    
    getPageTitleVersion = (showVersion = true) => {
        return `Heeros${(this.state.settingsLoaded && showVersion) ? (this.settings.versionId < 10 ? ' PSA' : ' Small Business') : ' PSA'}`
    }

    componentWillMount() {        
        if (this.state.view.token) {
            //adminpanel login
            if (this.state.view.local_login == '1')
                localStorage.setItem('taimerToken', this.state.view.token);
            else
                sessionStorage.setItem('taimerToken', this.state.view.token);
        
            this.setTokenExpiration();
            window.history.pushState({}, this.getPageTitleVersion(), this.urlify({token: ""}));
            window.location.reload();
        }
       
        this.setTitle();
        const storage = this.getStorage();

        if (storage.taimerPrivileges) {
            try {
                this.settings.privileges = JSON.parse(storage.taimerPrivileges);
                this.settings.taimerVersion = storage.taimerVersion;
                this.settings.versionId = storage.taimerVersionId;
                this.settings.addons = JSON.parse(storage.taimerAddons);
            } catch (e) { console.log("invalid session") }
        }
        else if (process.env.NODE_ENV !== 'production')
            $.get("json/privileges.json").done(e => this.settings.privileges = e);

        if ("nextAction" in sessionStorage) {
            const { state, view } = this.state;
            const nextAction = sessionStorage["nextAction"];

            if (view && view.code) {
                const code = view.code;
                const realmId = view.realmId
                if (nextAction === "mail-office-login")
                {
                    DataHandler.post({url: 'mails/login'}, {
                        code
                        
                    }).then(() =>  {
                        const url = sessionStorage["previousUrl"];

                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else if (nextAction === "settings-accounting") {
                    DataHandler.post({url:
                        `settings/company/${this.settings.userObject.companies_id}/quickbooks/link`
                    }, {
                        code, state, realmId
                    }).done(() => {
                        this.updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default'})
                    }).fail(e => {
                        this.updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', error: e.responseJSON})
                    });

                } else if (sessionStorage["nextAction"] === "onedrive-office-login") {

                    sessionStorage.removeItem("nextAction");
                    DataHandler.post({ url: 'onedrive/login' }, {
                        code
                    }).then(() => {
                        const url = sessionStorage["previousUrl"];
                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else if (nextAction === "calendar-office-login") {

                    sessionStorage.removeItem("nextAction");
                    DataHandler.post({ url: 'calendar/office/login' }, {
                        code
                    }).then(() => {
                        const url = sessionStorage["previousUrl"];
                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else {
                    console.log(nextAction, "invalid")
                }

                sessionStorage.removeItem("nextAction");
            }
        }
        !['login-login', 'onboarding-view', 'external-quote'].includes(this.state.view.selectedPage) && this.whoami(true);

        this.initMixpanel();

        window.addEventListener("beforeunload", this.onBeforeUnload);
        window.addEventListener("popstate", this.onPopState);
        document.addEventListener('keypress', this.handleActivityEvent);
        document.addEventListener('click', this.handleActivityEvent);

    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("beforeunload", this.onBeforeUnload);
        window.removeEventListener("popstate", this.onPopState);
        document.removeEventListener('keypress', this.handleActivityEvent);
        document.removeEventListener('click', this.handleActivityEvent);
    }

    initMixpanel = () => {
        mixpanel.init(this.getEnv('REACT_APP_MIXPANEL_TOKEN'));
    }

    updateTimeFormat(format, is12hr) {
        const is12hrf = format.indexOf('h') > -1;

        if (is12hrf == is12hr)
            return format;

        if (is12hr)
            return format.replace(/H/g, 'h') + " A";
        else
            return format.replace(/h/g, 'H').replace(" A", "");
    }

    getFnsLocales = () => {
        const use12hr = this.settings.calendar.clock === 0;
        const fnsLocales = {
            'en': use12hr ? enUS : enGB,
            'fi': fi,
            'se': sv,
            'lt': lt,
            'nn': nn,
            'es': es,
            'pt': pt,
            'it': it,
        }
        return fnsLocales;
    }
   
    companyUsesAddOn = (addOn, companyId) => {
        if(!addOn || !companyId) {
            return false;
        }

        const { addons } = this.settings;

        return addons[addOn] 
            && addons[addOn]?.used_by_companies?.indexOf(companyId) > -1;
    }

    getMomentLocale(data) {
        this.settings.calendar.clock = Number(data.settings.clock_format);
        this.settings.calendar.startOfWeek = Number(data.settings.week_start);

        const use12hr = this.settings.calendar.clock === 0;
        const lang = this.lang.split('-')[0];

        const fnsLocales = this.getFnsLocales();

        const fnsLocaleNames = {
            'en': use12hr ? 'en-US' : 'en-GB',
            'fi': 'fi',
            'se': 'se',
            'lt': 'lt',
            'nn': 'nn',
            'es': 'es',
            'pt': 'pt',
            'it': 'it',
        }

        this.settings.calendar.locale = fnsLocales[lang] || fnsLocales.en;
        this.settings.calendar.localeName = fnsLocaleNames[lang] || fnsLocaleNames.en;

        const langToMomentLocale = {
            'en': use12hr ? 'en' : 'en-gb',
            'fi': 'fi',
            'se': 'sv',
            'lt': 'lt',
            'nn': 'nn',
            'da': 'da',
            'es': 'es',
            'pt': 'pt',
            'it': 'it',
        };

        const baseLocale = langToMomentLocale[lang] || langToMomentLocale.en;
        const taimerLocale = `taimer-${baseLocale}`;

        const localeData = moment.localeData(baseLocale);
        const longDateFormat = {};

        for (const iterator of ['LT', 'LTS', 'LLL', 'LLLL']) {
            longDateFormat[iterator] = this.updateTimeFormat(localeData.longDateFormat(iterator), use12hr);
        }

        moment.defineLocale(taimerLocale, {
            parentLocale: baseLocale,
            week: {
                dow: this.settings.calendar.startOfWeek,
                doy: this.settings.taimerAccount.countryCode === "US" ? 6 : 4 // https://momentjs.com/docs/#/customization/dow-doy/
            },
            longDateFormat,
        });

        return taimerLocale;
    }

    _isPrintService = () => {
        return this.state.view.module == "print";
    }

    getHourApprovalPrivileges = async () => {
        const response = await DataHandler.get({ url: 'timetracker/workhours/approval/settings' });
        return response.show_approval && response.show_approval === '1';
    }

    
    getTaimerContent = async () => {
        return taimerContent;
    }
    

    async whoami(setPageTitle = false) {
        // used when some additional delay is needed after redirections to Taimer for example
        if (this.state.view?.useDelay == 1) {
            setTimeout(() => {
                this.updateView({ useDelay: undefined, replace: true })
            }, 3000);
            return;
        }

        // Load Users Settings
        let data = {}
        
        const storage = this.getStorage();
        let taimerContent;
        try {
            const responses = await Promise.all([DataHandler.get({ url: 'whoami' }), this.getHourApprovalPrivileges(), this.getTaimerContent()])
            data = responses[0] || {};
            data = {
                ...data,
                showApprovals: responses[1]
            }
            taimerContent = data.frontEndModel;
            // if(!this.cacher.isInitialized()) {
                // this.cacher.initialize([
                   // [{ url: `projects/autoCompleteData/${data.company}` }, 300],
                   // [{ url: `subjects/dimensions/teams/${data.company}` }, 300],
                   // [{ url: `projects/needed_rights` }, 300],
                   // [{ url: `settings/company/${data.company}/defaultPipeline` }, 300],
                   // [{ url: `settings/company/${data.company}/tagSettings` }, 300],
                   // [{ url: `settings/company/${data.company}/project/oneprojecttype` }, 300],
                   // [{ url: `settings/company/${data.company}/default_project_category` }, 300],
                   // [{ url: `subjects/companies/projects/read/`, currency: 1 }, 300],
                // ]);
            // }

        } catch (error) {
            console.log(error)
            if (this.state.view.selectedPage !== "login-login") {
                storage["loginRedirect"] = JSON.stringify(this.state.view);
                this.updateView({action: 'login', 'module': 'login'}, false, false, false, true /* no whoami */);
            }

            return;
        }

        const params = {
            id: data.user,
            attachment: "user_profile",
            auth: storage.taimerToken,
        };
        
        if (this.settings.build.length && this.settings.build !== data.build)
            window.location.reload();
        else
            this.settings.build = data.build;
        
        this.settings.showMenuSwitcher = data.addons.show_sidebar_switcher !== undefined;
        this.settings.userObject.fullname = `${data.settings.lastname} ${data.settings.firstname}`;
        this.settings.userObject.abbreviation = `${data.settings.lastname.slice(0, 1)}${data.settings.firstname.slice(0, 1)}`
        this.settings.userObject.usersId = Number(data.user);
        this.settings.userObject.companies_id = data.company;
        this.settings.userObject.title = data.title;
        this.settings.userObject.avatar = this.getDownloadPath() + $.param(params);
        this.settings.userObject.avatarId = data.settings.profile_img_id;
        this.settings.userObject.accountlistDefaultUnitFilter = data.settings.accountlist_default_unit_filter;
        if(data.settings.date_format == '') {
            data.settings.date_format = "%d.%m.%Y";
        }
        this.settings.userObject.dateFormat = this.convertDateFormat(data.settings.date_format);
        this.settings.taimerAccount.companyDateFormat = this.convertDateFormat(data.companySettings.date_format);
        switch (data.settings.date_format) {
            case '%d.%m.%Y':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%d.%m.');
                break;

            case '%m/%d/%Y':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%m/%d');
                break;

            case '%Y-%m-%d':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%m-%d');
                break;
            default:
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%d.%m.');
                break;
        }
        this.settings.userObject.personal_meeting_link = data.settings.personal_meeting_link;
        this.settings.userObject.datetimeFormat = `${this.settings.userObject.dateFormat} ${this.settings.userObject.timeFormat}`;
        this.settings.userObject.color = data.settings.color;
        this.settings.userObject.email = data.settings.email;
        this.settings.userObject.language = data.settings.lang || 'en';
        this.settings.userObject.project_read_companies = data.project_read_companies;
        this.settings.userObject.customer_read_companies = data.customer_read_companies;
        this.settings.userObject.mass_invoicing_companies = data.mass_invoicing_companies;
        this.settings.userObject.hasAccountWritePermission = data.has_account_write_permission;
        //this.settings.userObject.hasProjectWritePermission = data.has_project_write_permission;
        this.settings.userObject.hasCrmWritePermission = data.has_crm_write_permission;
        this.settings.userObject.sidebarStyle = data.settings.sidebar_style;
        this.settings.userObject.creationDate = data.settings.creationdate;
        this.settings.userObject.show_email = data.settings.show_email;
        this.settings.userObject.show_onedrive = data.settings.show_onedrive;
        this.settings.userObject.show_calendar = data.settings.show_calendar;
        this.settings.userObject.timetracker_old_drop = data.settings.timetracker_old_drop;
        this.settings.userObject.disable_user_balance = data.settings.disable_user_balance;
        //this.settings.userObject.hubSpotChatToken = data.hubspot_chat_token;
        this.settings.userObject.completedOnboardingItems = data.settings.completed_onboarding_items ? data.settings.completed_onboarding_items.split(',') : [];
        this.settings.userObject.onboardingStatus = data.settings.onboarding_status || 1;
        this.settings.userObject.accept_tos = data.settings.accept_tos;
        this.settings.userObject.accept_privacypolicy = data.settings.accept_privacypolicy;
        this.settings.userObject.accept_nonessential_cookies = data.settings.accept_nonessential_cookies;
        this.settings.userObject.company_name = data.settings.company_name;
        this.settings.userObject.clock_format = data.settings.clock_format;
        this.settings.userObject.isFreelancer = Number(data.settings.is_freelancer) === 1;

        this.settings.folder = data.folder;
        this.settings.timeTracker.workhour_accuracy = Number(data.globalSettings.workhour_accuracy);
        this.settings.timeTracker.bulkentry_interval = Number(data.globalSettings.bulkentry_interval);
        this.settings.timeTracker.saturday_is_workday = data.globalSettings.resourcing_count_saturday;
        this.settings.timeTracker.sunday_is_workday = data.globalSettings.resourcing_count_sunday;
        this.settings.timeTracker.workday_length = Number(data.settings.workday_length === null ? data.globalSettings.dailyworkinghours : data.settings.workday_length);
        this.settings.timeTracker.show_approval = data.showApprovals;

        if (this.settings.timeTracker.bulkentry_interval < 1) {
            this.settings.timeTracker.bulkentry_interval = this.settings.timeTracker.workhour_accuracy;
        }

        /* dev override */
        if (localStorage.devLang)
            this.settings.userObject.language = localStorage.devLang;
        this.settings.taimerAccount.currency = data.companySettings.currency.toUpperCase(); /* DEPRECATED */
        this.settings.taimerAccount.defaultVat = parseFloat(data.settings.defaultvat);
        this.settings.taimerAccount.name = data.companySettings.name; /* DEPRECATED */
        this.settings.taimerAccount.ourCompanyLang = data.companySettings.country_lang;  
        this.settings.taimerAccount.companyPrintLang = data.companySettings.print_lang;  
        this.settings.taimerAccount.financialYearStart = Number(data.companySettings.financial_year_start); /* DEPRECATED */
        this.settings.taimerAccount.userlimit = data.globalSettings.userlimit;
        this.settings.taimerAccount.trialStatus = data.companySettings.trialStatus;
        this.settings.taimerAccount.trialEnds = data.companySettings.trialEnds;
        this.settings.taimerAccount.hasMaventa = data.companySettings.has_maventa;
        this.settings.taimerAccount.logo = data.companySettings.logo;
        this.settings.taimerAccount.showLogo = data.companySettings.showlogo;
        this.settings.taimerAccount.countryCode = data.companySettings.country_code.toUpperCase();
        this.settings.taimerAccount.hasEnterpriseGroups = Boolean(Number(data.companySettings.has_enterprise_groups));
        this.settings.taimerAccount.use_maventa_targeting = data.companySettings.use_maventa_targeting;
        this.settings.taimerAccount.force_teams_branchofbusiness_for_projects = data.companySettings.force_teams_branchofbusiness_for_projects == 1;
        this.settings.taimerAccount.mandatory_team_selection_for_projects = data.companySettings.mandatory_team_selection_for_projects == 1;
        this.settings.taimerAccount.showState = this.settings.taimerAccount.countryCode === "US";
        this.settings.taimerAccount.origin = data.globalSettings.origin;
        this.settings.taimerAccount.defaulttermsofpayment = data.globalSettings.defaulttermsofpayment;
        this.settings.taimerAccount.defaultannotation = data.globalSettings.defaultannotation;
        this.settings.taimerAccount.defaultpenaltyinterest = data.globalSettings.defaultpenaltyinterest;
        this.settings.taimerAccount.stripeCustomerId = data.globalSettings.stripe_customer_id;
        this.settings.taimerAccount.attachmentMaxSize = data.attachmentMaxSize;
        this.settings.taimerAccount.isMulticompany = Boolean(data.is_multicompany);
        this.settings.taimerAccount.hasS3SweFinance = data.has_s3_swe_finance;
        this.settings.taimerAccount.useSubresources = data.globalSettings.use_subresources > 0;
        this.settings.taimerAccount.useExtraProjectHours = data.globalSettings.use_extra_project_hours > 0;
        this.settings.taimerAccount.allow_resourcing_to_project = data.globalSettings.allow_resourcing_to_project > 0;
        this.settings.taimerAccount.sessionTimeOut = data.globalSettings.session_timeout;
        this.settings.taimerAccount.hasExtranet = data.globalSettings.has_extranet > 0;
        this.settings.taimerAccount.hideOtherCompanyTasks = data.globalSettings.hide_other_company_tasks > 0;
        this.settings.taimerAccount.subContractorType = data.globalSettings.customers_types_id;
        this.settings.taimerAccount.shareCustomerBasicForAll = data.globalSettings.share_customer_basic_for_all > 0;
        this.settings.taimerAccount.preventCompanySpecificAutocompleteData = data.globalSettings.prevent_company_specific_autocomplete_data > 0;
        this.settings.taimerAccount.allowAccountManagerAndResponsibleFromAllCompanies = data.globalSettings.allow_account_manager_and_responsible_from_all_companies > 0;
        this.settings.taimerAccount.forceCustomerShipGroup = data.globalSettings.force_customership_group > 0;
        this.settings.taimerAccount.useHubSpot = data.use_hubspot;
        this.settings.taimerAccount.onboardingSeen = data.globalSettings.onboarding_seen;
        this.settings.taimerAccount.locked = data.globalSettings.locked;
        this.settings.taimerAccount.onboardingEnded = data.onboarding_ended;
        this.settings.taimerAccount.daysUntilCancellation = data.days_until_cancellation;
        this.settings.taimerAccount.tos_updated_date = data.globalSettings.tos_updated_date;
        this.settings.taimerAccount.privacypolicy_updated_date = data.globalSettings.privacypolicy_updated_date;

        this.settings.misc = data.misc;

        this.settings.allAddons = data.allAddons;
        this.settings.content = taimerContent;
        VersionContentManager.setContent(taimerContent);

        // Moment Calendar Locale (usses settings)
        moment.locale(this.getMomentLocale(data));
        window.moment = moment;        

        /* dev override */
        if (localStorage.devOrigin)
            this.settings.taimerAccount.origin = localStorage.devOrigin;

        this.settings.taimerAccount.numberFormat = data.companySettings.country_lang.toLowerCase() + "-" + data.companySettings.country_code.toUpperCase(); 

        if (this.settings.taimerAccount.origin === "ingram") {
            data.privileges.receivedinvoices = [];
        }

        if (!this.settings.fakePrivileges && !_.isEqual(data.privileges, this.settings.privileges)) {
            storage.setItem('taimerPrivileges', JSON.stringify(data.privileges));
            this.settings.privileges = data.privileges;
            this.setState({});
        }
        if (!_.isEqual(data.addons, this.settings.addons)) {
            storage.setItem('taimerAddons', JSON.stringify(data.addons));
            this.settings.addons = data.addons;
            this.setState({});
        }
        if (data.appVersion !== this.settings.versionId) {
            this.settings.versionId = data.appVersion;
            storage.setItem('taimerVersionId', data.appVersion);
            this.setState({});
        }
            
        // Restore page before login
        if (storage["loginRedirect"]) {
            const view = JSON.parse(storage["loginRedirect"]);
            delete view["settingsLoaded"];
            delete view["tabletMode"];

            storage.removeItem("loginRedirect");

            this.updateView(view);
        }
        
        if (storage.taimerLang !== this.settings.userObject.language) {
            storage.taimerLang = this.settings.userObject.language;
            window.location.reload();
        }
        
        if (localStorage.testUS === "1") {
            this.settings.taimerAccount.showState = true;
            this.settings.taimerAccount.origin = 'ingram';
        }

        this.setZendesk(data);
        
        // Intercom
        /*if (data.use_hubspot == 0) {
            window.intercomSettings = {
                app_id: "t2aiyxkp",
                user_id: data.user + "/" + data.adminCompanyId,
                user_hash: data.intercomHash,
                name: data.settings.firstname + " " + data.settings.lastname,
                email: data.settings.email,
                phone: data.settings.phone,
                created_at: data.settings.creationdate == "0000-00-00" ? Math.round(new Date("2015-01-01").getTime()/1000) : Math.round(new Date(data.settings.creationdate).getTime()/1000),
                folder: data.folder,
                taimer_lang: data.settings.lang,
                admin: data.privileges.admin ? 1 : 0,
                taimer_version: this.planNames[data.appVersion-1],
                taimer_software: 9,
                taimer_origin: this.settings.taimerAccount.origin == "ingram" ? "T-Mobile" : "Taimer",
                'Company industry': data.globalSettings.marketing_industry,
                'Company size': data.globalSettings.marketing_size,
                'Company Domain': data.companySettings.domain,
                plan: data.companySettings.defaultreference == "onboarding" ? 'Free trial' : 'Customer',
                company: {
                    name: data.companySettings.name,
                    id: data.adminCompanyId,
                    folder: data.folder,
                    country_code: data.companySettings.country_code,
                    taimer_version: this.planNames[data.appVersion-1],
                    taimer_software: 9,
                    currency: data.companySettings.currency,
                    plan: data.companySettings.defaultreference == "onboarding" ? 'Free trial' : 'Customer',
                    taimer_origin: this.settings.taimerAccount.origin == "ingram" ? "T-Mobile" : "Taimer",
                    industry: data.globalSettings.marketing_industry,
                    'Company size Text': data.globalSettings.marketing_size,
                    website: data.companySettings.domain
                },
                language_override: data.settings.lang
            }
        } else {
            window.Intercom = undefined;
        }*/

        this.initAppcues();
        this.initSurvicate();

        globalContext = this.settings;

        if ((data.globalSettings.tos_updated_date > data.settings.accept_tos || data.settings.accept_tos == null) || (data.globalSettings.privacypolicy_updated_date > data.settings.accept_privacypolicy || data.settings.accept_privacypolicy == null)) {
            this.setState({
                acceptTos: (data.globalSettings.tos_updated_date > data.settings.accept_tos || data.settings.accept_tos == null) ? false : true,
                acceptPrivacypolicy: (data.globalSettings.privacypolicy_updated_date > data.settings.accept_privacypolicy || data.settings.accept_privacypolicy == null) ? false : true,
                /*tosPrivacypolicyDialogOpen: true,*/  //TODO: remove comment to show tos and privacy policy dialog when needed
            });
        }

        try {
            if (data.settings.accept_nonessential_cookies !== null && data.settings.accept_nonessential_cookies !== '0000-00-00 00:00:00') {
                if (!mixpanel.has_opted_in_tracking()) {
                    mixpanel.opt_in_tracking();
                }
                mixpanel.identify(data.settings.email);
                mixpanel.people.set({
                    "$email":  data.settings.email,
                    "$first_name": data.settings.firstname,
                    "$last_name": data.settings.lastname,
                    "$created": data.settings.creationdate == "0000-00-00" ? new Date("2019-01-01").toISOString() : new Date(data.settings.creationdate).toISOString(),
                    "$last_login": new Date().toISOString(),
                    "$ip": data.ipAddress,
                    "$phone": data.settings.phone,
                    "full_name": data.settings.firstname + " " + data.settings.lastname, 
                    "user_id": data.user,
                    "folder": data.folder,
                    "language": data.settings.lang,
                    "title": data.settings.title,
                    "plan_type": this.planNames[data.appVersion],
                    "company_name": data.companySettings.name,
                    "currency": data.companySettings.currency,
                    "trial": data.companySettings.trialStatus == 1 ? true : false,
                    "company_id": data.company,
                    "admin": data.privileges.admin ? true : false,
                });
            } else {
                mixpanel.opt_out_tracking();
                if (data.settings.accept_nonessential_cookies == null) {
                    this.setState({gdprDialogOpen: true});
                }
            }
        } catch(e) {
            console.log('Mixpanel error');
        }

        

        /*if (data.use_hubspot == 1 && data.hubspot_chat_token && data.hubspot_chat_token != "") {
            window.hsConversationsSettings = {
                identificationEmail: data.settings.email,
                identificationToken: data.hubspot_chat_token,
            };

            window.HubSpotConversations && window.HubSpotConversations.widget.load();
        }*/

        if (!this.state.settingsLoaded) {
            this.loadWorkhourTimers();
        }

        if (!this.timetrackerSettings) {
            await this.refreshTimeTrackerSettings();
        }

        if (this.lastCompany === false) {
            this.lastCompany = Number(data.company);

            if (localStorage.lastCompany) {
                const l = Number(localStorage.lastCompany);

                if (l && l > 0) {
                    this.lastCompany = l;
                }
            }
        }

        if (!this._isPrintService()) {
            //session timout management
            this.getStorage().setItem("lastActivityTime", moment().unix());
            this.loopActivityTime();
        }

        let lang = data.settings.lang;
        if (lang == "se")
            lang = "sv";
        if (lang == "en_us")
            lang = "en-us";
        if (lang == "en_au")
            lang = "en-au";
        if (lang == "en_ca")
            lang = "en-ca";

        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
       
        this.setState({ settingsLoaded: true }, () => {
            setPageTitle && this.setTitle();
        });
    }

    setZendesk = (data) => {
        if (window.zE) {
            if (window.zE('webWidget:get', 'display') !== "launcher" && window.zE('webWidget:get', 'display') !== "hidden" && window.zE('webWidget:get', 'display') !== undefined)
                return;

            // Identify the user for Zendesk
            window.zE('webWidget', 'identify', {
                name: data.settings.firstname + " " + data.settings.lastname,
                email: data.settings.email
            });

            let hideChat = true;

            // Admins get to see chat and contact forms (except for Free, Growth and Business verisons)
            if (data.privileges.admin && !["1", "10", "11", "12", 1, 10, 11, 12].includes(data.appVersion)) {
                hideChat = false;
            }

            // Widget settings
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    offset: {
                        horizontal: this.state.view.tabletMode === 'On' ? '70px' : '230px'
                    },
                    chat: {
                        suppress: hideChat,
                        departments: {
                            enabled: ['Helpdesk, PSA'],
                            select: 'Helpdesk, PSA'
                        },
                        prechatForm: {
                            greeting: {
                                '*': 'Our chat serves Heeros PSA users on weekdays from 9 am to 11 am and from 1 pm to 3 pm.\n\nPlease describe the question/situation as precisely as possible in the first message.\n\nPlease do not share privacy-related material, such as personal or payroll information, through chat.',
                                fi: "Chattimme palvelee Heeros PSA käyttäjiä arkisin 9-11 ja 13-15.\n\nKuvailethan kysymyksen/tilanteen mahdollisimman tarkasti jo ensimmäiseen viestiin.\n\nEthän jaa chatin kautta tietosuojalain alaista materiaalia, kuten esimerkiksi henkilö- tai palkkatietoja."
                            }
                        },
                        tags: ['taimer']
                    },
                    contactForm: {
                        suppress: false,
                        fields: [
                            {
                                id: 5614431185554,
                                prefill: {
                                    '*': data.companySettings.name,
                                },
                            },
                            {
                                id: 5614543603090,
                                prefill: {
                                    '*': data.folder
                                }
                            }, 
                            {
                                id: 360014504859,
                                prefill: {
                                    '*': this.detectBrowser()
                                }
                            }
                        ]
                    },
                    helpCenter: {
                        suppress: false
                    },
                    talk: {
                        suppress: true
                    },
                    answerBot: {
                        suppress: false,
                        avatar: {
                            name: { "*" : 'Helper'}
                        },
                        title: {
                            '*': 'Helper'
                        }
                    }
                }
            });

            // Email prefilled to Zendesk forms
            window.zE('webWidget', 'prefill', {
                email: {
                    value: data.settings.email,
                    readOnly: true //optional
                },
                name: {
                    value: data.settings.firstname + " " + data.settings.lastname,
                    readOnly: true //optional
                }
            });

            // Widget language
            let lang = "en-gb";
            if (data.settings.lang == "fi" || data.settings.lang == "fi_special1")
                lang = "fi";
            else if (data.settings.lang == "se")
                lang = "sv";
            else if (data.settings.lang == "en_us")
                lang = "en-US";
            else if (data.settings.lang == "en_au")
                lang = "en-au";
            else if (data.settings.lang == "en_ca")
                lang = "en-ca";
            else if (data.settings.lang == "en" || data.settings == "en_special1")
                lang = "en-gb";

            window.zE('webWidget', 'setLocale', lang);
        }
    }

    detectBrowser = () => {          
        const userAgent = navigator.userAgent;
        let browserName;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
        }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
        }else if(userAgent.match(/safari/i)){
            browserName = "safari";
        }else if(userAgent.match(/opr\//i)){
            browserName = "opera";
        }else if(userAgent.match(/edg/i)){
            browserName = "edge";
        }else{
            browserName = "Unknown";
        }

        return browserName;
    }

    initAppcues = () => {
        const { userObject, folder, versionId } = this.settings;
        try {
            window.Appcues.identify(folder + userObject.usersId, {
                email: userObject.email,
                displayName: userObject.fullname,
                version: versionId,
                language: userObject.language,
                created_at: userObject.creationDate,
                folder
            });
            window.AppcueWidget = window.AppcuesWidget(window.Appcues.user());
        } catch (e) {
            console.error(e);
        }
    }
    initSurvicate = () => {
        const { userObject } = this.settings;
        try {
            const createdAt = userObject.creationDate == "0000-00-00" ? new Date("2019-01-01") : new Date(userObject.creationDate);
            const existsForOneMonth = (new Date() - createdAt) / 1000 / 60 / 60 / 24 > 30;
            window._sva && window._sva.setVisitorTraits({
                "email": userObject.email,
                "created_at": createdAt.toISOString(),
                "last_login": new Date().toISOString(),
                "exists_for_one_month": existsForOneMonth
            });
        } catch (e) {
            console.error(e);
        }
    }
    

    showNotification = (title, body = undefined, callback = undefined, extra = {}) => {
        if (!("Notification" in window))
            return;

        if (Notification.permission === "denied")
            return;

        if (Notification.permission !== "granted") {
            Notification.requestPermission().then( (perm) => {
                if (perm === "granted")
                    this.showNotification(title, body);
            } )

            return;
        }

        const notification = new Notification(title, {
            body,
            icon: notificationIcon,
            ...extra,
        });

        notification.onclick = callback;
    }

    getFinancialYear = (date = null, financialYearStart = null) => {

        if (!financialYearStart) {
            financialYearStart =  this.settings.taimerAccount.financialYearStart;
        }

        let year = null;

        if (!date) {
            year = new Date();

            if ((getMonth(year) + 1) < financialYearStart)
                year = addYears(year, -1);
        }
        else if (typeof date === 'number') {
            year = parse(`${date}-01-01`, "YYYY-MM-DD", new Date());
        } 
        else {            
            if ((getMonth(date) + 1) < financialYearStart)
                year = addYears(date, -1);
            else
                year = (date);
        }

        year = startOfYear(year);

        const start = addMonths(year, financialYearStart - 1);
        const end = endOfMonth( addMonths(year, Number(financialYearStart) + 10) );

        return {
            label: `${format(start, "MM/YYYY")} - ${format(end, "MM/YYYY")}`,
            start,
            end,
        }
    }


    presentCurrency = (value, currency, options = {}) => {
        if(!currency) currency = this.settings.taimerAccount.currency;
    
        const response = new Intl.NumberFormat(this.settings.taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'symbol',
            ...options
        }).format(value);
 
        return response;
    }

    updateUrl(url) {
        if(typeof url === "string") 
            window.history.pushState({}, "", url);
        else if(typeof url === "object") {
            const parts      = window.location.search.split("&");
            const currentUrl = {};
            const urlParts = [];

            parts[0] = parts[0].charAt(0) === "?" ? parts[0].substr(1) : parts[0];

            parts.forEach(part => {
                const split = part.split("=");

                if(split.length > 1)
                    currentUrl[split.shift()] = split.join("=");
                else
                    currentUrl[split[0]] = "";
            });

            for(const key in url)
                currentUrl[key] = url[key];

            for(const i in currentUrl)
                urlParts.push(`${i}=${currentUrl[i]}`);

            window.history.pushState({}, "", window.location.pathname + "?" + urlParts.join("&"));
        }
    }
    
    getUrlParams(update) {
        update = update ? _.cloneDeep(update) : {};
        
        const { view } = this.state;
        const sticky = ['tabletMode'];
        const updateKeys = Object.keys(update);

        if (update.selectedPage) {
            const split = update.selectedPage.split("-");
            update.module = split[0];
            update.action = split[1];
        }
        
        if (update.module || update.action)
            update.selectedPage = update.module + "-" + update.action;
        
        if (!sticky.some(e => updateKeys.indexOf(e) === -1) && updateKeys.length === 1) {
            /* only sticky params are updated, add saved params to state change */
            
            update = {...view, ...update};
        }
        else if(!update.module && !update.action && !update.selectedPage) {
            /* view is not changing, add saved params to state change */
            
            update = {...view, ...update};

            Object.keys(update).forEach(key => {
                if (update[key] == undefined) {
                    delete update[key];
                }
            });
        }
        else {
            /* view is changing, add sticky params to state change */
            
            sticky.forEach(e => view[e] !== undefined && (update[e] = view[e]));
        }
        
        return update;
    }
    urlify(update) {
        update = this.getUrlParams(update);
        return `index.html?${$.param(update)}`;
    }

    updateView(update, options = null, stateRefresh = true /* DEPRECATED */, rememberPage = false, noWhoami = false, windowTitle = null, pushHistory = true) {
        windowTitle !== null && this.setTitle(windowTitle);
        update.selectedPage == 'login-login' && this.setTitle(undefined, false);

        options = typeof options !== "object" ? {
            newWindow: options,
        } : (options ?? {
            newWindow: false,
        });
        
        this.refErrorBoundary && this.refErrorBoundary.current && this.refErrorBoundary.current.clearError();
        let nonUrlParams = {};
        if (update.nonUrlParams) {
            nonUrlParams = update.nonUrlParams;
            delete update.nonUrlParams;
        }

        update = this.getUrlParams(update);

        if ((this.state.view.selectedPage !== update.selectedPage || this.state.view.selectedTab !== update.selectedTab || this.state.view.selectedSubTab !== update.selectedSubTab) && this.isDirty && !options.newWindow) {
            this.dirtyLeaveTarget = update;
            this.dirtyHandler && this.dirtyHandler();

            this.setState({unsavedWarningOpen: true});

            return;
        }
        
        if (rememberPage && update.selectedPage !== 'login-login') {
            localStorage.lastView = JSON.stringify({...update});
        }

        // Remove elements that might get "stuck" in the DOM for one reason or another.
        const chartTooltip = document.getElementById("chartjs-tooltip");
        if (chartTooltip) {
            chartTooltip.parentNode.removeChild(chartTooltip);
        }
        const shouldReplace = update?.replace == true;
        if (shouldReplace) {
            delete update.replace;
        }
        const url = `index.html?${$.param(update)}`;

        if (options.previewList !== undefined) {
            if (!options.previewList) {
                delete localStorage["activePreviewList"];
            } else {
                localStorage["activePreviewList"] = JSON.stringify(options.previewList);
            }
        }
        
        if (options.newWindow) {
            const win = window.open(url, '_blank');
            if (typeof newWindow === "function")
                win.onload = function () { win.taimerCallback = options.newWindow; };
        }
        else { /* single page app */
            if (shouldReplace) {
                window.history.replaceState({}, this.getPageTitleVersion(), url);
            } else {
                pushHistory && window.history.pushState({}, this.getPageTitleVersion(), url);
            }
            if (update.selectedPage != this.state.view?.selectedPage) {
                this.setAdditionalHeaders([]);
                this.setOverrideHeaderTitles(undefined);
                this.previousView = cloneDeep(this.state.view);
            }

            // Update preview list state with currentIndex if open
            const previewList = options.previewList ?? this.state.activePreviewList;
            const activePreviewList = previewList ? {
                ...previewList,
                currentIndex: this.getPreviewListPosition(previewList, update),
            } : null;

            this.setState({view: update, activePreviewList, nonUrlParams}, () => !noWhoami && this.whoami());
            if(window.Appcues){
                window.Appcues.page(); 
            }
            if (window.Intercom) {
                window.Intercom('update', {last_request_at: parseInt((new Date()).getTime()/1000)});
            }
        }
    }

    goToPreviousView = (fallbackLocation = undefined) => {
        if (!this.previousView && !fallbackLocation) return;
        this.updateView(this.previousView || fallbackLocation);
    }

    onUnreadCount = (unreadCount) => {
        this.refTaimerNavi.current && this.refTaimerNavi.current.setUnreadCount(unreadCount);
    }

    onUnseenCount = (unseenCount) => {
        this.refTaimerNavi.current && this.refTaimerNavi.current.setUnseenCount(unseenCount);
    }

    openTeamChat = (location = {}) => {
        this.refTeamChat.current.open(location)
    }

    openNotifications = (location = {}) => {
        this.refNotifications.current.open(location)
    }

    toggleBuyDialog = (addon = "") => {
        const storage = this.getStorage();
        
        if (storage.onboardingEnded == "true")
            return;

        //addon != "" && (this.state.buyTaimerOpen == false || this.state.buyTaimerOpen == undefined) && mixpanel.track('Open Add-on Dialog', {'Clicked add-on': addon, 'Trial dialog': false});

        this.setState({ buyTaimerOpen: !this.state.buyTaimerOpen, buyTaimerAddon: addon });
    }

    setPerms = (perm) => {
        if (!perm) {
            this.settings.fakePrivileges = false;
            this.whoami();
            return;
        }

        this.settings.fakePrivileges = true;
        this.settings.privileges = perm;
        this.forceUpdate();
    }

    dirtyCancel = () => {
        const { unsavedDialogData } = this;
        this.dirtyLeaveTarget = {};

        unsavedDialogData && unsavedDialogData.onCancel && unsavedDialogData.onCancel();
        // this.unsavedDialogData = false;
        this.setState({ unsavedWarningOpen: false });
    }

    dirtyAccept = () => {
        this.dirtyHandler && this.dirtyHandler(true);
        this.setDirty(false);

        this.state.overlayComponent ? this.setOverlayComponent(this.dirtyLeaveTarget) : this.updateView(this.dirtyLeaveTarget);
        this.setState({ unsavedWarningOpen: false });
    }

    fetchNavigationNotificationData = (module = "") => {
        DataHandler.get({ url: `notifications/navigation`, module: module }).done(response => {
            this.setState({ navigationNotifications: {...this.state.navigationNotifications, ...response} });
        })
    }

    componentDidMount() {
        const { action, module, tab , tabletMode } = this.state;
        $('body').attr('action', action).attr('module', module).attr('tab', tab).attr('tabletMode', tabletMode);
        if ("Notification" in window) {
            Notification.requestPermission();
        }
        this.getStorage().setItem("lastActivityTime", moment().unix());
        setTimeout(() => {
            this.refNotifications.current && this.refNotifications.current.refresh(true);
        }, 500);

        this.fetchNavigationNotificationData();

        Utils.initMacCmdListener();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.view.selectedPage !== nextState.view.selectedPage) {
            this.viewWillChange(this.state.view, nextState.view);
        }

        return true;
    }

    componentDidUpdate() {
        const { action, module, tab } = this.state;

        $('body').attr('action', action).attr('module', module).attr('tab', tab);    
    }

    viewWillChange(currentView, nextView) {
        this.unregisterOnUnloadListeners();
    }

    unregisterOnUnloadListeners = () => {
        this.onUnloadListeners.forEach(l => {
            window.removeEventListener("unload", l);
        });

        this.onUnloadListeners = [];
    }

    registerOnUnloadListener = (fn) => {
        window.addEventListener("unload", fn);

        this.onUnloadListeners.push(fn);
    };

    emptyCacheOnUnload = (viewName) => {
        this.registerOnUnloadListener(() => {
            const storage = sessionStorage.taimerToken 
                ? sessionStorage 
                : localStorage;

            const token   = `Bearer ${storage.taimerToken}`;
            const headers = new Headers();

            headers.append('Authorization-taimer', token);

            const prefix = window.location.href.indexOf(":8080") > -1 
                ? "react/api/" 
                : "/react/api/" ;

            fetch(`${prefix}/cache/handle_view_refresh/${viewName}`, {
                method: "POST", 
                body: null,            
                headers: headers,       
                credentials: 'include',
                keepalive: true
            });
        });
    };

    toggleNewMenu = () => {
        this.settings.userObject.sidebarStyle = !this.settings.userObject.sidebarStyle;

        this.forceUpdate();
    }

    setSidebarStyle = (sidebarStyle) => {
        if (sidebarStyle != 0 && sidebarStyle != 1) return;
        this.settings.userObject.sidebarStyle = sidebarStyle;
        this.forceUpdate();
    }

    setLastCompany = (company) => {
        company = Number(company);

        if (!company) return;

        this.lastCompany = company;
        localStorage.lastCompany = company;
    }

    setOverlayComponent = (overlayComponent) => {
        if (!overlayComponent && this.isDirty) {
            this.dirtyLeaveTarget = overlayComponent;
            this.dirtyHandler && this.dirtyHandler();
            this.setState({ unsavedWarningOpen: true });
            return;
        }
        this.setState({ overlayComponent });
    }

    showDialogContent = (dialogContent, dialogProps) => {
        const dialog = (
            <Dialog open={true} onClose={this.closeDialog} PaperProps={{ className: "taimer-dialog-container" }} {...dialogProps}>
                {dialogContent}
            </Dialog>  
        );
        this.showDialog(dialog);
    }
    
    showConfirmationDialog = ({header, warning, confirmText, cancelText = this.tr("Cancel"), onConfirm, confirmButtonClass = undefined, hideWarningIcon = false, cancelButtonClass = undefined, confirmDisabled = false, infoText = undefined, wider = false}) => {
        this.showDialog( <CoreDialog
            dialogType="delete"
            dialogProps={{
                onCloseClick: this.closeDialog,
                close: this.closeDialog,
                onConfirm: () => {
                    this.closeDialog();
                    onConfirm && onConfirm();
                },
                onCancel: this.closeDialog,
                header,
                translatedConfirmButtonText: confirmText,
                cancelButtonText: cancelText,
                warning: () => warning,
                confirmButtonClass,
                hideWarningIcon,
                cancelButtonClass,
                confirmDisabled,
                infoText,
                wider
            }}
        />);
    }

    showDialog = (dialog) => {
        this.setState({ dialog });
    }

    showSlider = (slider, forceBackdrop = false) => {
        const sliders = [...this.state.sliders || []];
        if (sliders.length > 0) {
            slider = React.cloneElement(slider, { key: sliders.length, hideBackdrop: !forceBackdrop });
        }
        sliders.push(slider);
        this.setState({ sliders });
    }

    showSliderContent = (title, content, forceBackdrop = false) => {
        const slider = (
            <AnimatedSlider open={true} title={title} onClose={this.closeSlider}>
                {content}
            </AnimatedSlider>
        );
        this.showSlider(slider, forceBackdrop);
    }

    closeDialog = (callback) => {
        this.setState({ dialog: undefined }, () => {
            callback && callback instanceof Function && callback();
        });
    }

    closeSlider = (callback) => {
        let sliders = [...this.state.sliders || []];
        sliders.pop();
        if (sliders.length == 0) sliders = undefined;
        this.setState({ sliders }, () => {
            callback && typeof callback == 'function' && callback();
        });
    }

    setAdditionalHeaders = (additionalHeaders) => {
        if (!isEqual(additionalHeaders, this.state.additionalHeaders)) {
            this.setState({ additionalHeaders });
        }
    }

    setOverrideHeaderTitles = (titles) => {
        this.refTaimerNavi.current?.setOverrideHeaderTitles && this.refTaimerNavi.current.setOverrideHeaderTitles(titles);
    }

    showUpgradeSlider = (version) => {
        this.showSlider(<UpgradeVersionSlider key="upgradeVersion" open={true} onClose={this.closeSlider} version={(!!version?.id && !!version?.name) ? version : undefined} />, true);
    }

    showBuyLicensesSlider = () => {
        this.showSlider(<BuyLicensesSlider key="buyLicenses" open={true} onClose={this.closeSlider} />, true);
    }

    showOnboardingSlider = () => {
        this.showSlider(<OnboardingSlider key="onboarding" open={true} onClose={this.closeSlider} />, true);
    }

    addAccount = (initialSelectionProps, otherProps) => {
        const { addons } = this.settings;
        if (addons.customers && addons.customers.limit && addons.customers.used >= addons.customers.limit) {
            // need to think what to show here
            this.showUpgradeSlider();
            return;
        }
        this.showSlider(<AddAccountSlider key="addAccount" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addContact = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddContactSlider key="addContact" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    openActivitySlider = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddActivitySlider key="addActivity" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addUser = async (initialSelectionProps) => {
        const { versionId, addons } = this.settings;
        const userLimit = await DataHandler.get({ url: 'settings/limits/users' });
        if (userLimit.used >= userLimit.limit) {
            if (addons.new_stripe) {
                if ((versionId == 11 && userLimit.used >= 3) || versionId == 10) {
                    this.showUpgradeSlider();
                    return;
                }
                this.showBuyLicensesSlider()
                return;
            }
            if (versionId == 1) {
                DataHandler.get({ url: 'settings/subscription/getFreeId' }).done(response => {
                    const id = response.id
                    if (id) {
                        this.updateView({
                            module: 'onboarding',
                            action: 'order',
                            subId: id
                        });
                    }
                });
            } else {
                this.updateView({
                    module: 'onboarding',
                    action: 'order',
                    subId: false
                });
            }
            return;
        }
        this.showSlider(<AddUserSlider key="addUser" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} />)
    }

    addHours = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddHoursSlider key="addHours" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addResource = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddResourceSlider key="addResource" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addProject = (initialSelectionProps, otherProps) => {
        const { addons } = this.settings;
        // if (addons.projects && addons.projects.limit && addons.projects.used >= addons.projects.limit) {
        //     this.toggleBuyDialog('projects');
        //     return;
        // }
        this.showSlider(<AddProjectSlider key="addProject" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addInvoice = async (props, newTab) => {
        const { addons } = this.settings;
        const { project, account, company, origin_point, preselect_material_rows } = props;
        const { view } = this.state;

		if (addons.invoicing && addons.invoicing.limit && addons.invoicing.used >= addons.invoicing.limit) {
			this.toggleBuyDialog("invoicing");
            return;
		}
        const params = { module: 'invoices', action: 'view', editMode: 1, origin_point: origin_point, preselect_material_rows: preselect_material_rows };
        if (project) {
            params.projects_id = project.id;
            params.customers_id = project.account.id;
            params.companies_id = project.companies_id;
            params.preselect = 'projectInvoice';

            const invoiceableProjects = await DataHandler.get({ url: `invoices/invoiceable_projects/${company}`, invoiceType: 'all'});
            const isInvoiceable = invoiceableProjects.projects.find(e => Number(e.id) === Number(project.id));
            
            if (isInvoiceable && isInvoiceable.invoiceable) {
                params.template = 'material';
                this.updateView(params, newTab);
            } 
            else if (isInvoiceable && isInvoiceable.refundable) {
                params.invoiceType = 3;
                this.updateView(params, newTab);
            } else {
                const companies = await DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, invoice_only_material: 1 });
                const foundCompany = companies.find(c => c.id == (project?.companies_id || company));
                if (foundCompany?.invoice_only_material != 1) {
                    params.template = 'blank';
                    this.showDialog(
                        <EmptyInvoiceConfirmationDialog 
                            open
                            onDialogClose={this.closeDialog}
                            data={{
                                text: this.tr("No uninvoiced material for project. Do you want to create an empty invoice?"),
                                params: params,
                                updateView: (params) => {
                                    this.closeDialog();
                                    this.updateView(params);
                                }}} 
                            />
                    );
                } else {
                    this.currentViewRef.current && this.currentViewRef.current.props.enqueueSnackbar && this.currentViewRef.current.props.enqueueSnackbar(this.tr("No uninvoiced material for project."), {
                        variant: "warning",
                    });
                }
            }
        } else if (account) {
            params.customers_id = account.id;
            params.companies_id = company;
            params.preselect = 'account';
            this.updateView(params, newTab);
        } else {
            params.companies_id = company;
            this.updateView(params, newTab);
        }
	}

    addExpense = (props = {}) => {
        this.setOverlayComponent(<ExpenseWizard expenseType="1" enqueueSnackbar={this.props.enqueueSnackbar} {...props} />);
    }

    addTravelExpense = (props = {}) => {
        this.setOverlayComponent(<ExpenseWizard expenseType="2" enqueueSnackbar={this.props.enqueueSnackbar} {...props} />);
    }

    renderGdprDialogs = () => {
        return (
            <>
                <Dialog
                    data-testid="gdpr-tos-pp-dialog"
                    className="gdprDialog"
                    open={this.state.tosPrivacypolicyDialogOpen}>
                    <div className="graphicIllustration"><WeWantToBeTransparentGraphic className="svg"/></div>
                    <DialogTitle>{this.tr("We want to be transparent")}</DialogTitle>
                    <DialogContent className="gdprDialogContent">
                        <DialogContentText>
                            {this.renderTosPpContent()}
                        </DialogContentText>
                        <DialogContentText>
                            {(this.settings.taimerAccount.tos_updated_date > this.settings.userObject.accept_tos || this.settings.userObject.accept_tos == null) && 
                            <div>
                                <div className="gdprCheckbox">
                                    <div data-testid="gdpr-dialog-checkbox-accept-tos" onClick={() => this.setState({acceptTos: !this.state.acceptTos})}  className={this.state.acceptTos ? 'checkmarkContainer checked' : 'checkmarkContainer'}></div>
                                </div>
                                <span className="acceptLabel">{this.tr('I accept the')}</span> {this.tosLink()}
                            </div>
                            }
                            {(this.settings.taimerAccount.privacypolicy_updated_date > this.settings.userObject.accept_privacypolicy || this.settings.userObject.accept_privacypolicy == null) && 
                            <div>
                                <div className="gdprCheckbox">
                                    <div data-testid="gdpr-dialog-checkbox-accept-privacypolicy" onClick={() => this.setState({acceptPrivacypolicy: !this.state.acceptPrivacypolicy})}  className={this.state.acceptPrivacypolicy ? 'checkmarkContainer checked' : 'checkmarkContainer'}></div>
                                </div>
                                <span className="acceptLabel">{this.tr('I accept the')}</span> {this.privacypolicyLink()}
                            </div>
                            }
                            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button data-testid="gdpr-dialog-tos-pp-decline" variant="contained" onClick={() => this.forceLogOut()} color="info">
                            {this.tr("Decline & log out")}
                        </Button>
                        <Button 
                            data-testid="gdpr-dialog-tos-pp-accept"
                            disabled={!this.state.acceptTos || !this.state.acceptPrivacypolicy} 
                            onClick={() => this.acceptTosAndPrivacypolicy()} 
                            color="primary"
                        >
                        {this.tr('Done')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    data-testid="gdpr-nonessential-cookies-dialog"
                    className="gdprDialog"
                    open={this.state.gdprDialogOpen && !this.state.tosPrivacypolicyDialogOpen}>
                    <div className="graphicIllustration"><WeValueYourPrivacyGraphic className="svg"/></div>
                    <DialogTitle>{this.tr("We value your privacy")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="gdprDialogContent">
                            {this.tr("We use non-essential cookies to optimize our product's functionality and to give you the best possible experience. By clicking \"Accept\", you consent to our use of these cookies. You can change your selection later in the settings.")}
                        </DialogContentText>
                        {this.state.gdprReadmore &&
                        <>
                            <DialogContentText className="subHeader">{this.tr("Non-essential cookies we use")}</DialogContentText>
                            <DialogContentText className="subHeader">{this.tr("Mixpanel")}</DialogContentText>
                            <DialogContentText>
                                {this.tr("We use Mixpanel to...")}
                            </DialogContentText>
                        </>
                    }
                        <div className="gdprReadmore" onClick={() => this.setState({gdprReadmore: !this.state.gdprReadmore})}>{this.state.gdprReadmore ? this.tr("Read less about these cookies") : this.tr("Read more about these cookies")}</div>
                    </DialogContent>
                    <DialogActions>
                        <Button data-testid="gdpr-dialog-cookies-reject" variant="contained" onClick={() => this.setNonessentialCookies(0)} color="info">
                            {this.tr("Reject")}
                        </Button>
                        <Button data-testid="gdpr-dialog-cookies-accept" onClick={() => this.setNonessentialCookies(1)} color="primary">
                            {this.tr('Accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    showGdprDialog = (show) => {
        this.setState({gdprDialogOpen: show});
    }

    renderTosPpContent = () => {
        const addTos = (this.settings.taimerAccount.tos_updated_date > this.settings.userObject.accept_tos || this.settings.userObject.accept_tos == null);
        const addPp = (this.settings.taimerAccount.privacypolicy_updated_date > this.settings.userObject.accept_privacypolicy || this.settings.userObject.accept_privacypolicy == null);
        const agreementBase = this.tr('Before you can move forward, you need to read and accept our ${terms} ${and} ${privacyPolicy}');
                const agreementParts = agreementBase.split(' ');
                const agreement = agreementParts.map((word, i) => {
                    const postfix = i == agreementParts.length - 1 ? '' : ' ';
                    switch (word) {
                        case '${terms}':
                            if (addTos) {
                                return (<>
                                    {this.tosLink()}
                                    </>);
                            } else {
                                break;
                            }
                        case '${and}':
                            if (addTos && addPp) {
                                return (<>
                                    {' '}{this.tr("and")}{' '}
                                    </>);
                            } else {
                                break;
                            }
                        case '${privacyPolicy}':
                            if (addPp) {
                                return (<>
                                    {this.privacypolicyLink()}{'. '}
                                    </>);
                            } else {
                                return (<>
                                    {'. '}
                                    </>);
                            }
                        default:
                            return `${word}${postfix}`;
                    }
                });
                return (
                    <p>{agreement} {(addTos && addPp) ? this.tr("Don't worry, it's easy - just tap on the two checkboxes below.") : this.tr("Don't worry, it's easy - just tap on the checkbox below.")}</p>
                );
    }

    tosLink = () => {
        return (
            <a
                href={this.settings.userObject.language != 'fi' ? `https://psahelpcenter.heeros.com/hc/en-us/articles/10207381240978` : `https://psahelpcenter.heeros.com/hc/fi/articles/10207381240978`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {this.tr('Terms of Service')}
            </a>
        );
    }

    privacypolicyLink = () => {
        return (
            <a href={this.settings.userObject.language != 'fi' ? `https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754` : `https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754`} target="_blank" rel="noopener noreferrer">
                {this.tr('Privacy Policy')}
            </a>
        );
    }

    render() {
        const { settingsLoaded, buyTaimerOpen, unreadCount, view, workhourTimers, overlayComponent, additionalHeaders, dialog, sliders, activePreviewList, navigationNotifications, nonUrlParams } = this.state;
        const { navigationOnly, enqueueSnackbar, closeSnackbar } = this.props;
        const { addons } = this.settings;

        const noLoadIndicatorModules = ['login', 'external'];
        if (view.action != 'order') {
            noLoadIndicatorModules.push('onboarding');
        }
        // Time Tracker doesn't support runtime locale changes
        if (!settingsLoaded && !noLoadIndicatorModules.includes(view.module))
            return <div><img className='main-page-loading-indicator' style={{ marginTop: -35 }} src={require('./dashboard/insights/img/loading.svg').default} /></div>

        const storage = this.getStorage();
        
        const { state, updateView, updateUrl, openTeamChat, toggleBuyDialog, whoami, unsavedDialogData, openNotifications } = this;
        const props = { ...state.view, ...nonUrlParams, updateView, updateUrl, openTeamChat, toggleBuyDialog, whoami, enqueueSnackbar, closeSnackbar, navigationNotifications };

        const views = {
            // new main views with tabs
            'dashboard-main': (<Dashboard {...props} />),
            'contacts-main': <Contacts {...props} />,
            'timemanagement-main': <TimeManagement {...props} />,
            'resourceplanning-main': <ResourcePlanning {...props} />,
            'invoices-main': <Invoices {...props} />,
            'costs-main': <Costs {...props} />,
            'products-main': <Products {...props} />,
            // old views
            'dashboard-overview': (<Dashboard {...props} />),
            'dashboard-myday': (<Dashboard {...props} />),
            'dashboard-my_day': (<Dashboard {...props} />),
            'dashboard-profitloss': (<ProfitLoss {...props} />),
            'dashboard-invoicing': <InvoicingInsight {...props} />,
            'dashboard-hours': <HoursInsight {...props} />,
            'dashboard-sales': <SalesInsight viewProps={state.view} {...props} />,
            'dashboard-goals': <GoalsInsight {...props} />,
            'customers-view': (<AccountView key={props.id} viewProps={state.view} {...props} />),
            'mails-login': (<MailLogin className="main" {...props} />),
            'onedrive-login': (<OnedriveLogin className="main" {...props} />),
            'products-view': (<Products {...props} />),
            'cpq-view': (<Products {...props} selectedTab="cpq" />),
            'catalog-list': (<Products {...props} selectedTab="catalogs" />),
            'projects-view': (<ProjectView key={props.id} viewProps={state.view} {...props} />),
            'projects-list': (<ProjectList viewProps={state.view} {...props} />),
            'contact-view': (<ContactView {...props} />),
            'contacts-list': (<Contacts {...props} selectedTab="contacts" />),
            'users-list': (<Contacts {...props} selectedTab="users" />),
            'workhours-calendar': (<TimeManagement {...props} selectedTab="time-tracker" selectedSubTab="calendarView" />),
            'workhours-list': (<TimeManagement  {...props} selectedTab="time-tracker" selectedSubTab="myHours" />),
            'workhours-modified': (<TimeManagement {...props} selectedTab="time-tracker" selectedSubTab="myHours-modified" />),
            'invoices-list': (<Invoices {...props} selectedTab="invoices" />),
            'invoices-mass': (<Invoices {...props} selectedTab="mass-invoicing" />),
            'invoices-log': (<Invoices {...props} selectedTab="invoices-log" />),
            'worktrips-modify': (<ExpenseView key={props.id} {...props} />),
            'worktrips-list': (<Costs {...props} selectedTab="expenses" />),
            'travelexpenses-list': (<Costs {...props} selectedTab="travel-expenses" />),
            'customers-list': (<Contacts {...props} selectedTab="accounts" />),
            'projects-kanban': (<ProjectKanban {...props} />),
            'login-login': (<Login {...props} />),
            'settings-index': (<Settings viewProps={state.view} {...props} />),
            'reports-view': (<ReportsView viewProps={state.view} {...props} />),
            'new_reports-view': (<NewReportsView viewProps={state.view} {...props} />),
            'users-view': (<UserView {...props} />),
            'teamchat-popup': (<TeamChat full />),
            'resourcing-view': (<ResourcePlanning {...props} />),
            'invoices-view': (<InvoiceView key={props.id} {...props} />),
            'collaborate-view': (<CollaborateView {...props} />),
            'onboarding-order': (<OrderView {...props} />),
            'entry-order': (<div />),
            'purchaseorder-view': <PurchaseOrderView key={props.id} {...props} />,
            'bills-list': <Costs {...props} selectedTab="bills" />,
            'print-quote': <QuotePrint {...props} />,
            'receivedinvoice-view': <ReceivedInvoiceView key={props.id} {...props} />,
            'activities-insight': (<ActivitiesInsight {...props} />),
            'hours-insight': (<HoursInsight {...props} />),
            'sales-insight': <SalesInsight {...props} />,
            'goals-insight': <GoalsInsight {...props} />,
            'calendar-login': (<TaimerCalendarLogin className="main" {...props} />),
            'insights-view': (<InsightsView {...props} />),

            'test-view': (<TestView {...props} />),
        };
        
        let selectedPage = view.selectedPage; 
        
        if (!storage.taimerToken) {
            selectedPage = "login-login";
        }
        if (view.module == 'onboarding' && view.action == 'view') {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            <SettingsContext.Provider value={this.settings}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <OnboardingView
                                        showGdprDialog={this.showGdprDialog}
                                        acceptNonessentialCookies={this.state.acceptNonessentialCookies}
                                    />
                                </Suspense>
                                {this.renderGdprDialogs()}
                            </SettingsContext.Provider>
                        </SnackbarProvider>
                    </ThemeProvider >
                </StyledEngineProvider>
            );            
        }
        if (view.module == 'external' && view.action == 'quote') {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <SnackbarProvider 
                            action={(key) => (
                                <CloseSnackbarButton key={key} />
                            )} 
                            iconVariant={{ default: <ThumbUp /> }} 
                            classes={{
                                containerAnchorOriginTopRight: 'externalSnackbar',
                                containerAnchorOriginTopLeft: 'externalSnackbar',
                                root: 'snackbarRoot',
                            }} 
                            maxSnack={3} 
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <SettingsContext.Provider value={this.settings}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ExternalQuote />
                                </Suspense>
                            </SettingsContext.Provider>
                        </SnackbarProvider>
                    </ThemeProvider >
                </StyledEngineProvider>
            );            
        }

        const isPopup = ["teamchat", "login", "print", "proposal"].indexOf(view.module) > -1 || selectedPage === 'login-login';

        if (isPopup) {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <SettingsContext.Provider value={this.settings}>
                            <div id="react-content">
                                <Suspense fallback={<div>Loading...</div>}>
                                    {views[view.selectedPage]}
                                </Suspense>
                            </div>
                            {dialog && dialog}
                        </SettingsContext.Provider>
                        </SnackbarProvider>
                    </ThemeProvider >
                </StyledEngineProvider>
            );
        }
 
        switch(selectedPage) {
            case 'dashboard-profitloss':
                if(!this.hasPrivilege('dashboard', 'profit_loss_read')) {
                    selectedPage = 'dashboard-main';
                    break;
                }
        }

        let overrideContent;
        
        if (this.settings.taimerAccount.onboardingEnded) {
            const { addons } = this.settings;
            selectedPage = addons.new_stripe ? "entry-order" : "onboarding-order";
            if (addons.new_stripe) {
                overrideContent = <EntryOrderView />
            }
        }

        if (this.settings.taimerAccount.locked == -2) {
            overrideContent = <LockUsersView />
        }

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={TaimerTheme}>
                <SettingsContext.Provider value={this.settings}>
                    <SnackbarProvider 
                        action={(key) => (
                            <CloseSnackbarButton key={key} />
                        )} 
                        iconVariant={{ default: <ThumbUp /> }} 
                        classes={{
                            containerAnchorOriginTopRight: 'snackbarZindex',
                            containerAnchorOriginTopLeft: 'snackbarZindex',
                            root: 'snackbarRoot',
                        }} 
                        maxSnack={3} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        {overrideContent || <div id="react-content" className={`${view.tabletMode === 'On' ? "tablet-mode" : ""} ${overlayComponent ? "no-display" : ""}`}>
                            <TaimerNavi
                                key={(this.settings.userObject.sidebarStyle === '0' ? "navi-new" : "navi") 
                                        + (this.settings.userObject.show_email == '0' ? "no_email" : "show_email") 
                                        + (this.settings.userObject.show_onedrive == '0' ? "no_onedrive" : "show_onedrive")
                                        + (this.settings.userObject.show_calendar == '0' ? "no_calendar" : "show_calendar")}
                                toggleNewMenu={this.toggleNewMenu}
                                goToPreviousView={this.goToPreviousView}
                                ref={this.refTaimerNavi}
                                url={{ ...state.view, tabletMode: undefined }}
                                toggleBuyDialog={toggleBuyDialog}
                                updateView={this.updateView}
                                tabletMode={view.tabletMode}
                                chatUnread={unreadCount}
                                additionalHeaders={additionalHeaders}
                                currentViewRef={this.currentViewRef}
                                chatClicked={() => this.refTeamChat.current?.open && this.refTeamChat.current.open(this.state)}
                                notificationsClicked={() => this.refNotifications.current.open(this.state)}
                                personalNoteClicked={() => this.refPersonalNote.current?.open && this.refPersonalNote.current.open()}
                                changeDevPerms={this.setPerms}
                                changeDevLang={this.changeDevLang}
                                useAppcues={this.state.useAppcues}
                                workhourTimers={workhourTimers} 
                                activePreviewList={activePreviewList}
                                navigationNotifications={navigationNotifications}
                                sidebarItems={this.settings.content.sidebar || []}
                                />
                            {!navigationOnly && <ErrorBoundary ref={this.refErrorBoundary} updateView={this.updateView}>{React.cloneElement(views[selectedPage] || <PageNotFound />, { ref: this.currentViewRef, viewKey: selectedPage })}</ErrorBoundary>}
                            {this.checkPrivilege("newsfeed", "newsfeed", "0") && <TeamChat ref={this.refTeamChat} onUnreadCount={this.onUnreadCount} />}
                            {<Notifications ref={this.refNotifications} updateFrequency={5000} onUnseenCount={this.onUnseenCount} {...props} />}
                            {<PersonalNote ref={this.refPersonalNote} {...props} />}
                        </div>}
                        {buyTaimerOpen && (
                            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                                <Elements locale={this.settings.userObject.language}>
                                    <BuyTaimer {...props} addon={this.state.buyTaimerAddon} />
                                </Elements>
                            </StripeProvider>
                        )}
                        <Dialog
                            data-testid="unsaved-warning-dialog"
                            open={this.state.unsavedWarningOpen}
                            onClose={this.dirtyCancel}>
                            <DialogTitle><WarningIcon style={{ marginRight: 16, color: '#ffcf5c' }} /> {unsavedDialogData.title || this.tr("Are you sure you want to exit?")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {unsavedDialogData.text || this.tr("Your changes won't be saved unless all mandatory fields are filled.")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button data-testid={"dirty_button_cancel"} variant="outlined" onClick={this.dirtyCancel} color="primary">
                                    {unsavedDialogData.cancelText || this.tr("Cancel")}
                                </Button>
                                <Button data-testid={"dirty_button_leave"} onClick={this.dirtyAccept} color="primary">
                                    {this.tr('Leave')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {this.renderGdprDialogs()}
                        {sliders?.length > 0 && sliders}
                        {dialog && dialog}
                        <AttachmentInput 
                            ref={this.attachmentUploadInput}
                            notifier={{
                                notify:        (msg) => this.currentViewRef.current.props.enqueueSnackbar(msg),
                                notifyError:   (msg) => this.currentViewRef.current.props.enqueueSnackbar(msg, { variant: "error" }),
                                notifySuccess: (msg) => this.currentViewRef.current.props.enqueueSnackbar(msg, { variant: "success" }),
                            }}
                        />
                        <AttachmentInput ref={this.attachmentFileSelectorInput} />
                        {overlayComponent && 
                            <div className="taimer-overlay">{overlayComponent}</div>
                        }
                        </SnackbarProvider>
                    </SettingsContext.Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default Taimer;
