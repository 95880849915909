import getAccountColumns from './AccountColumns';
import getColumnType, { ColumnDefinition } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import getProjectColumns from './ProjectColumns';
import getUserColumns from './UserColumns';
import { filterColumns } from "./ColumnVisibility";

interface Params {
    customFields: CustomFields;
    netvisorActive: boolean;
    procountorActive: boolean;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    tr: (text, replacers?: Record<string, string>) => string;
    settingsContext: any;
}

export default function getCostsColumns(params: Params) {
    const columns: ColumnDefinition[] = [     
        {
            field: 'row_id',
            headerName: 'Row ID',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'entity_number',
            headerName: 'Number',
            sort: 'desc',
            sortIndex: 2,
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=$(module)&action=$(action)&id=$(id)$(extraQuery)"
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'cost_type',
            headerName: 'Cost type',
            sort: 'desc',
            sortIndex: 0,
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 1, "name": "Expense"},
                    {id: 2, "name": "Travel expense"},
                    {id: 3, "name": "Purchase invoice"},
                    {id: 4, "name": "Manually added cost"},
                ],
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'row_type',
            headerName: 'Row type',
            sort: 'desc',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 'daily_allowance', "name": "Daily Allowance"},
                    {id: 'additional_allowance', "name": "Additional Allowance"},
                    {id: 'other_allowance', "name": "Other Expense"},
                    {id: 'mileage_allowance', "name": "Mileage Allowance"},
                    {id: 'bill_project_row', "name": "Purchase Invoice Row"},
                    {id: 'bill_no_project_row', "name": "Purchase Invoice Row"},
                    {id: 'expense_row', "name": "Other Expense"},
                    {id: 'manual_cost_row', "name": "Manually added cost"},
                ],
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'state',
            headerName: 'Status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: 'waiting', "name": "waiting", "color": "#ffb822"},
                    {id: 'approved', "name": "approved", "color": "#20C6A1"},
                    {id: 'rejected', "name": "Rejected", "color": "#f7548f"},
                    {id: 'pre_approved', "name": "Pre-approved", "color": "#979797"},
                    {id: 'archived', "name": "Archived", "color": "#716aca"},
                    {id: 'to_payment', "name": "To payment", "color": "#2d9ff7"},
                    {id: 'draft', "name": "Draft", "color": "#6B7897"},
                ],
                tr: params.tr
            }
        },
        {
            field: 'creationdate',
            headerName: 'Creation date',
            sort: 'desc',
            sortIndex: 1,
            ...getColumnType('date', null, 'creationdate'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'payment_type',
            headerName: 'Payment type',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'allowance_type',
            headerName: 'Allowance',
            ...getColumnType('text'),
        },
        {
            field: 'expense_types',
            headerName: 'Expense types',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'description',
            headerName: 'Description',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'row_description',
            headerName: 'Row Description',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'vat_amount',
            headerName: 'VAT',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'total_without_vat',
            headerName: 'Cost total (vat 0%)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'total_with_vat',
            headerName: 'Cost total',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'selling_price',
            headerName: 'Selling Price (vat 0%)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'billed',
            headerName: 'Billed',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "No"},
                    {id: 1, "name": "Yes"},
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'billable',
            headerName: 'Billable',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "No"},
                    {id: 1, "name": "Yes"},
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'billed_price',
            headerName: 'Billed price',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'cost_date',
            headerName: 'Cost date',
            ...getColumnType('date', null, 'cost_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'startdate',
            headerName: 'Start date',
            ...getColumnType('date', null, 'startdate'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'starttime',
            headerName: 'Start time',
            ...getColumnType('time'),
        },
        {
            field: 'enddate',
            headerName: 'End date',
            ...getColumnType('date', null, 'enddate'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'endtime',
            headerName: 'End time',
            ...getColumnType('time'),
        },
        {
            field: 'route',
            headerName: 'Route',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'mileage',
            headerName: 'Mileage',
            ...getColumnType('number'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: "km",
            ignoreFromAggregateType: "string"
        },
        {
            field: 'days',
            headerName: 'Days',
            ...getColumnType('number'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: "d"
        },
        {
            field: 'due_date',
            headerName: 'Due date',
            ...getColumnType('date', null, 'due_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'currency',
            headerName: 'Currency',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'currency_rate',
            headerName: 'Currency rate',
            ...getColumnType('number'),
            decimalAmount: 6,
            grandTotal: 'none',
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'total_in_currency',
            headerName: 'Total in currency',
            ...getColumnType('currency'),
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            showEmptyAggSum: true,
            noCurrencyConvert: true
        },
        {
            field: 'buyer_reference',
            headerName: 'Buyer Reference',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'reference_nr',
            headerName: 'Reference nr.',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'bill',
            headerName: 'Invoice',
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=invoices&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'synced_po',
            headerName: 'Synced PO',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=purchaseorder&action=view&id=$(id)&companies_id=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=customers&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'company',
            headerName: 'Project company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'creator_company',
            headerName: 'Creator Company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            chartDataType: 'category',
            field: 'date_year',
            headerName: 'Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'date_month',
            headerName: 'Month',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'date_quarter',
            headerName: 'quarter',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            chartDataType: 'category',
            field: 'date_fy',
            headerName: 'Financial Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'assigned_to',
            headerName: 'Assigned To',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'approved_by',
            headerName: 'Approved by',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'voucher_date',
            headerName: 'Accounting date',
            ...getColumnType('date', null, 'voucher_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'voucher_date_year',
            headerName: 'Accounting date year',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'voucher_date_month',
            headerName: 'Accounting date month',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'voucher_date_quarter',
            headerName: 'Accounting date quarter',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
        ...getUserColumns('created_by', params),
    ];

    if (params.procountorActive) {
         columns.push({
            field: 'procountor_id',
            headerName: 'In Procountor',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "No"},
                    {id: 1, "name": "Yes"},
                ],
                tr: params.tr
            },   
            defaultAggFunc: 'sumAndGroup',     
        });
    }

    if (params.netvisorActive) {
        columns.push({
            field: 'netvisor_id',
            headerName: 'In Netvisor',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "No"},
                    {id: 1, "name": "Yes"},
                ],
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        });
    }
    
    return filterColumns(
        columns, 
        "costs", 
        params.settingsContext, 
        "created_by"
    );
}

            
