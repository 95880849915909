import getColumnType, { ColumnDefinition, isGroupedParent, sumAndGroup, columnSorter } from './ColumnTypes';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";
import { languages } from 'country-data';

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
}

export default function getContactColumns(params: Params) {
    // En tiiä mikä nämä on määrittänyt otin yhteystietokortilta.
    const taimerLangs = [
        'fin',
        'swe',
        'nor',
        'dan',
        'icl',
        'est',
        'lit',
        'lav',
        'eng',
        'deu',
        'ita',
        'fra',
        'spa',
        'por',
        'rus',
        'pol',
        'bul',
        'hrv',
        'ces',
        'ell',
        'hun',
        'gle',
        'mlt',
        'ron',
        'slk',
        'slv',
        'zho',
        'jpn',
        'nld',
        'ara',
        'hin',
        'pan',
        'ind',
    ];
    const languageOptions: { id: string; name: string; }[] = Object.keys(languages).map((key) => ({
        id: key,
        name: params.tr(languages[key].name)
    })).filter(lang => {
        return taimerLangs.indexOf(lang.id) > -1;
    });

    const columnOrder = [
        "firstname",
        "lastname",
        "account",
        ...getAccountColumns(params).map(f => f.field).filter(f => f !== "account"),
        "company",
        "title",
        "position",
        "contact_type",
        "owner",
        "mailing_lists",
        "email",
        "phone",
        "street_address",
        "zip_code",
        "city",
        "country",
        "language",
        "tags",
        "created_by",
        "last_edited_by",
        "last_edited_on",
        "primary_relation",
        "last_activity_date",
    ];

    const columns: ColumnDefinition[] = [    
        {
            field: 'firstname',
            headerName: 'First name',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=contact&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'read', r.relation_company_id),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'lastname',
            headerName: 'Last name',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=contact&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'read', r.relation_company_id),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
        },
        {
            field: 'title',
            headerName: 'Title',
            ...getColumnType('text'),
        },
        {
            field: 'position',
            headerName: 'Position',
            ...getColumnType('text'),
        },
        {
            field: 'contact_type',
            headerName: 'Contact type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    { id: 1, "name": "Customer" },
                    { id: 2, "name": "Partner" },
                ],
                tr: params.tr
            } 
        },
        {
            field: 'owner',
            headerName: 'Owner',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.owner_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'mailing_lists',
            headerName: 'Mailing lists',
            ...getColumnType('multi'),
        },
        {
            field: 'email',
            headerName: 'Email',
            ...getColumnType('emailLink'),
        },
        {
            field: 'phone',
            headerName: 'Phone',
            ...getColumnType('text'),
        },
        {
            field: 'street_address',
            headerName: 'Address',
            ...getColumnType('text'),
        },
        {
            field: 'zip_code',
            headerName: 'Zip code',
            ...getColumnType('text'),
        },
        {
            field: 'city',
            headerName: 'City',
            ...getColumnType('text'),
        },
        {
            field: 'country',
            headerName: 'Country',
            ...getColumnType('text'),
        },
        {
            field: 'language',
            headerName: 'Language',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas: [
                    ...languageOptions,
                    {
                        id: "",
                        name: ""
                    }
                ]
            }
        },
        {
            field: 'tags',
            headerName: 'Tags',
            ...getColumnType('multi'),
        },
        // Not saved yet.
        // {
            // field: 'created_by',
            // headerName: 'Created by',
            // ...getColumnType('link'),
        // },
        {
            field: 'edited_by',
            headerName: 'Last edited by',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.last_editor_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'last_edited_on',
            headerName: 'Last edited on',
            ...getColumnType('date', null, 'last_edited_on'),
        },
        {
            field: 'primary_relation',
            headerName: 'Primary account',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    { id: 0, name: "No" },
                    { id: 1, name: "Yes" },
                ],
                tr: params.tr
            } 
        },
        {
            field: 'last_activity_date',
            headerName: 'Last activity date',
            ...getColumnType('date', null, 'last_activity_date'),
        },
        ...getAccountColumns(params),
    ];

    columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "contacts", 
        params.settingsContext
    );
}
