import React from 'react';

import _ from 'lodash';
import TabBasic from './TabBasic';
import PropTypes from 'prop-types';
import TabActivities from './../general/TabActivities';
import { withSnackbar } from 'notistack';
import TaimerComponent from '../TaimerComponent';
import TabMessenger from './TabMessenger';
import TabFinance from './TabFinance';
import TabAttachments from './../general/TabAttachments';
import TabCollaborate from './TabCollaborate';
import TabQuotes from './TabQuotes';
import TabResourcing from './TabResourcing';
import TabForms from './TabForms';
import TabMemo from './TabMemo';
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import { DatePicker } from '../general/react-date-range/src';
import { getWorktypes } from '../Data';
import ProfitLoss from '../charts/ProfitLoss';
import ConfirmationDialog from './../list/dialogs/ConfirmationDialog';
import MassDialog from '../dialogs/mass_operations/CoreDialog';
import SetAsSubProjectDialog from '../dialogs/SetAsSubProjectDialog';
import ResourceDialog from '../dialogs/ResourceDialog';
import CopyProjectDialog from '../dialogs/CopyProjectDialog';
import { MenuItem, Tooltip } from '@mui/material';
import DataHandler from './../general/DataHandler';
import { AddBusiness, Archive, CopyAll, Folder, SubdirectoryArrowRight, VpnKey } from '@mui/icons-material';
import Utils from './../general/Utils.js';
import { format } from 'date-fns';
import List from './../list/List';
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';
import NoteDrawer from '../general/NoteDrawer';
import moment from 'moment';
import HourInsights from '../dashboard/insights/hours/HoursInsight';
import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
import { ReactComponent as MovePipelineIcon } from './images/move_pipeline.svg';
import { ReactComponent as MoveProjectIcon } from './images/move_project.svg';
import { ReactComponent as MoveWonDealIcon } from './images/won_deal.svg';
import { ReactComponent as OnHoldIcon } from './images/on_hold.svg';
import CloseDialogContent from './CloseDialogContent';

import ProjectStatistics from './ProjectStatistics';

/* css */
import './ProjectView.css';
import TabSales from './TabSales';
import ProjectStatusReasonDialog from '../dialogs/ProjectStatusReasonDialog';
import EmptyInvoiceConfirmationDialog from '../invoices/dialogs/EmptyInvoiceConfirmationDialog';

import WithTabs from '../navigation/WithTabs';
import BillsPoList from '../bills/BillsPoList';
import ExpenseList from '../expenses/ExpenseList';
import HoursReportSlider from './HoursReportSlider';
import InvoicingInsight from '../dashboard/insights/invoicing/InvoicingInsight';

import cardStyles from '../general/styles/CardStyles.module.scss';
import SpecialPermissionSlider from '../general/SpecialPermissionSlider';
import ActionIcons from '../navigation/ActionIcons';
import VersionContentManager from '../general/VersionContentManager';
import QuoteView from './QuoteView';

class ProjectView extends TaimerComponent {
    static defaultProps = { showLockedUsersWithTag: true };
    backButton = {
        visible: true,
        fallbackLocation: { module: 'projects', action: 'list' },
    };

    customFieldsVisible = false;
    snackbarKey = false;

    constructor(props, context) {
        super(props, context, 'projects/ProjectView');

        this.tabs = this.formatTabs(VersionContentManager.getViewTabs(this.namespace));

        this.dialogs = {
            confirmation: ConfirmationDialog,
            copyProject: CopyProjectDialog,
            delete: MassDialog,
            subproject: SetAsSubProjectDialog,
            emptyInvoice: EmptyInvoiceConfirmationDialog,
        };

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };

        this.tabSales = React.createRef();
        this.noteDrawer = React.createRef();
        this.tabQuotes = React.createRef();

        this.deletableReasons = {
            projects: this.tr("deletable_reason_projects"),
            workhours: this.tr("deletable_reason_workhours"),
            projectentries: this.tr("deletable_reason_projectentries"),
            attachments: this.tr("deletable_reason_attachments"),
            crm: this.tr("deletable_reason_crm"),
            invoices: this.tr("deletable_reason_invoices"),
            projects_resources: this.tr("deletable_reason_projects_resources"),
            traveling_expenses: this.tr("deletable_reason_traveling_expenses"),
            expenses: this.tr("deletable_reason_expenses"),
            resources: this.tr("deletable_reason_resources"),
            deadlines: this.tr("deletable_reason_deadlines"),
            contractbilling: this.tr("deletable_reason_contractbilling"),
            subcontracts: this.tr("deletable_reason_subcontracts"),
            bills: this.tr("deletable_reason_bills"),
            sales_quotes: this.tr("deletable_reason_sales_quotes"),
        };

        this.state = {
            project: {
                name: '',
                account: {},
                status: '0',
                locked: '-1',
                projectNumberExists: this.context.addons.custom_project_id ? false : undefined,
                project_id: this.context.addons.custom_project_id ? '' : 'unsaved',
                parentid: this.props.parentid ? this.props.parentid : 0,
                closing_date: format(new Date().setDate(new Date().getDate() + 30), 'YYYY-MM-DD'),
                startdate: format(new Date(), 'YYYY-MM-DD'),
                enddate: format(new Date().setDate(new Date().getDate() + 30), 'YYYY-MM-DD'),
                statelog: [],
                dialogOpen: false,
                has_forms: false,
                has_tasks: false,
                type: '2',
                next_activity: {},
                invoicing: {
                    invoiced: 0,
                    paid: 0,
                    billentry_id: 0,
                },
                projects_sales_states_id: 0,
                only_selected_worktasks: 0,
                costest_sum: 0,
                costest_margin: 0,
                tags: [],
                project_hours: [],
                team_members: [],
                contacts: [],
                activities: {},
                invoicing_address: {},
                types: [],
                delivery_address: {},
                custom: {},
                rights: [],
                status_users_id: this.context.userObject.usersId,
                companies_id: this.props.companies_id ? context.functions.getCompany('projects', 'write', this.props.companies_id) : context.functions.getCompany('projects', 'write'),
            },
            companies: [],
            categoryCount: 0,
            projects_sales_states: [],
            pipelines: [],
            selectedTab: this.props.selectedTab || 'home',
            employees: [],
            sales_users: [],
            worktypes: [],
            customFields: [],
            customFieldsErrors: {},
            formErrors: {},
            deletable: false,
            hoursReportOpen: false,
            datesShown: false,
            showProjectNameInHeader: (this.props.selectedSubTab || 'overview') != 'overview',
            projectLimitReached: false,
            resourcingAutoCompleteData: undefined,
            specialPermissionSliderOpen: false,
        };
    }

    formatTabs = (tabs) => {
        if (!!tabs && this.context.addons?.nav) {
            tabs = tabs.filter(t => t.id != "finances_2");
        }
        return tabs ? tabs.map(this.formatTab) : undefined;
    };

    formatTab = (tab) => {
        return { ...tab, label: this.tr(tab.label), getDestination: this.getDestinationForTab, onClick: this[tab.onClick], items: this.formatTabs(tab.items) };
    };

    getDestinationForTab = (tab) => {
        const { project } = this.state;
        const reportProjectParams = {
            projects_id: project.id,
            projects_project_id: project.project_id,
            projects_name: project.name,
            preset_company: project.companies_id,
        };
        switch (tab.id) {
            case 'employees_report':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'employees_report',
                    selectedTab: 'hourReports',
                    ...reportProjectParams,
                };
            case 'employees_daily':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'employees_daily',
                    selectedTab: 'hourReports',
                    ...reportProjectParams,
                };
            case 'invoicing_vs_subcontracting':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'invoicing_vs_subcontracting',
                    selectedTab: 'invoicing',
                    ...reportProjectParams,
                };
            case 'invoicing_vs_subcontracting_bills':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'invoicing_vs_subcontracting_bills',
                    selectedTab: 'invoicing',
                    ...reportProjectParams,
                };
            case 'revenue_recognition_by_account':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'revenue_recognition_by_account',
                    selectedTab: 'recognitionReports',
                    ...reportProjectParams,
                };
            case 'revenue_recognition_by_user':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'revenue_recognition_by_user',
                    selectedTab: 'recognitionReports',
                    ...reportProjectParams,
                };
            case 'revenue_recognition_by_user_and_project':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'revenue_recognition_by_user_and_project',
                    selectedTab: 'recognitionReports',
                    ...reportProjectParams,
                };
            default:
                return undefined;
        }
    };

    getHeaderTitles = () => {
        const noProjectName = !this.state?.project?.name || !this.state.showProjectNameInHeader;
        return !this.props.id
            ? [this.tr('Add project')]
            : noProjectName
            ? [this.tr('Project')]
            : [
                  {
                      label: `${this.state?.project?.name} (${this.state?.project?.project_id})`,
                      sublabel: { label: this.state?.project?.account?.name, url: { module: 'customers', action: 'view', id: this.state?.project?.account?.id } },
                  },
              ];
    };

    changeTab = (selectedTab, extra = {}) => {
        if (this.state.selectedTab != 'sales') this.validateForms();

        const { id, updateView } = this.props;

        updateView(
            {
                id: id,
                selectedTab,
                ...extra,
            },
            false,
            undefined,
            undefined,
            true
        );
    };

    updateProjectRights = () => {
        const { id } = this.props;
        this.updateComponentData();
        DataHandler.get({ url: `projects/${id}/rights` }).done((rights) => {
            const project = _.cloneDeep(this.state.project);
            project.rights = rights;
            this.setState({ project });
        });
    };

    updateStates = (pipeline_id = false) => {
        const { project } = this.state;
        const selectedPipeline = pipeline_id > 0 ? pipeline_id : project.projects_pipelines_id;
        const handler = (data) => {
            const update = { projects_sales_states: data };

            if (data.length && data.find((e) => e && e.id === project.projects_sales_states_id) === undefined) {
                const { project: currentProject } = this.state;
                update.project = { ...currentProject, projects_sales_states_id: data[0].id };
            }

            this.setState(update, this.validateForms);
        };
        if (project.status === '0') DataHandler.get({ url: `subjects/sales_states/${selectedPipeline}` }).done(handler);
        else if (project.status === '1') DataHandler.get({ url: `subjects/project_states/${project.companies_id}` }).done(handler);
        else if (project.status === '5') DataHandler.get({ url: `subjects/internal_states/${project.companies_id}` }).done(handler);

        this.validateForms();

        if (this.props.copyId > 0) {
            const interval = Math.round(Math.abs(new Date(project.enddate).getTime() - new Date(project.startdate).getTime()) / (1000 * 60 * 60 * 24));
            const proj = {
                id: undefined,
                name: '',
                invoicing: { invoiced: 0, due: 0 },
                project_hours: [],
                activities: {},
                next_activity: {},
                stateLog: [],
                locked: '-1',
                maxhours: '',
                status_date: format(new Date(), 'YYYY-MM-DD'),
                startdate: format(new Date(), 'YYYY-MM-DD'),
                enddate: format(new Date().setDate(new Date().getDate() + interval), 'YYYY-MM-DD'),
            };
            const update = Object.assign({}, this.state.project, proj);
            this.setState({ project: update });
        }
        this.setUrlPipeline();
    };

    setUrlPipeline = () => {
        const { project } = this.state;
        let urlPipeline;
        if (Number(project.status) > 0) urlPipeline = project.status * -1;
        else {
            urlPipeline = project.projects_pipelines_id;
            const pipelines = [...this.state.pipelines];

            // Check if pipeline is found when adding new project (when company is changed pipeline is not found)
            if (!project.id && pipelines && pipelines.length > 0) {
                let pipelineFound = false;

                pipelines.forEach(function (item) {
                    if (item.id == project.projects_pipelines_id) {
                        pipelineFound = true;
                    }
                });
                urlPipeline = pipelineFound ? project.projects_pipelines_id : pipelines[0].id;
            }
        }

        if (urlPipeline == 0 && this.props.pipeline_id) {
            urlPipeline = this.props.pipeline_id;
        }

        this.setState((state) => {
            state.project = { ...state.project, projects_pipelines_id: urlPipeline };
            return state;
        });
        this.props.updateView({ id: this.state.project.id, pipeline_id: urlPipeline, replace: true }, false, undefined, undefined, true);
    };

    updateComponentData = () => {
        const { id, copyId } = this.props;
        const { project } = this.state;

        this.updateCompanyData();

        /* get project data */
        const realId = copyId > 0 ? copyId : id || 0;
        const url = realId > 0 ? `projects/${realId}` : `projects/new/${project.companies_id}`;

        DataHandler.get({ url, account: this.props.customers_id })
            .done(async (data) => {
                data.companies_id !== project.companies_id && this.updateCompanyData(data.companies_id);
                /* If adding new project set status as it was in the list */
                if ((this.props.pipeline_id || this.props.pipeline_id == '0') && realId == 0) {
                    this.setNewProjectStatus(data);
                } else {
                    const update = Object.assign({}, this.state.project, data);
                    this.setState({ project: update }, () => {
                        this.updateStates();
                    });

                    /* this will update state directly and it is forbidden */
                    //                this.setState( state => Object.keys(data).forEach(e => state.project[e] = data[e]) || state, this.updateStates );
                }

                getWorktypes(data.companies_id).then((worktypes) => {
                    this.setState({ worktypes });
                });

                let dontIgnoreCompany = false;
                if (data.limited_project_team == 1) dontIgnoreCompany = true;

                const employees = await DataHandler.request('GET', { url: `subjects/employees/${data.companies_id}`, dontIgnoreCompany: dontIgnoreCompany });
                const sales_users = await DataHandler.request('GET', { url: `projects/${realId}/employees/read`});
                
                const userTagProps = {
                    fields: { label: 'label' },
                    showLocked: this.props.showLockedUsersWithTag,
                    transl: this.translations,
                };
                employees.forEach((p, i) => {
                    employees[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
                });

                sales_users.forEach((p, i) => {
                    sales_users[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
                });

                this.setState({ employees, sales_users });

                //getOneProjectType
                DataHandler.get({ url: `settings/company/${data.companies_id}/project/oneprojecttype` })
                    .done((response) => {
                        this.setState({ oneprojecttype: response.oneprojecttype });
                    })
                    .fail((err) => console.log(err));
            })
            .fail(() => {
                this.setState({ error: 'no_permission' });
            });
    };

    setNewProjectStatus = (data) => {
        let status = 0;

        if (data.status == '1' && this.props.pipeline_id != '-5') status = '1';
        else if (data.status == '5' && this.props.pipeline_id != '-1') status = '5';
        else if (Number(this.props.pipeline_id) > 0) status = '0';
        else status = this.props.pipeline_id * -1 + '';

        this.setState(
            (state) =>
                Object.keys(data).forEach((e) => {
                    if (e == 'status') state.project[e] = status;
                    else state.project[e] = data[e];
                }) || state,
            () => this.updateStates(this.props.pipeline_id)
        );
    };

    updateCompanyData = (company) => {
        const { id } = this.props;
        const { project, updateData } = this.state;

        company = company || project.companies_id;

        DataHandler.get({ url: `subjects/pipelines/${company}` }).done((pipelines) => this.setState({ pipelines }));

        DataHandler.get({ url: `settings/company/${company}/project/customfields`, include_deleted: id || 0 }).done((data) => {
            this.setState({ customFields: data });
        });
    };

    // Custom project number add-on.
    updateProjectNumber = (projectNumber) => {
        this.setState({
            project: {
                ...this.state.project,
                project_id: projectNumber,
            },
        });
    };

    componentDidMount = () => {
        super.componentDidMount();
        this.updateComponentData();

        this.getProjectLimitStatus();
        this.props.customers_id &&
            DataHandler.get({ url: `accounts/name/${this.props.customers_id}` }).done((data) => {
                this.setState((state) => (state.project.account = JSON.parse(data)));
            });
        this.checkDeletable();

        window.addEventListener('workhourSaved', this.updateComponentData);
        window.addEventListener('newContactCreated', this.updateComponentData);
        // window.addEventListener('activitySaved', () => this.updateActivityStats(true));
        window.addEventListener('projectValueUpdated', this.updateComponentData);
    };

    componentDidUpdate = (prevProps) => {
        if ((this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) || (this.props.selectedSubTab && prevProps.selectedSubTab !== this.props.selectedSubTab)) {
            this.setState({ selectedTab: this.props.selectedTab, showProjectNameInHeader: (this.props.selectedSubTab || 'overview') != 'overview' }, () => {
                this.context.functions.setOverrideHeaderTitles(undefined);
            });
        }
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('workhourSaved', this.updateComponentData);
        window.removeEventListener('newContactCreated', this.updateComponentData);
        // window.removeEventListener('activitySaved', this.updateActivityStats);
        window.removeEventListener('projectValueUpdated', this.updateComponentData);

        if (this.snackbarKey) this.props.closeSnackbar(this.snackbarKey);
    };

    getProjectLimitStatus = async () => {
        const projectLimitReached = await this.isProjectLimitReached();
        this.setState({ projectLimitReached });
    };

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    };

    openDialogWithData = (name, dialogData = {}) => {
        this.setState({ currentDialog: name , dialogData});
    }

    closeDialog = (data) => {
        if (this.state.currentDialog == 'copyProject' && data) {
            this.props.updateView({ id: data[this.state.project.id].id });
        } else {
            this.setState({ currentDialog: false, dialogData: undefined });
        }
    };

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    openDeleteDialog = () => {
        const { project } = this.state;
        this.setState(
            {
                dialogData: {
                    saveFunc: () => {
                        this.deleteProject();
                    },
                    deleteDisabled: !this.state.deletable,
                    header: this.tr('Delete project') + '?',
                    confirmButtonText: this.tr('Delete').toUpperCase(),
                    text: this.state.deletable
                        ? this.tr('Are you sure you want to delete project') + ': ' + project.name + ' (' + project.project_id + ')?'
                        : this.state.deletableReason,
                },
            },
            () => this.openDialog('delete')
        );
    };

    openArchiveDialog = (hasSubProjects, data, showReasonDialog = false) => {
        const { project } = this.state;

        let text;
        if (data.hasUninvoiced)
            text =
                this.tr('Are you sure you want to archive project') +
                ': ' +
                project.name +
                ' (' +
                project.project_id +
                ')' +
                (data.hasUninvoiced ? ', ' + this.tr('because it has uninvoiced rows?') : '?');
                
        else if (hasSubProjects) text = <CloseDialogContent data={data} />;
        if (project.status == 0 && project.locked == -1) {
            text = this.tr('Are you sure you want to mark the following project as lost') + ': ' + project.name + ' (' + project.project_id + ')' + '?';       
        }
        else text = this.tr('Are you sure you want to archive project') + ': ' + project.name + ' (' + project.project_id + ')' + '?';

        this.setState(
            {
                id: project.id,
                dialogData: {
                    saveFunc: (id) => {
                        if (showReasonDialog) {
                            this.context.functions.showDialogContent(
                                <ProjectStatusReasonDialog
                                    type="lost"
                                    project={project}
                                    onSave={(reason, status_reason_comment) => {
                                        const reasonData = { status_reason_id: reason.id, status_reason_name: reason.name, status_reason_comment };
                                        if (!hasSubProjects) this.projectDataChanged({ locked: '1', status_date: new Date(), ...reasonData });
                                        else this.closeProject(data.all_projects, reasonData);
                                    }}
                                />
                            );
                        } else {
                            if (!hasSubProjects) this.projectDataChanged({ locked: '1', status_date: new Date() });
                            else this.closeProject(data.all_projects);
                        }
                    },
                    deleteDisabled: false,
                    confirmButtonText: project.status == 0 && project.locked == -1 ? this.tr('Mark as lost').toUpperCase() : this.tr('Archive').toUpperCase(),
                    header: project.status == 0 && project.locked == -1 ? this.tr('Mark as lost') + '?' : this.tr('Archive project') + '?',
                    text,
                },
            },
            () => this.openDialog('delete')
        );
    };

    deleteProject = () => {
        if (this.state.deletable === false) {
            this.props.enqueueSnackbar(this.state.deletableReason, {
                variant: 'error',
            });
            return;
        }

        const { updateView, enqueueSnackbar } = this.props;
        const { project } = this.state;
        let urlPipeline;
        if (Number(project.status) > 0) urlPipeline = project.status * -1;
        else urlPipeline = project.projects_pipelines_id;

        DataHandler.post({ url: 'projects/' + project.id + '/delete' })
            .done(() => {
                this.context.functions.setDirty(false);
                updateView({ module: 'projects', action: 'list', pipeline_id: urlPipeline, companies_id: project.companies_id });
                this.updateLimitInfo();
                enqueueSnackbar(this.tr('Project deleted successfully') + '!', {
                    variant: 'success',
                });
            })
            .catch((err) => {
                let msg = this.tr('Error in deleting project');
                if (err?.responseJSON?.error) {
                    msg += ': ' + this.tr(err.responseJSON.error);
                }
                enqueueSnackbar(msg, {
                    variant: 'error',
                });
            });
    };

    closeProject = (projects, reasonData) => {
        projects.forEach((p, i) => {
            p.locked = 1;
            p.status_date = new Date();
            projects[i] = {
                ...p,
                ...reasonData,
            };
        });

        DataHandler.put({ url: `projects/mass` }, { projects }).done((response) => {
            setTimeout(() => {
                this.updateComponentData();
                if (this.tabQuotes.current) {
                    this.tabQuotes.current.getQuotes();
                }
            }, 1000);
        });

        this.closeDialog();
    };

    updateLimitInfo = () => {
        if (this.context.addons && this.context.addons.projects && this.context.addons.projects.limit) {
            this.context.functions.whoami();
        }
    };

    projectDataChanged = (update, delay = false, showSnackbar = false) => {
        const { id } = this.props;
        const { project, oneprojecttype } = this.state;

        if (oneprojecttype && update.types && update.types.length > 1) {
            this.props.enqueueSnackbar(this.tr(`one project type enabled`), {
                variant: 'error',
            });
            return;
        }

        if (!id) {
            delay = false;
            this.context.functions.setDirty(true, this.dirtyHandler);
        }

        if (!update.status_date && ((update.status && !['1', '5'].includes(update.status)) || update.locked)) {
            update.status_date = format(new Date(), 'YYYY-MM-DD');
        }

        //pipeline change, mukaanlukien won deals & internal
        if (update.projects_pipelines_id || ['1', '5'].includes(update.status)) {
            update.projects_pipelines_date = update.projects_pipelines_date || format(new Date(), 'YYYY-MM-DD');
            update.state_changed = update.state_changed || format(new Date(), 'YYYY-MM-DD');
        }

        if (update.sales_user && !Array.isArray(update.sales_user) && !this.state.project.team_members.find((member) => member.id == update.sales_user.id)) {
            update.team_members = this.state.project.team_members.concat(update.sales_user);
        }

        this.save({ id, ...update }, delay ? update : undefined, showSnackbar);
        delete update.use_single_account;

        if (!delay) {
            if (update.custom) {
                const formErrors = {
                    ...this.state.formErrors,
                };

                for (const key in update.custom) {
                    if (update.custom.hasOwnProperty(key) && formErrors[key]) {
                        delete formErrors[key];
                    }
                }

                update.custom = { ...project.custom, ...update.custom };

                this.setState({ formErrors });
            }

            this.setState(
                (state) => Object.keys(update).forEach((e) => (state.project[e] = update[e])) || state,
                () => {
                    (update.projects_pipelines_id || update.status) && this.updateStates();
                    this.validateForms();
                    if (update.locked == 1 && this.tabQuotes.current) {
                        setTimeout(() => {
                            this.tabQuotes.current.getQuotes();
                        }, 1000);
                    }
                }
            );
        }
    };

    save = (update, stateUpdate = undefined, showSnackbar = false) => {
        const { enqueueSnackbar } = this.props;

        return (
            update.id &&
            DataHandler.put({ url: 'projects/' + update.id }, update)
                .done((response) => {
                    setTimeout(() => {
                        this.updateComponentData();

                        window.dispatchEvent(new CustomEvent("projectSaved", {
                            id: update.id,
                            update,
                        }));
                    }, 1000);
                    const keys = Object.keys(response);
                    keys.length && this.setState((state) => keys.forEach((e) => (state.project[e] = response[e])) || state);
                    this.validateForms();
                    if (showSnackbar) {
                        enqueueSnackbar(this.tr('Changes saved!'), {
                            variant: 'success',
                        });
                    }
                    if (stateUpdate !== undefined) {
                        if (stateUpdate.sales_user && this.tabSales.current) {
                            setTimeout(() => {
                                this.tabSales.current.updateRevenueRecognition();
                            }, 1000);
                        }
                        this.setState(
                            (state) => Object.keys(stateUpdate).forEach((e) => (state.project[e] = stateUpdate[e])) || state,
                            () => {
                                (stateUpdate.projects_pipelines_id || stateUpdate.status) && this.updateStates();
                                this.validateForms();
                            }
                        );
                    }
                })
                .fail((error) => {
                    const resp = error.responseJSON;

                    if (resp && resp.error === 'invalid_state') {
                        enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(', ')}`, {
                            variant: 'error',
                        });
                        resp.data.error = false;
                        if (resp.form) {
                            this.changeTab('forms', { form: resp.form });
                        }

                        this.setState({ formErrors: resp.formErrors }, this.validateForms);
                    } else if (resp && resp.error === 'parent_locked') {
                        enqueueSnackbar(this.tr(`Cannot change status: Parent is locked`), {
                            variant: 'error',
                        });
                        return;
                    } else if (resp && resp.error === 'CUSTOMER_NOT_ALLOWED') {
                        enqueueSnackbar(this.tr(`Cannot create project for this customer`), {
                            variant: 'error',
                        });
                        return;
                    } else if (resp && resp.error === 'EXISTING_PROJECT_ID') {
                        this.showProjectNumberExistsNotification();
                        return;
                    }

                    if (resp && resp.data) {
                        this.setState((state) => (state.project = { ...state.project, ...resp.data }));
                    }
                })
        );
    };

    isLeaving = false;

    validateForms = (detailsOnly = false, showSnackbar = false) => {
        const { id, enqueueSnackbar } = this.props;

        if (this.isLeaving) return;

        const {
            customFields,
            project: { status, projects_pipelines_id, projects_sales_states_id, custom },
            projects_sales_states,
        } = this.state;

        const currentSalesSate = projects_sales_states.find((x) => x.id == projects_sales_states_id);

        if (!currentSalesSate) return;

        const prevSaleState = currentSalesSate && projects_sales_states.find((x) => x.stateorder == parseInt(currentSalesSate.stateorder) - 1);
        const nextSaleState = currentSalesSate && projects_sales_states.find((x) => x.stateorder == parseInt(currentSalesSate.stateorder) + 1);

        const statesToValidate = !!id || currentSalesSate.stateorder > 1 ? projects_sales_states.filter((x) => x.stateorder <= parseInt(currentSalesSate.stateorder)).map((x) => parseInt(x.id)) : [];

        const pipelines = [0, projects_pipelines_id > 0 ? parseInt(projects_pipelines_id) : status * -1];

        const formFields = customFields.filter(
            (x) => (statesToValidate.indexOf(x.projects_sales_states_id) > -1 || x.projects_sales_states_id === 0) && pipelines.indexOf(x.projects_pipelines_id) > -1
        );

        let hasErrors = false;
        const customFieldsErrors = {};
        const errorFields = [];
        let formId = 0;

        _.forEach(formFields, (v) => {
            const value = (custom && custom[v.id]) || '';

            if (v.required && value === '' && (!detailsOnly || v.show_in_details)) {
                customFieldsErrors[v.id] = true;
                hasErrors = true;
                errorFields.push(v.name);

                if (!formId) formId = v.form;
            }
        });

        this.setState({ customFieldsErrors });

        if (hasErrors && showSnackbar && !this.snackbarKey) {
            this.snackbarKey = enqueueSnackbar(this.tr('Some required fields not filled.') + '\n' + `${this.tr('Following required fields are not filled')}: ` + errorFields.join(', '), {
                variant: 'info',
            });
        }

        if (id && hasErrors && !nextSaleState) {
            this.context.functions.setDirty(
                true,
                (navigateAway) => {
                    if (prevSaleState && navigateAway) {
                        this.isLeaving = true;
                        this.projectDataChanged({ projects_sales_states_id: prevSaleState.id });
                    }
                },
                {
                    title: this.tr('Missing Required Data'),
                    text: `${this.tr('Required fields are not filled')}: ` + errorFields.join(', '),
                    onCancel: () => {
                        this.changeTab('forms', { form: formId });
                    },
                }
            );
        } else if (id) this.context.functions.setDirty(false);

        return hasErrors;
    };

    copy = async () => {
        if (this.state.projectLimitReached) {
            this.props.toggleBuyDialog('projects');
            return;
        }

        this.setState({
            currentDialog: 'copyProject',
            dialogData: {
                id: this.state.project.id,
                project_id: this.state.project.project_id,
                customer: this.state.project.account?.name,
                name: this.state.project.name,
                companiesId: this.state.project.companies_id,
            },
        });
    };

    isProjectLimitReached = async () => {
        if (this.context.addons && this.context.addons.projects && this.context.addons.projects.limit) {
            const limitInfo = await DataHandler.get({ url: `settings/limits/projects` });
            const projectsAdded = limitInfo.used;

            if (limitInfo.limit && projectsAdded >= limitInfo.limit) return true;
        }
        return false;
    };

    archiveProject = async (showReasonDialog = false) => {
        const { project } = this.state;

        DataHandler.get({ url: 'projects/' + project.id + '/archivecheck/' + this.state.project.companies_id }).done((data) => {
            if (data['sub_project_data']['sub_projects'].length > 0) {
                this.openArchiveDialog(true, data['sub_project_data'], showReasonDialog);
                return;
            }

            this.openArchiveDialog(false, { hasUninvoiced: data['archiveProject'] > 0 }, showReasonDialog);
        });
    };

    projectWonReasonIsInUse = (company = this.state.project.companies_id) => {
        return this.context.userObject.project_read_companies.findIndex((c) => c.id == company && c.use_project_won_reason == 1) != -1;
    };

    projectLostReasonIsInUse = (company = this.state.project.companies_id) => {
        return this.context.userObject.project_read_companies.findIndex((c) => c.id == company && c.use_project_lost_reason == 1) != -1;
    };

    markProjectAsWon = () => {
        const { project } = this.state;
        if (this.projectWonReasonIsInUse()) {
            this.context.functions.showDialogContent(
                <ProjectStatusReasonDialog
                    type="won"
                    project={project}
                    onSave={(reason, status_reason_comment) => {
                        this.projectDataChanged({ status: '1', type: 1, status_reason_name: reason.name, status_reason_id: reason.id, status_reason_comment }, true);
                    }}
                />
            );
        } else {
            this.projectDataChanged({ status: '1', type: 1 }, true);
        }
    };

    markProjectAsLost = () => {
        this.archiveProject(this.projectLostReasonIsInUse());
    };

    pipelineChanged = (evt, id, showSnackbar = false) => {
        let { value } = evt.target;
        const update = { locked: '-1' };
        value = id ? id : value;

        const { project } = this.state;
        const { id: projectId } = this.props;

        if (value === -1) {
            if (project.status == '1') return;
            update.status = '1';
            update.projects_pipelines_id = 0;
        } else if (value === -5) {
            if (project.status == '5') return;
            update.status = '5';
            update.projects_pipelines_id = 0;
        } else {
            if (project.projects_pipelines_id == value) return;
            update.status = '0';
            update.projects_pipelines_id = value;
        }

        if (projectId && value === -1 && this.projectWonReasonIsInUse()) {
            this.context.functions.showDialogContent(
                <ProjectStatusReasonDialog
                    type="won"
                    project={project}
                    onSave={(reason, status_reason_comment) => {
                        this.projectDataChanged({ ...update, status_reason_name: reason.name, status_reason_id: reason.id, status_reason_comment }, true, showSnackbar);
                    }}
                />
            );
        } else {
            this.projectDataChanged(update, true, showSnackbar);
        }
    };
    getDefaultInvoiceSettings = () => {
        const { id } = this.props;

        DataHandler.get({ url: 'projects/' + id + '/invoice_settings' }).done((response) => {
            const keys = Object.keys(response);
            keys.length && this.setState((state) => keys.forEach((e) => (state.project[e] = response[e])) || state);
        });
    };

    checkPrivilege = (group, right, company) => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        const {
            project: { id, rights, companies_id, customers_id, customer_rights },
        } = this.state;

        if (group === 'projects' && id > 0) return rights.indexOf(right) > -1;
        if (group === 'customers' && Number(customers_id) > 0) return customer_rights.indexOf(right) > -1;

        //global
        else return checkPrivilege(group, right, company || companies_id);
    };

    showProjectNumberExistsNotification = () => {
        this.props.enqueueSnackbar(this.tr('The given project number already exists. Please choose another project number.'), {
            variant: 'error',
        });
    };

    openInvoicerNote = (location = {}) => {
        this.refNoteDrawer.current.open(location);
    };

    saveNote = (data, project) => {
        const { userObject } = this.context;
        const params = { message: data, creator: userObject.usersId };

        DataHandler.post({ url: `projects/${project}/note_to_invoicer` }, params).done((response) => {
            if (response && response.info && response.info == 'too_long_for_netvisor') {
                this.props.enqueueSnackbar(this.tr('Maximum length of the comment in Netvisor is 500 characters.'), {
                    variant: 'warning',
                });
            }

            const project = { ...this.state.project };
            project.message_for_invoicer = data;
            project.creator_id = userObject.usersId;
            project.creator = userObject.fullname;
            project.message_creation_date = moment().format(userObject.dateFormat);
            this.setState({ project: project });
        });
    };

    editNote = (data, project) => {
        const { userObject } = this.context;
        const params = { message: data, editor: userObject.usersId };

        DataHandler.put({ url: `projects/${project}/note_to_invoicer` }, params).done((response) => {
            if (response && response.info && response.info == 'too_long_for_netvisor') {
                this.props.enqueueSnackbar(this.tr('Maximum length of the comment in Netvisor is 500 characters.'), {
                    variant: 'warning',
                });
            }
            const project = { ...this.state.project };
            project.message_for_invoicer = data;
            project.editor = userObject.usersId;
            project.editor = userObject.fullname;
            project.message_edited_date = moment().format(userObject.dateFormat);
            this.setState({ project: project });
        });
    };

    setMaxHours = (hours) => {
        const project = _.cloneDeep(this.state.project);
        project.maxhours = hours;
        project.has_tasks = true;
        this.setState(project);
    };

    checkDeletable = () => {
	    DataHandler.get({ url: `projects/${this.props.id}/check_deletable`, company: this.state.project.companies_id }).done(response => {
            let deletableReason = this.tr("Can not delete because project has");
            if(response.properties) {
                const reasons = [];
                for(const i in response.properties) {
                    if(response.properties[i] > 0) {
                        reasons.push(i);
                    }
                }
                deletableReason += " " + reasons.map(e => this.deletableReasons[e]).join(", ");
            }
            this.setState({deletable: response.deletable, deletableReason: deletableReason})
        });		
    }
    
    hasRightToView = (view) => {
        const { project } = this.state;
        const {
            taimerAccount,
            privileges,
            userObject,
            functions: { checkPrivilegeAny },
        } = this.context;
        switch (view) {
            case 'quote':
                return this.checkPrivilege('projects', 'project_cost_estimate_read', project.companies_id);
            case 'revenueRecognition':
                return this.checkPrivilege('projects', 'revenue_recognition_read', project.companies_id);
            case 'finances_1':
            case 'finances_2':
                return this.checkPrivilege('projects', 'project_billing_entries_read', project.companies_id);
            case 'finances_3':
                return this.checkPrivilege('projects', 'project_actual_costs_read', project.companies_id);
            case 'invoicing':
                return (
                    this.checkPrivilege('invoices', 'write_full', project.companies_id) ||
                    (this.checkPrivilege('invoices', 'write_simple', project.companies_id) && project.users_id && project.users_id == userObject.usersId)
                );
            case 'bills':
                return (this.context.addons && this.context.addons.bills) && (this.checkPrivilege('receivedinvoices', 'approve', project.companies_id) || this.checkPrivilege('receivedinvoices', 'pre_approve', project.companies_id));
            case 'po':
                return (this.context.addons && this.context.addons.bills) && project.rights.includes('purchase_order_read');
            case 'expenses':
            case 'travelExpenses':
                return checkPrivilegeAny('worktrips', undefined, project.companies_id);
            case 'profit_loss':
                return this.checkPrivilege('projects', 'profit_loss_read', project.companies_id);
            case 'hours_insight':
                return this.checkPrivilege('projects', 'hours_read', project.companies_id);
            case 'invoicing_insight':
                return this.checkPrivilege('projects', 'invoicing_read', project.companies_id);
            // case 'financial_overview':
            //     return (
            //         (1 ||
            //             (this.checkPrivilege('invoices', 'write_simple', project.companies_id) && project.users_id && project.users_id == userObject.usersId)) &&
            //         this.checkPrivilege('projects', 'project_cost_estimate_read', project.companies_id) &&
            //         this.checkPrivilege('projects', 'project_actual_costs_read', project.companies_id) &&
            //         this.checkPrivilege('projects', 'project_billing_entries_read', project.companies_id)
            //     );
            case 'activities':
                return this.checkPrivilege('projects', 'project_crm_read', project.companies_id);
            case 'resourcing':
            case 'resourcing_grid':
            case 'resourcing_gantt':
            case 'resourcing_list':
            case 'resourcing_usage':
                return (this.context.addons && this.context.addons.resourcing) && this.checkPrivilege('projects', 'project_resourcing_read', project.companies_id) || this.checkPrivilege('projects', 'own_resourcing_read', project.companies_id);
            case 'attachments':
                return this.checkPrivilege('projects', 'attachments_read', project.companies_id);
            case 'memo':
                return this.checkPrivilege('projects', 'project_memo_read', project.companies_id);
            case 'boards':
                return taimerAccount.hasExtranet && this.checkPrivilege('projects', 'collaborate', project.companies_id);
            case 'forms':
                return project.has_forms || this.checkPrivilege('admin', 'admin');
            case 'email':
                return userObject.show_email == 1;
            case 'employees_report':
            case 'employees_daily':
                return true; // users can see reports of their own hours
            case 'invoicing_vs_subcontracting':
            case 'invoicing_vs_subcontracting_bills':
                return ['invoicing'].some((right) => (privileges.reports || {})[right]);
            case 'recognition_reports':
                return ['recognition'].some((right) => (privileges.reports || {})[right]);
            case 'hours_report':
                return this.checkPrivilege('projects', 'hours_report_read', project.companies_id);
            default:
                return true;
        }
    };

    sendAHquery = (action) => {
        const { project } = this.state;
        if ('activeElement' in document) document.activeElement.blur();
        switch (action) {
            case 'create':
                DataHandler.post({ url: `projects/${project.id}/ah_folder` }, {}).done(() => {});
                break;
            case 'public':
            case 'private':
                DataHandler.put({ url: `projects/${project.id}/ah_folder_visibility` }, { visibility: action }).done(() => {
                    this.props.enqueueSnackbar(
                        this.tr('Your AH modification request has been added to queue. You should see your changes in your system in a few minutes. This project card will also be updated then.'),
                        {
                            variant: 'info',
                        }
                    );
                });
                break;
            default:
                break;
        }
    };

    dateIsValid = (date) => {
        return !!date && date != '0000-00-00';
    };

    openHoursReportSlider = () => this.setState({ hoursReportOpen: true });
    closeHoursReportSlider = () => this.setState({ hoursReportOpen: false });

    setShowProjectNameInHeader = (showProjectNameInHeader) => {
        this.context.functions.setOverrideHeaderTitles(
            showProjectNameInHeader
                ? [
                      {
                          label: `${this.state?.project?.name} (${this.state?.project?.project_id})`,
                          sublabel: { label: this.state?.project?.account?.name, url: { module: 'customers', action: 'view', id: this.state?.project?.account?.id } },
                      },
                  ]
                : undefined
        );
    };

    getTabInfoButtonProps = (selectedTab) => {
        // these will be added at some point
        switch (selectedTab) {
            // case 'overview':
            //     return [
            //         {
            //             title: this.tr('How overview works'),
            //             id: 'tab_explainers_project_overview_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'resourcing_grid':
            //     return [
            //         {
            //             title: this.tr('How grid works'),
            //             id: 'tab_explainers_resourcing_grid_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'resourcing_gantt':
            //     return [
            //         {
            //             title: this.tr('How gantt works'),
            //             id: 'tab_explainers_resourcing_gantt_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'resourcing_list':
            //     return [
            //         {
            //             title: this.tr('How list works'),
            //             id: 'tab_explainers_resourcing_list_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'resourcing_usage':
            //     return [
            //         {
            //             title: this.tr('How usage works'),
            //             id: 'tab_explainers_resourcing_usage_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'quote':
            //     return [
            //         {
            //             title: this.tr('How quote works'),
            //             id: 'tab_explainers_quote_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'revenueRecognition':
            //     return [
            //         {
            //             title: this.tr('How revenue recognition works'),
            //             id: 'tab_explainers_revenueRecognition_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'financial_overview':
            //     return [
            //         {
            //             title: this.tr('How financial overview works'),
            //             id: 'tab_explainers_financial_overview_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'finances_1':
            //     return [
            //         {
            //             title: this.tr('How scheduled invoicing works'),
            //             id: 'tab_explainers_finances_1_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'finances_2':
            //     return [
            //         {
            //             title: this.tr('How automatic invoicing works'),
            //             id: 'tab_explainers_finances_2_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'finances_3':
            //     return [
            //         {
            //             title: this.tr('How actual costs work'),
            //             id: 'tab_explainers_finances_3_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'memo':
            //     return [
            //         {
            //             title: this.tr('How memo works'),
            //             id: 'tab_explainers_memo_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'attachments':
            //     return [
            //         {
            //             title: this.tr('How attachments work'),
            //             id: 'tab_explainers_attachments_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'forms':
            //     return [
            //         {
            //             title: this.tr('How forms work'),
            //             id: 'tab_explainers_forms_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            // case 'boards':
            //     return [
            //         {
            //             title: this.tr('How boards work'),
            //             id: 'tab_explainers_boards_article',
            //             icon: 'article',
            //             onClick: () => window.open('http://8797193.hs-sites.com/en/knowledge/account-and-project-hierarchy-in-taimer'),
            //         },
            //     ];
            default:
                return null;
        }
    };

    onCreateExpense = (e) => {
        const { addons } = this.context;
        const { project } = this.state;

        if (addons.expenses && addons.expenses.limit && addons.expenses.used >= addons.expenses.limit) {
            this.props.toggleBuyDialog('expenses');
        } else {
            this.context.functions.addExpense({ projectId: project.id });
        }
    };

    onCreateTravelExpense = (e) => {
        const { addons } = this.context;
        const { project } = this.state;

        if (addons.expenses && addons.expenses.limit && addons.expenses.used >= addons.expenses.limit) {
            this.props.toggleBuyDialog('expenses');
        } else {
            this.context.functions.addTravelExpense({ projectId: project.id });
        }
    };

    addTask = () => {
        const { project } = this.state;
        const data = {
            companies_id: project.companies_id,
            projects_id: project.id,
            type: 'task',
            project_disabled: 1,
            origin_point: "project_view"
        };
        this.context.functions.addResource(data);
    };

    addContact = () => {
        this.context.functions.addContact({
            customers_id: this.state.project.account.id,
            projects: [
                {
                    name: `${this.state.project.name} (${this.state.project.project_id})`,
                    label: `${this.state.project.name} (${this.state.project.project_id})`,
                    id: this.state.project.id,
                    value: this.state.project.id,
                },
            ],
        });
    };

    addActivity = () => {
        this.context.functions.openActivitySlider({
            customers_id: this.state.project.account.id,
            projects_id: this.state.project.id,
        });
    };

    unlinkProjectFromParent = () => {
        const { id } = this.props;

        this.save({id, parentid: 0}, undefined, true);
    }

    renderActionButtons = () => {
        if (!this.props.id) return null;
        const { project, pipelines } = this.state;
        const { addons, userObject } = this.context;
        const editable = this.checkPrivilege('projects', 'write', project.companies_id);
        const fullWriteRight = this.context.functions?.checkPrivilege('projects', 'write', project.companies_id);

        // 0 = no folder, 1 = public, 2 = private
        let ahStatus = 0;
        if (project.has_samba_folder > 0) {
            if (project.is_private_samba_folder < 1) {
                ahStatus = 1;
            } else {
                ahStatus = 2;
            }
        }

        const hasHours = project.project_hours && project.project_hours.length > 0;
        const canCreateInvoice =
            this.checkPrivilege('invoices', 'write_full', project.companies_id) ||
            (this.checkPrivilege('invoices', 'write_simple', project.companies_id) && project.users_id && project.users_id == userObject.usersId);

        return (
            <div className={cardStyles.actionButtons}>
                <ContextMenu
                    //@ts-ignore
                    className={cardStyles.optionsMenu}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Actions')}
                    size="medium"
                    placement={'bottom-end'}
                    menuOpenCallback={this.checkDeletable}
                >
                    {editable && project.locked != '-1' && (
                        <MenuItem key="activate" onClick={() => this.projectDataChanged({ locked: '-1' }, false, true)}>
                            <OnHoldIcon title="" />
                            {this.tr('Activate')}
                        </MenuItem>
                    )}
                    {editable && project.locked === '-1' && project.status != 1 && (
                        <MenuItem key="won" onClick={project.status == 0 ? this.markProjectAsWon : () => this.projectDataChanged({ status: '1', type: 1 }, true, true)}>
                            <MoveWonDealIcon title="" />
                            {project.status == 0 ? this.tr('Mark as won') : this.tr('Move to won deals')}
                        </MenuItem>
                    )}
                    {editable && project.locked === '-1' && (
                        <MenuItem key="lost" onClick={() => (project.status == 0 ? this.markProjectAsLost() : this.archiveProject())}>
                            <Archive title="" />
                            {project.status == 0 ? this.tr('Mark as lost') : this.tr('Archive project')}
                        </MenuItem>
                    )}
                    {editable && project.locked !== '2' && Number(this.state.project.parentLocked) !== 1 && (
                        <MenuItem key="hold" onClick={() => this.projectDataChanged({ locked: '2' }, false, true)}>
                            <OnHoldIcon title="" />
                            {this.tr('Put on hold')}
                        </MenuItem>
                    )}
                    {fullWriteRight && (
                        <MenuItem onClick={(e) => this.copy(e)}>
                            <CopyAll />
                            {this.tr('Copy project')}
                        </MenuItem>
                    )}
                    {editable && project.parentid > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') && (
                        <MenuItem className="subproject-menu-item" onClick={() => this.openDialogWithData('confirmation', { 
                            saveFunc: () => {
                                this.unlinkProjectFromParent();
                            },
                            header: this.tr('Unlink from parent project?'),
                            confirmButtonText: this.tr('Unlink'),
                            text: this.tr('Are you sure you want to unlink this project from its parent? This will affect the project tree and make this project a main project.'),
                         })}>
                            <SubdirectoryArrowRight />
                            {this.tr('Unlink from parent project')}
                        </MenuItem>
                    )}
                    {editable && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') && (
                        <MenuItem className="subproject-menu-item" onClick={() => this.openDialog('subproject')}>
                            <SubdirectoryArrowRight />
                            {this.tr('Assign as a subproject')}
                        </MenuItem>
                    )}
                    {editable && pipelines && pipelines.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && (
                        <ContextSubMenu icon={<MovePipelineIcon title="" />} title={this.tr('Move to Pipeline')}>
                            {pipelines.map((p) => (
                                <MenuItem onClick={(evt) => this.pipelineChanged(evt, p.id, true)}>{p.name}</MenuItem>
                            ))}
                        </ContextSubMenu>
                    )}
                    {editable && project.status !== '5' && !VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && (
                        <MenuItem key="internal" onClick={() => this.projectDataChanged({ status: '5', locked: '-1' }, false, true)}>
                            <MoveProjectIcon title="" />
                            {this.tr('Move to internal project')}
                        </MenuItem>
                    )}
                    {Number(project.id) > 0 && this.checkPrivilege('projects', 'special_permissions') && !VersionContentManager.isFeatureHidden(this.namespace, 'specialPermissions') && (
                        <MenuItem className="subproject-menu-item" onClick={() => this.setSpecialPermissionSliderOpen(true)}>
                            <VpnKey />
                            {this.tr('Edit special permissions')}
                        </MenuItem>
                    )}
                    {addons.ah_folders && editable && ahStatus == 0 && (
                        <>
                            <MenuItem onClick={(e) => this.sendAHquery('public')}>
                                <Folder title="" />
                                {this.tr('Create Public AH Folder')}
                            </MenuItem>
                            <MenuItem onClick={(e) => this.sendAHquery('private')}>
                                <Folder title="" />
                                {this.tr('Create Private AH Folder')}
                            </MenuItem>
                        </>
                    )}
                    {addons.ah_folders && editable && ahStatus == 1 && (
                        <MenuItem onClick={(e) => this.sendAHquery('private')}>
                            <Folder title="" />
                            {this.tr('Make AH Folder Private')}
                        </MenuItem>
                    )}
                    {addons.ah_folders && editable && ahStatus == 2 && (
                        <MenuItem onClick={(e) => this.sendAHquery('public')}>
                            <Folder title="" />
                            {this.tr('Make AH Folder Public')}
                        </MenuItem>
                    )}
                    {editable && (
                        <Tooltip title={!this.state.deletable ? this.state.deletableReason : ''}>
                            <div>
                                <MenuItem
                                    disabled={hasHours || !this.state.deletable}
                                    className="delete"
                                    onClick={() => {
                                        this.openDeleteDialog();
                                    }}
                                >
                                    <RemoveIcon className="Delete" title="" />
                                    {this.tr('Delete')}
                                </MenuItem>
                            </div>
                        </Tooltip>
                    )}
                </ContextMenu>
                <ContextMenu
                    //@ts-ignore
                    className={`${cardStyles.optionsMenu} ${cardStyles.addNew}`}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Add new')}
                    size="medium"
                    placement={'bottom-end'}
                    data-testid={'project_contextmenu_add_new'}
                >
                    {canCreateInvoice && (
                        <MenuItem data-testid="add_invoice_to_project" onClick={() => this.context.functions.addInvoice({ project, company: project.companies_id, origin_point: "project_view" })}>
                            <ActionIcons.invoice />
                            {this.tr('Invoice')}
                        </MenuItem>
                    )}
                    {this.checkPrivilege('workhours', 'write') && (
                        <MenuItem
                            data-testid="add_hour_to_project"
                            onClick={() =>
                                this.context.functions.addHours({
                                    customer: {
                                        id: this.state.project.account.id,
                                    },
                                    unit: {
                                        id: 0,
                                    },
                                    project: {
                                        id: this.state.project.id,
                                    },
                                })
                            }
                        >
                            <ActionIcons.workhours />
                            {this.tr('Hour Entry')}
                        </MenuItem>
                    )}
                    {(this.context.addons && this.context.addons.resourcing) && (this.checkPrivilege('projects', 'project_resourcing_write') || this.checkPrivilege('projects', 'own_resourcing_write')) && (
                        <MenuItem data-testid="add_task_to_project" onClick={this.addTask}>
                            <ActionIcons.task />
                            {this.tr('Task')}
                        </MenuItem>
                    )}
                    {this.checkPrivilege('projects', 'project_crm_write') && (
                        <MenuItem data-testid="add_activity_to_project" onClick={this.addActivity}>
                            <ActionIcons.activity />
                            {this.tr('Activity')}
                        </MenuItem>
                    )}
                    {this.checkPrivilege('persons', 'read') && (
                        <MenuItem data-testid="add_contact_to_project" onClick={this.addContact}>
                            <ActionIcons.contact />
                            {this.tr('Contact')}
                        </MenuItem>
                    )}
                    {Number(this.context.userObject.companies_id) === Number(this.state.project.companies_id) && this.checkPrivilege('worktrips', 'write') && (
                        <MenuItem data-testid="add_expense_to_project" onClick={this.onCreateExpense}>
                            <ActionIcons.expenses />
                            {this.tr('Expense')}
                        </MenuItem>
                    )}
                    {this.checkPrivilege('worktrips', 'write') 
                        && Number(this.context.userObject.companies_id) === Number(this.state.project.companies_id) 
                        && !VersionContentManager.isFeatureHidden(this.namespace, 'travelExpenses') && (
                        <MenuItem data-testid="add_travel_expense_to_project" onClick={this.onCreateTravelExpense}>
                            <ActionIcons.travel />
                            {this.tr('Travel Expense')}
                        </MenuItem>
                    )}
                    {fullWriteRight && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') && (
                        <MenuItem
                            data-testid="add_subproject_to_project"
                            onClick={() => {
                                this.context.functions.addProject({ customers_id: project.customers_id, companies_id: project.companies_id, parentid: project.id, parentname: project.name, origin_point: "project_view_add_subproject", });
                            }}
                        >
                            <AddBusiness />
                            {this.tr('Subproject')}
                        </MenuItem>
                    )}
                </ContextMenu>
            </div>
        );
    };

    setSpecialPermissionSliderOpen = (specialPermissionSliderOpen) => this.setState({ specialPermissionSliderOpen });

    render() {
        const { 
            userObject, 
            taimerAccount 
        } = this.context;
        const {
            project,
            projects_sales_states,
            customFields,
            customFieldsErrors,
            pipelines,
            employees,
            billing_addresses,
            formErrors,
            worktypes,
            error,
            oneprojecttype,
            saving,
            currentDialog,
            specialPermissionSliderOpen,
        } = this.state;
        const { hours_date_range, profit_loss_range } = project;
        const { id, updateView } = this.props;

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        const canCreateInvoice =
            this.checkPrivilege('invoices', 'write_full', project.companies_id) ||
            (this.checkPrivilege('invoices', 'write_simple', project.companies_id) && project.users_id && project.users_id == userObject.usersId);

        if (error) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay} />}</div>;
        }

        const editable = this.checkPrivilege('projects', 'write', project.companies_id);

        const tabProps = {
            ...this.props,
            company: project.companies_id,
            currency: taimerAccount.currency,
            project,
            editable,
            /*callbacks */
            updateView,
            valueChanged: this.projectDataChanged,
            disableExtraFields: !id,
            checkPrivilege: this.checkPrivilege,

            // Custom project number props:
            projectNumberExists: this.state.projectNumberExists,
            updateProjectNumber: this.updateProjectNumber,
            showProjectNumberExistsNotification: this.showProjectNumberExistsNotification,
            enqueueSnackbar: this.props.enqueueSnackbar,
            canCreateInvoice: canCreateInvoice,
            openDialog: this.openDialog,
        };

        let progressColor;
        if (project.status === '0') progressColor = 'done';
        if (project.status === '1') progressColor = 'won';
        if (project.status === '5') progressColor = 'internal';
        if (project.locked === '1' || project.locked === '3') progressColor = 'lost';
        if (project.locked === '2') progressColor = 'hold';

        const activityDefault = {
            customer: {
                id: project.customers_id,
                name: project.customer,
            },
            project: {
                id: project.id,
                name: project.name,
            },
        };

        const noteDrawerProps = {
            title: this.tr('Note to invoice creator'),
            noteProps: {
                onCtrlS: project.creator_id > 0 ? this.editNote : this.saveNote,
                actions: ['creatorChat', 'projectChat'],
            },
            noteData: [
                {
                    project_id: project.id,
                    mainHeader: project.name + ' (' + project.project_id + ')',
                    subHeader: project.account.name,
                    note: project.message_for_invoicer,
                    creator: project.creator,
                    creator_id: project.creator_id,
                    created_date: moment(project.message_creation_date).format(userObject.dateFormat),
                    editor: project.editor,
                    editor_id: project.editor_id,
                    edited_date: moment(project.message_edited_date).format(userObject.dateFormat),
                },
            ],
        };

        const startDate = this.dateIsValid(this.state.project.startdate) ? this.state.project.startdate : undefined;
        const endDate = this.dateIsValid(this.state.project.enddate) ? this.state.project.enddate : undefined;

        let dialogProps = {};
        if (currentDialog == 'activity') {
            dialogProps = {
                item: {
                    customer: {
                        id: project.account.id,
                        name: project.account.name,
                    },
                    project: {
                        id: project.id,
                        name: project.name,
                    },
                    user: {
                        id: userObject.usersId,
                        name: userObject.fullName,
                    },
                    company: {
                        id: project.companies_id,
                    },
                    projectNotEditable: true,
                },
                onClose: this.closeDialog,
            };
        } else if (currentDialog == 'subproject') {
            dialogProps = {
                onClose: this.closeDialog,
                dialogProps: { project: { ...project } },
                company: project.companies_id,
                data: { customerId: project.account.id },
                onSave: (e) => this.projectDataChanged({ [e.target.name]: e.target.value }),
            };
        }

        if (id && !project.id) return null;

        return (
            <React.Fragment>
                <div className="taimer-view" id="project-view">
                    {(saving || (id && !project.id)) && (
                        <div className="saving-overlay">
                            <img src={require('../dashboard/insights/img/loading.svg').default} />
                        </div>
                    )}
                    <WithTabs
                        getTabInfoButtonProps={this.getTabInfoButtonProps}
                        checkItemPrivilege={this.hasRightToView}
                        selectedTab={this.props.selectedTab}
                        selectedSubTab={this.props.selectedSubTab}
                        selectedSubTabChild={this.props.selectedSubTabChild}
                        tabs={this.tabs}
                        rightComponent={this.renderActionButtons()}
                        height={55}
                        tabsAlwaysVisible
                    >
                        {(selectedTab) => {
                            if (!this.hasRightToView(selectedTab)) {
                                selectedTab = 'overview';
                            }
                            switch (selectedTab) {
                                case 'overview':
                                    return (
                                        <TabBasic
                                            {...tabProps}
                                            sales_users={this.state.sales_users}
                                            customFields={customFields}
                                            customFieldsErrors={customFieldsErrors}
                                            formErrors={formErrors}
                                            oneprojecttype={oneprojecttype}
                                            onPipelineChanged={this.pipelineChanged}
                                            pipelines={pipelines}
                                            salesStates={projects_sales_states}
                                            employees={employees}
                                            worktypes={worktypes}
                                            billing_addresses={billing_addresses}
                                            updateProjectData={this.updateComponentData}                                             projectWonReasonIsInUse={this.projectWonReasonIsInUse}
                                            projectLostReasonIsInUse={this.projectLostReasonIsInUse}
                                            setShowProjectNameInHeader={this.setShowProjectNameInHeader}
                                        >
                                            <ProjectStatistics {...tabProps} />
                                        </TabBasic>
                                    );
                                case 'activities':
                                    return (
                                        <TabActivities
                                            hasWritePermission={this.checkPrivilege('projects', 'project_crm_write')}
                                            newActivityDefault={activityDefault}
                                            entity="project"
                                            {...tabProps}
                                            editable={this.checkPrivilege('projects', 'project_crm_write')}
                                        />
                                    );
                                case 'quote':
                                    return VersionContentManager.hasFeature('quoteWizard') ? <QuoteView {...tabProps} updateProjectData={this.updateComponentData} /> : <TabQuotes {...tabProps} updateProjectData={this.updateComponentData} validateForms={this.validateForms} />
                                case 'revenueRecognition':
                                    return (
                                        <TabSales
                                            noTabs
                                            {...tabProps}
                                            tabView="revenueRecognition"
                                            ref={this.tabSales}
                                            updateProjectData={this.updateComponentData}
                                            validateForms={this.validateForms}
                                        />
                                    );
                                case 'resourcing_grid':
                                case 'resourcing_gantt':
                                case 'resourcing_list':
                                case 'resourcing_usage':
                                    return (
                                        <TabResourcing
                                            {...tabProps}
                                            noTabs
                                            selectedTab={selectedTab.replace('resourcing_', '')}
                                            onProjectRangeExtended={this.updateComponentData}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setMaxHours={this.setMaxHours}
                                        />
                                    );
                                case 'finances_1':
                                case 'finances_2':
                                case 'finances_3':
                                    return (
                                        <TabFinance
                                            selectedType={Number(selectedTab.replace('finances_', ''))}
                                            startDate={startDate}
                                            endDate={endDate}
                                            notesClicked={() => this.noteDrawer.current.open(this.state)}
                                            {...tabProps}
                                            entity="project"
                                        />
                                    );
                                case 'invoicing':
                                    return (
                                        <TabFinance
                                            selectedType={4}
                                            startDate={startDate}
                                            endDate={endDate}
                                            notesClicked={() => this.noteDrawer.current.open(this.state)}
                                            {...tabProps}
                                            entity="project"
                                        />
                                    );
                                case 'bills':
                                case 'po':
                                    return <BillsPoList noTabs projects_id={project.id} {...tabProps} selectedTab={selectedTab} />;
                                case 'expenses':
                                case 'travelExpenses':
                                    return <ExpenseList 
                                        noTabs 
                                        listType={selectedTab} 
                                        projects_id={project.id} 
                                        lockCompanyDropdown={true}
                                        {...tabProps} />;
                                // case 'financial_overview':
                                //     return (
                                //         <TabFinance
                                //             selectedType={5}
                                //             startDate={startDate}
                                //             endDate={endDate}
                                //             notesClicked={() => this.noteDrawer.current.open(this.state)}
                                //             {...tabProps}
                                //             entity="project"
                                //         />
                                //     );
                                case 'profit_loss':
                                    return (
                                        <ProfitLoss
                                            useDefaultDateRange
                                            startDate={profit_loss_range?.start}
                                            endDate={profit_loss_range?.end}
                                            singleProject
                                            customer={project.account.id}
                                            project={id}
                                            header={this.tr('Profit & Loss')}
                                            company={project.companies_id}
                                            showTreeDropdown={true}
                                            hideAccountAndProjectDataLists={true}
                                            allowFullDateRange
                                        />
                                    );
                                case 'hours_insight':
                                    return (
                                        <HourInsights
                                            useDefaultDateRange
                                            startDate={hours_date_range?.start}
                                            endDate={hours_date_range?.end}
                                            className="insights-project-card"
                                            singleProject
                                            header={this.tr('Hours Overview')}
                                            customer={project.account.id}
                                            project={id}
                                            company={project.companies_id}
                                            allowFullDateRange
                                        />
                                    );
                                case 'invoicing_insight':
                                    return (
                                        <InvoicingInsight
                                            useDefaultDateRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="insights-project-card"
                                            singleProject
                                            header={this.tr('Invoicing Overview')}
                                            customer={project.account.id}
                                            project={id}
                                            company={project.companies_id}
                                            allowFullDateRange
                                        />
                                    );
                                case 'memo':
                                    return <TabMemo {...tabProps} />;
                                case 'boards':
                                    return <TabCollaborate {...tabProps} />;
                                case 'forms':
                                    return (
                                        <TabBasic
                                            sales_users={this.state.sales_users}
                                            showHeader
                                            {...tabProps}
                                            customFields={customFields}
                                            customFieldsErrors={customFieldsErrors}
                                            formErrors={formErrors}
                                            onPipelineChanged={this.pipelineChanged}
                                            pipelines={pipelines}
                                            salesStates={projects_sales_states}
                                            employees={employees}
                                            worktypes={worktypes}
                                            billing_addresses={billing_addresses}
                                            projectWonReasonIsInUse={this.projectWonReasonIsInUse}
                                            projectLostReasonIsInUse={this.projectLostReasonIsInUse}
                                            setShowProjectNameInHeader={this.setShowProjectNameInHeader}
                                        >
                                            <TabForms
                                                {...tabProps}
                                                projects_sales_states={projects_sales_states}
                                                customFields={customFields}
                                                customFieldsErrors={customFieldsErrors}
                                                formErrors={formErrors}
                                                salesStates={projects_sales_states}
                                                employees={employees}
                                                billing_addresses={billing_addresses}
                                            />
                                        </TabBasic>
                                    );
                                case 'email':
                                    return <TabMessenger {...tabProps} />;
                                case 'attachments':
                                    return <TabAttachments entity="projects" {...tabProps} editable={this.checkPrivilege('projects', 'attachments_write', project.companies_id)} />;
                                default:
                                    return (
                                        <TabBasic
                                            {...tabProps}
                                            sales_users={this.state.sales_users}
                                            customFields={customFields}
                                            customFieldsErrors={customFieldsErrors}
                                            formErrors={formErrors}
                                            oneprojecttype={oneprojecttype}
                                            onPipelineChanged={this.pipelineChanged}
                                            pipelines={pipelines}
                                            salesStates={projects_sales_states}
                                            employees={employees}
                                            worktypes={worktypes}
                                            billing_addresses={billing_addresses}
                                            updateProjectData={this.updateComponentData}
                                            projectWonReasonIsInUse={this.projectWonReasonIsInUse}
                                            projectLostReasonIsInUse={this.projectLostReasonIsInUse}
                                            setShowProjectNameInHeader={this.setShowProjectNameInHeader}
                                        />
                                    );
                            }
                        }}
                    </WithTabs>
                </div>

                {Dialog && this.state.currentDialog !== 'delete' && (
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onClose={this.closeDialog}
                        onDialogSave={this.confirmDialog}
                        updateLimitInfo={this.updateLimitInfo}
                        data={this.state.dialogData}
                        {...dialogProps}
                    />
                )}

                {this.state.currentDialog === 'delete' && (
                    <MassDialog
                        onDialogClose={this.closeDialog}
                        onDialogSave={this.deleteProject}
                        dialogType={'delete'}
                        dialogProps={{
                            wider: true,
                            confirmDisabled: this.state.dialogData.deleteDisabled,
                            onCloseClick: this.closeDialog,
                            close: this.closeDialog,
                            open: this.state.currentDialog === 'delete',
                            header: this.state.dialogData.header,
                            confirmButtonText: this.state.dialogData.confirmButtonText,
                            warning: () => {
                                return <p id="project-delete-dialog-warning">{this.state.dialogData.text}</p>;
                            },
                            onConfirm: () => {
                                this.state.dialogData.saveFunc();
                                this.closeDialog();
                            },
                        }}
                    />
                )}

                <NoteDrawer ref={this.noteDrawer} {...noteDrawerProps} />
                <HoursReportSlider open={this.state.hoursReportOpen} onClose={this.closeHoursReportSlider} project={this.state.project} />
                <SpecialPermissionSlider
                    open={specialPermissionSliderOpen}
                    onClose={() => this.setSpecialPermissionSliderOpen(false)}
                    mode={'projects'}
                    company={project.companies_id}
                    entityId={project.id}
                    updateRights={this.updateProjectRights}
                />
            </React.Fragment>
        );
    }
}
ProjectView.propTypes = {
    id: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ProjectView);
