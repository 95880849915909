import _ from "lodash";
import DataHandler from "../general/DataHandler";
import { FilterState } from "./FiltersState";
import { DateSpan, DateStringSpan } from "../general/DateTimeUtils";

export enum ReportType {
    Unkown = 0,
    Invoicing = 1,
    Hours = 2,
    Costs = 3,
    Projects = 4,
    Forecast = 5,
    ScheduledInvoicing = 6,
    Accounts = 7,
    Sales = 8,
    Activities = 9,
    Contacts = 10,
    Resourcing = 11,
    Users = 12,
    RevenueRecognition = 13,
}

export interface TemplateListItem {
    id: number;
    name: string;
    description: string;
    users_id: number | string;
    report_type: ReportType;
    is_favorite: boolean;
    is_hidden: boolean;
    is_own: boolean;
    is_default?: boolean;
    is_shared_to_others: boolean;
    shared_by_user: string;
    shared_date: string;
    order_nr: number;
    shared_to?: SharedToData[];
}

export interface SharedToData {
    id?: string|number;
    entity_id?: string|number;
    value: number | string;
    label: string;
    name: string;
    relation_type: number | string;
    permission?: number;
    additionalText?: string;
    bgColor?: string;
}

export interface FilterDataJson {
    filterState: FilterState;
    selectedCurrency?: string;
    dateSpan?: DateStringSpan;
    relativeRange?: string;
    materialDateSpan?: DateStringSpan;
    materialRelativeRange?: string;
}

export interface TemplateData {
    report_type: ReportType;
    data_json?: FilterDataJson;
    id: number;
    name: string;
    description: string;
    users_id: number | string;
    shared_to?: SharedToData[];
    date_span?: DateSpan;
    material_date_span?: DateSpan;
    relative_range?: string | null;
    material_relative_range?: string | null;
    isNew?: boolean;
}

export async function getTemplatesForUser(usersId: number, tr: ((text, replacers?: Record<string, string>) => string) | null = null): Promise<TemplateListItem[]> {
    const templates = await DataHandler.get({ url: `new_reports/templates/all/${usersId}` }) as TemplateListItem[];

    const templatesByGroup = _.chain(templates)
        .sortBy(x => [!x.is_default, !x.is_favorite, x.name?.toLowerCase(), x.order_nr, x.id])
        .groupBy(x => x.report_type)
        .values()
        .value();

    for (const group of templatesByGroup) {
        let nr = 0;

        for (const item of group) {
            item.order_nr = nr++;
            
            if (tr && item.is_default) {
                item.name = tr(item.name);
                item.description = tr(item.description);
            }
        }
    }

    return templates;
}

const cachedTemplates: Record<number, TemplateData> = {};

export function clearTemplateFromCache(id: number) {
    delete cachedTemplates[id];
}

export async function getTemplateData(id: number | null | undefined): Promise<TemplateData|null> {
    if (!id) {
        return null;
    }

    if (cachedTemplates[id]) {
        return cachedTemplates[id];
    }

    try {
        const data = await DataHandler.get({url: `new_reports/templates/${id}`}) as TemplateData;

        if (data && data.data_json && data.report_type) {
            cachedTemplates[id] = data;
            return data;
        } else {
            console.error("template load failed", id, data)
        }
    } catch (error) {
        console.error("template load failed", id, error)     
    }

    return null;
}

/**
 * Report type definitions, needs to match ReportType.php
 */


/**
 * Tab to numeric id
 */
const tabTypeMap = {
    invoicing: ReportType.Invoicing,
    scheduled_invoicing: ReportType.ScheduledInvoicing,
    workinghours: ReportType.Hours,
    costs: ReportType.Costs,
    projects: ReportType.Projects,
    accounts: ReportType.Accounts,
    forecast: ReportType.Forecast,
    activities: ReportType.Activities,
    sales: ReportType.Sales,
    contacts: ReportType.Contacts,
    revenue_recognition: ReportType.RevenueRecognition,
    resourcing: ReportType.Resourcing,
}

/**
 * Numeric id to tab name
 */
const typeTabMap = {
    [ReportType.Invoicing]: 'invoicing',
    [ReportType.ScheduledInvoicing]: 'scheduled_invoicing',
    [ReportType.Hours]: 'workinghours',
    [ReportType.Costs]: 'costs',
    [ReportType.Projects]: 'projects',
    [ReportType.Forecast]: 'forecast',
    [ReportType.Accounts]: 'accounts',
    [ReportType.Activities]: 'activities',
    [ReportType.Sales]: 'sales',
    [ReportType.Contacts]: 'contacts',
    [ReportType.RevenueRecognition]: 'revenue_recognition',
    [ReportType.Resourcing]: 'resourcing',
}

export function reportTypeToTab(n: ReportType|number) {
    return typeTabMap[n];
}

export function reportNameToReportType(s: string): ReportType {
    return tabTypeMap[s] ??  ReportType.Unkown;
}
