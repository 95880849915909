import TaimerComponent from '../TaimerComponent';
import { DisplayDataObject, getVariableDisplayValue, UserColumnDefinition } from './ColumnTypes';
import IconCell from './IconCell';
import _ from 'lodash';

interface Props {
    value: string;
    displayDatas: DisplayDataObject[];
    data: object;
    colDef: UserColumnDefinition;
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {
      colId: string;
    },
    api?:any;
    groupedDataValue?: string | number | boolean;
    tr?: (text, replacers?: Record<string, string>) => string;
    getIconComponent?: (data: any) => any;
}

export default class VariableCellRenderer extends TaimerComponent<Props> {
  render() {
    const { colDef, data } = this.props;

    const displayDatas = _.cloneDeep(this.props.displayDatas);

    const value = this.props.hasOwnProperty("groupedDataValue") // value can be false, so doesn't work if check this.props.groupedDataValue
      ? this.props.groupedDataValue
      : this.props.data && (this.props.data[this.props.colDef.field || ''] || 0);

    const iconComponent = this.props.getIconComponent ? this.props.getIconComponent(data) : null;
    const displayValue = getVariableDisplayValue(value, displayDatas, this.props.tr, colDef?.['pivotValueColumn'] ? true : false);

    if (iconComponent) {
      return <IconCell
        value={displayValue}
        iconComponent={iconComponent}
        justifyContent={colDef?.justifyContent || "icon_text"}
      />
    }
  
    return displayValue;
  }
}